//copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import './index.css';
import banner1 from './ban_babysitterwanted.jpg';
import banner2 from './ban_dirtylove_md.jpg';
import banner3 from './ban_cinderella.jpg';
import banner4 from './ban_deathwish.jpg';
import banner5 from './ban_unbreakable_spirit_brodynurse.jpg';
import banner6 from './ban_papillon.jpg';
import banner7 from './ban_ozzy_girlskate.jpg';
import banner8 from './ban_ozzy_family.jpg';

import Routes from '../../containers/AppRouter/routes';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { setMedia } from '../../redux/actions/currentMediaActions';
import PropTypes from 'prop-types';
// import Slider from 'react-animated-slider';
// import 'react-animated-slider/build/horizontal.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
// import './sliderCss.css';
// import AdSense from 'react-adsense';



export class ModTop1 extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            history,
            location,
            setMedia
        } = this.props;

        const slides = [

            { image: banner1, button: Routes.mku_bseg_fl_2013_singularityprinciple },
            { image: banner2 },
            // { title: 'Singularity Principle', description: 'Singularity Principle is a feature length science-fiction film about the consequences of renegade experiments into Parallel Universes.', image: banner1, button: Routes.mku_bseg_fl_2013_singularityprinciple},
            // { title: 'Forget About It', description: 'With 3 old men as neighbors, they find an ex-mob bosses money while playing golf in the desert. Things get lively.', image: banner2, button: Routes.mku_bseg_fl_2006_forgetaboutit },
            // { title: 'Dirty Love', description: 'A jilted photographer sets off on a mission to get back at her philandering model boyfriend and along the way discovers that not all love is created equal.', image: banner3, button: Routes.mku_bseg_fl_2006_dirtylove }
        ];
        // console.log('MODTOP1 HISTORY')
        // console.log(history)
        return (

            <div>
        { location.pathname === Routes.home ? // todo: make this module more dynamic for more pages later
            <Carousel emulateTouch infiniteLoop autoPlay stopOnHover showThumbs={false} width="" showStatus={false}>
                <div>
                    <img src={banner1} />
                    <button className="play-btn" onClick={()=> {
                        // console.log(setMedia(
                        //     "mku_bseg_fl_2009_babysitterwanted",
                        //     "Babysitter Wanted",
                        //     "https://link.storjshare.io/jwjch5os2euarmh26vytzytols2q/big-stream/mp4/mku_bseg_fl_2009_babysitterwanted.mp4?view",
                        //     "In a small college town, a young girl working on a babysitting job in a rural farm is terrorized throughout the night.",
                        //     "https://link.storjshare.io/jxaguz4e2w3kpdshfkuegnb3uveq/big-stream-v1/thumbnails/tbn_bseg_fl_2009_babysitterwanted.jpg?view"));
                        setMedia(
                            "mku_bseg_fl_2009_babysitterwanted",
                            "Babysitter Wanted",
                            "In a small college town, a young girl working on a babysitting job in a rural farm is terrorized throughout the night.",
                            "https://link.storjshare.io/jwjch5os2euarmh26vytzytols2q/big-stream/mp4/mku_bseg_fl_2009_babysitterwanted.mp4?view",
                            "https://link.storjshare.io/jxaguz4e2w3kpdshfkuegnb3uveq/big-stream-v1/thumbnails/tbn_bseg_fl_2009_babysitterwanted.jpg?view");
                        history.push(Routes.mku_bseg_fl_2009_babysitterwanted);
                    }}>Play</button>
                </div>
                <div>
                    <img src={banner2} />
                    <button className="play-btn" onClick={()=> {
                        // console.log(setMedia(
                        //     "mku_bseg_fl_2006_dirtylove",
                        //     "Dirty Love",
                        //     "https://link.storjshare.io/jxln3rmr3pitbkmjmc4nyrlgapya/big-stream/mp4/mku_bseg_fl_2006_dirtylove.mp4?view",
                        //     "A jilted photographer sets off on a mission to get back at her philandering model boyfriend and along the way discovers that not all love is created equal.",
                        //     "https://link.storjshare.io/jxty7to3ieazayraeaty6ondbtta/big-stream-v1/thumbnails/tbn_bseg_fl_2006_dirtylove.jpg?view"));
                        setMedia(
                            "mku_bseg_fl_2006_dirtylove",
                            "Dirty Love",
                            "A jilted photographer sets off on a mission to get back at her philandering model boyfriend and along the way discovers that not all love is created equal.",
                            "https://link.storjshare.io/jxln3rmr3pitbkmjmc4nyrlgapya/big-stream/mp4/mku_bseg_fl_2006_dirtylove.mp4?view",
                            "https://link.storjshare.io/jxty7to3ieazayraeaty6ondbtta/big-stream-v1/thumbnails/tbn_bseg_fl_2006_dirtylove.jpg?view");
                        history.push(Routes.mku_bseg_fl_2006_dirtylove);
                    }}>Play</button>
                </div>
                <div>
                    <img src={banner3} />
                    {/*<button className="play-btn">Legend 2</button>*/}
                </div>  
                <div>
                    <img src={banner4} />
                    {/*<button className="play-btn">Legend 2</button>*/}
                </div>  
                <div>
                    <img src={banner5} />
                    {/*<button className="play-btn">Legend 2</button>*/}
                </div>
                <div>
                    <img src={banner6} />
                    {/*<button className="play-btn">Legend 2</button>*/}
                </div>  
                <div>
                    <img src={banner7} />
                    {/*<button className="play-btn">Legend 2</button>*/}
                </div>  
                <div>
                    <img src={banner8} />
                    {/*<button className="play-btn">Play</button>*/}
                    {/*<p className="legend">Legend 2</p>*/}
                </div>                
            </Carousel>
           : '' }
        </div>
        );
    };
};


ModTop1.propTypes = {
    mku: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string,
    thumbnail: PropTypes.string,
};

ModTop1.propTypes = {
    setMedia: PropTypes.func,
    // toggleLoadingModule: PropTypes.func,
    // is_loading_module: PropTypes.bool,
};

ModTop1.defaultProps = {
    setMedia: () => {},
    // toggleLoadingModule: () => {},
    // is_loading_module: false,
}

export const mapStateToProps = state => ({
    mku: state.current_media.mku,
    title: state.current_media.title,
    desc: state.current_media.desc,
    url: state.current_media.url,
    thumbnail: state.current_media.thumbnail,

});

export const mapDispatchToProps = dispatch => ({
    setMedia: (mku, title, desc, url, thumbnail) => dispatch(setMedia(mku, title, desc, url, thumbnail)),
    // setCustom1: (playlist) => dispatch(setCustom1(playlist)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModTop1));