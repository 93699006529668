import * as ActionTypes from '../actions/customActions';

export const initialState = {
  playlist: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_CUSTOM_1:
      return Object.assign({}, state, {
        playlist: action.playlist,
      });
    default:
        return state;
  }
};
