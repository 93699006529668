
//copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import {Helmet} from "react-helmet";

export class About extends Component {
  componentDidMount() {
    document.title = 'BSE - About Big Stream Entertainment';
  }
  render() {    
    return (
      <div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>BSE - About Big Stream Entertainment</title>
            <link rel="canonical" href="https://bigstreamentertainment.com/about" />
            <meta name="description" content="BSE - About Big Stream Entertainment streaming application presented by Big Screen Entertainment Group (Stock: BSEG)" />
            <meta name="keywords" content="big stream, bseg, big screen entertainment, bseg holdings" />
        </Helmet>
        <article>
          <h1>About</h1>
          <h3>Big Stream Entertainment</h3>
          <p>Big Stream Entertainment is a streaming platform that delivers Big Stream Entertainment Group (Stock: BSEG), partner, and affiliate content. We currently have a Roku Channel by the name of Big Stream Entertainment (BSE for this article) that is in beta mode. Over the coming months and through next year we will be continually adding content to the BSE platform.</p>
          <p>Development is underway and releasing soon will be a desktop web portal that will mirror the content that is currently distributed on the BSE Roku channel. Shortly afterwards we look to develop an app that will also mirror the Roku channel, and desktop portal in functionality.</p>
      		<p>We hope that we can deliver content of all genres that will entertain and inform many people. It is our hope to continually grow this online library and in time with feedback from our viewers we can deliver more, and better quality streaming and entertainment services over time.</p>
      		<p>Thank you all for your support, we will have more information forthcoming as more is rolled out.</p>
      		<h3>Big Screen Entertainment Group (Stock: <strong>BSEG</strong>)</h3>
      		<p>Please visit our parent company's site <a href='https://bigscreenentgroup.com/' target='_blank' rel='noopener noreferrer' alt='Big Screen Entertainment Group (Stock: BSEG)'>www.bigscreenentgroup.com</a>.  There you can find information about many of our productions and current news about company developments.</p>
      		<p>BSEG Holdings is an umbrella company also 100% wholly owned by Big Screen Entertainment Group (stock: BSEG).  There you can find more inforamtion about our various subsidiaries.  Please visit <a href='https://bsegholdings.com/' target='_blank' rel='noopener noreferrer' alt='Big Screen Holdings (Stock: BSEG)'>www.bsegholdings.com</a> for more.</p>
        </article>
      </div>
    );
  }
} 

export default About;
