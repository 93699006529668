
//copyright: Big Screen Entertainment Group Inc.
export const SET_USER = 'SET_USER';

export function setUser(id, username, email, newsletter, subscription, subscription_plan_id, expiry_date) {
  return {
    type: SET_USER,
    id,
    username,
    email,
    newsletter,
    subscription,
    subscription_plan_id,
    expiry_date,
  };
}