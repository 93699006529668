//copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import '../index.css';
import article00003_captain_america from '../_images/article00003_captain_america.jpg';
import {Helmet} from "react-helmet";

export class Article00003 extends Component {

    render() {
        
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>BSE Hollywood International Film Magazine - Collecting History</title>
                    <link rel="canonical" href="https://bigstreamentertainment.com/the_directors_who_changed_the_film_industry" />
                    <meta name="description" content="BSE (Stock: BSEG) Hollywood International Film Magazine - Would you pay a quarter of a million dollars for a superhero costume." />
                    <meta name="keywords" content="hollywood, international, marvel, captain America, collectibles, auction, history, i-collector" />
                </Helmet>
                <article>
                    <div className="row">          
                        <h1>COLLECTING <span className="white">HISTORY</span></h1>
                    </div>
                    <div className="row">

                        <p>Would you pay a quarter of a million dollars for a superhero costume?</p>
                        <p>One bidder did just that, parting with $228,000 for a genuine Captain America outfit at a Marvel auction.</p>
                        <p>The sale was organized by Profiles in History, Hollywood's top auction house for film props and costumes.</p>
                        <p>The company's Chief Executive Joe Maddalena expanded his hobby of collecting film props into a business-and it's proving a very lucrative one.</p>
                        <p>Fortunes are paid for the rare and prized possessions he has been able to obtain from the biggest movies past and present.</p>
                        <p>Bidders come from all over the world-especially an increasing number of customers from China via platforms like I-collector.com and tech executives in Silicon Valley looking to decorate their offices with cool props.</p>
                        <p>His own office is packed with favorite things from his own personal collection that he would never part with, including memorabilia from X-Men, Buck Rogers and Back to the Future.</p>
                        <p>Joe has found that whther it's Star Wars, Star Trek or any other popular genre there's a burning desire for film fans to own a piece of cinematic history - and pay big for the privilege.</p>
                        <div>
                            <img className="w100" src={article00003_captain_america} alt="Marvel's Captain America" />
                        </div>
                    </div>            
                </article>
            </div>
        );
    }
}

export default Article00003;