
//copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleSearchMenu } from '../../redux/actions/appActions';
import { toggleSubMenuAbout } from '../../redux/actions/appActions';
import { toggleSubMenuCategory } from '../../redux/actions/appActions';
import { toggleCategory } from '../../redux/actions/appActions';
import { toggleOverflow } from '../../redux/actions/appActions';
// import './index.css';
import Routes from '../AppRouter/routes';
import { withRouter } from 'react-router';
// import SubMenuCategory from '../SubMenuCategory';
// import SearchMenu from '../SearchMenu'; 
// import Breadcrumbs from '../Breadcrumbs'



export class SubMenuAbout extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {
            toggleOverflow,
            is_overflow
        } = this.props;
        // toggleOverflow(!is_overflow)
    }

    componentWillUnmount() {
        // const {
        //     toggleOverflow,
        //     is_overflow,
        //     is_submenu_about,
        //     is_submenu_category,
        // } = this.props;
        // console.log(is_submenu_about)
        // console.log(is_submenu_category)
        // if (is_submenu_about && is_submenu_category) {
        //     // do nothing as Category menu is overflowing
        // } else if (is_submenu_about && !is_submenu_category) {
        //     toggleOverflow(false); //overflow is about to end once unmounted because category menu is also not on/false 
        // }
    }

    render() {
        const {
            is_search_menu,
            is_submenu_about,
            is_submenu_category,
            is_category,
            toggleSearchMenu,
            toggleSubMenuAbout,
            toggleSubMenuCategory,
            toggleCategory,
            toggleOverflow,
            match,
            location,
            history
        } = this.props;
        return (
            <div className="sub-menu-container">
                <div className="sub-menu-list">                
                    <div className={is_category === 'about' ? "sub-menu-item sub-menu-item-active" : "sub-menu-item"} onClick={()=> {
                        toggleSearchMenu(!is_search_menu);
                        // is_submenu_category ? toggleSubMenuCategory(!is_submenu_category) : toggleSubMenuCategory(is_submenu_category);
                        toggleSubMenuCategory(false);
                        toggleCategory('about');
                        history.push(Routes.about)
                        // toggleSubMenuAbout(!is_submenu_about);
                    }}> BSE</div>
                    <div className={is_category === 'aboutbseg' ? "sub-menu-item sub-menu-item-active" : "sub-menu-item"} onClick={()=> {
                        toggleSearchMenu(!is_search_menu);
                        // is_submenu_category ? toggleSubMenuCategory(!is_submenu_category) : toggleSubMenuCategory(is_submenu_category);
                        toggleSubMenuCategory(false);
                        toggleCategory('aboutbseg');
                        history.push(Routes.aboutbseg)
                        // toggleSubMenuAbout(!is_submenu_about);
                    }}> BSEG</div>
                </div>
            </div>
        );
    };
};

SubMenuAbout.propTypes = {
    toggleSubMenuAbout: PropTypes.func,
    is_submenu_about: PropTypes.bool,
}

SubMenuAbout.defaultProps = {
    toggleSubMenuAbout: () => {},
    is_submenu_about: true,
}

export const mapStateToProps = state => ({
    is_search_menu: state.app.is_search_menu,
    is_submenu_about: state.app.is_submenu_about,
    is_submenu_category: state.app.is_submenu_category,
    is_category: state.app.is_category,
    is_overflow: state.app.is_overflow,
});

export const mapDispatchToProps = dispatch => ({
    toggleSearchMenu: value => dispatch(toggleSearchMenu(value)),
    toggleSubMenuAbout: value => dispatch(toggleSubMenuAbout(value)),
    toggleSubMenuCategory: value => dispatch(toggleSubMenuCategory(value)),
    toggleCategory: value => dispatch(toggleCategory(value)),
    toggleOverflow: value => dispatch(toggleOverflow(value)),
});

{ /*export default connect(mapStateToProps, mapDispatchToProps)(SearchMenu);*/ } { /*export default withRouter(SearchMenu);*/ }
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubMenuAbout));