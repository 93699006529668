//copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import AppRouter from './containers/AppRouter';
import Header from './containers/Header';
import Footer from './containers/Footer';
import NavBottom from './containers/NavBottom';
// import { BrowserRouter as Router } from 'react-router-dom';
import { BrowserRouter, Switch } from 'react-router-dom';
import './App.css';
// import ModLeft1 from './components/ModLeft1';
// import ModRight1 from './components/ModRight1';
import ScrollToTop from './components/ScrollToTop';
import { ThroughProvider } from 'react-through';
import ModTop1 from './components/ModTop1';
// import ModAdTop1 from './components/ModAdTop1';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { epochTime } from './components/Helpers';
// import Sticky from 'react-sticky-el';
// import {Breadcrumbs}from 'react-breadcrumbs-dynamic';
// import store from './redux/store';
import axios from 'axios';
// import LoadingIndicator from './components/Widgets/LoadingIndicator';
import { toggleSubscription, toggleLoadingIndicator } from './redux/actions/appActions';
import { setUser } from './redux/actions/userActions';
import { setCustom1 } from './redux/actions/customActions';
import bg_mediaview from './images/bg_mediaview.jpg';

class App extends Component {
    // constructor(props) {
    //     super(props);
    //     // this.store = store;
    //     // this.localStorageUpdated = this.localStorageUpdated.bind(this);
    //     // this.state = {
    //     //     'subscription': null
    //     // }
    // }
    componentDidMount() {
        // this.setState({ subscription: localStorage.getItem('subscription') })
        // window.addEventListener('storage', this.localStorageUpdated);        
        // console.log(localStorage.getItem('id'));
        // console.log(localStorage.getItem('access_token'));
        // console.log(localStorage.getItem('expire_at'));
        // console.log(epochTime());
        if (epochTime() < localStorage.getItem('expire_at') && localStorage.getItem('expire_at') != null) {
            const {
                toggleLoadingIndicator,
                is_loading_indicator,
            } = this.props
            toggleLoadingIndicator(!is_loading_indicator);
            var fData = new URLSearchParams();
            // console.log(localStorage.getItem('id'))
            fData.append('id', localStorage.getItem('id'));

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            };
            //post to login api to password verify and jwt response
            axios({
                    method: 'post',
                    //url: 'http://127.0.0.1:8080/api_v1-007/user_login_refresh_jwt_check.php', // for testing usage switchonoff 
                    url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_login_refresh_jwt_check.php', // for production usage switchonoff
                    data: fData,
                    config
                })
                // use the arrow to perform a function because this way for some reason keeps the state part of userlogin object and not part of the axios promise
                .then((response) => {
                    //handle success
                    // console.log('API Login connect...'); // for console usage switchonoff     
                    // console.log(response.status + '\n' + response.data)
                    this.setState({ fields: {}, messages: {} });
                    //jwt set to local storage
                    if (response.status === 200) {
                        // console.log(response.data);
                        let messages = {};
                        this.state.fields['id'] = response.data.id;
                        this.state.fields['username'] = response.data.username;
                        this.state.fields['email'] = response.data.email;
                        this.state.fields['newsletter'] = parseInt(response.data.newsletter);
                        this.state.fields['subscription'] = parseInt(response.data.subscription);
                        this.state.fields['subscription_plan_id'] = response.data.subscription_plan_id;
                        this.state.fields['expiry_date'] = response.data.expiry_date;
                        this.state.fields['playlist'] = response.data.playlist;
                        // console.log(response.data.playlist); // for console usage switchonoff
                        //set localStorage of application user.  This data will be used until token expiry or logout
                        localStorage.setItem('subscription', this.state.fields['subscription']);
                        // localStorage.removeItem('paypal_token');
                        //todo expiry should be part of the always showing header... it can be invisible...

                        const {
                            setUser,
                            toggleSubscription,
                            is_subscription,
                        } = this.props;
                        // console.log(this.state);

                        setUser(this.state.fields['id'], this.state.fields['username'], this.state.fields['email'], this.state.fields['newsletter'], this.state.fields['subscription'], this.state.fields['subscription_plan_id'], this.state.fields['expiry_date']);
                        if (this.state.fields['subscription'] > 1) {
                            toggleSubscription(!is_subscription);
                        }

                        //set user response success
                        messages['loginYes'] = 'Success! - Welcome, ' + this.state.fields['username_email'];
                        this.setState({ messages: messages });


                        //CHECK FOR USER PLAYLIST IF SUBSCRIPTION OR IF RENTALS
                        // if (this.state.fields['subscription'] >= 30) { //is a paying subscriber and has playlist functionality                       
                            // console.log(state);
                            // console.log(state.user.subscription);
                            var fData = new URLSearchParams();
                            fData.append('id', this.state.fields['id']);
                            // console.log(state)
                            const config = {
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Accept': 'application/json'
                                }
                            };

                            axios({
                                    method: 'post',
                                    //url: 'http://127.0.0.1:8080/api_v1-008/user_playlist_list.php', // for testing usage switchonoff
                                    url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_playlist_list.php', // for production usage switchonoff
                                    data: fData,
                                    config
                                })
                                .then((response) => {
                                    //handle success
                                    const {
                                        setCustom1,
                                    } = this.props;
                                    // console.log(response.data);
                                    setCustom1(response.data);

                                })
                                .catch((error) => {
                                    // console.log('ERROR ' + error)
                                }); //end axios playlist exist
                        // }
                    } else if (response.status === 401 || response.data.match(/access denied/i)) {
                        // console.log('Permission denied...');
                        let fields = this.state.fields;
                    }

                    const {
                        toggleLoadingIndicator,
                        is_loading_indicator,
                    } = this.props
                    toggleLoadingIndicator(!is_loading_indicator);

                    // console.log(this.state);
                    let messages = {}
                    this.setState({ messages: {} });
                    let fields = this.state.fields;
                    this.setState({ fields });
                })
                .catch((error) => {
                    //handle error
                    let messages = {}
                    this.setState({ messages: {} });


                    messages['loginNo'] = 'Error! - Invalid credentials...';
                    // console.log(messages['loginNo']);
                    this.setState({ messages: messages });
                    const {
                        toggleLoadingIndicator,
                        is_loading_indicator,
                    } = this.props
                    toggleLoadingIndicator(!is_loading_indicator);
                });
        }
    }

    localStorageUpdated() {
        // this.updateState(localStorage.getItem('subscription'));
        // console.log('HOME')
    }

    updateState(value) {
        // this.setState({ subscription: value })
    }


    render() {
        // console.log('HISTORY:APP');
        // console.log(this.props);
        // const state = store.getState();
        const {
            is_subscription,
            // toggleSubscription,
        } = this.props;

        return (
            <div onContextMenu={(e)=> e.preventDefault()} className="App">     
            <BrowserRouter>
            <ThroughProvider>
            <ScrollToTop>
              {/*<Provider store={this.store}>*/}
                <header>
                  <Header />
                </header>
                <div className="wrapper-container">
                    <div className='modtop-container'>
                        <ModTop1 />
                    </div>
                    {/*<div className={is_subscription ? 'ads_component_disabled' : 'modleft ads_component_enabled'} >*/}
                      {/*<ModLeft1 />*/}
                    {/*</div>*/}
                    <main style={{backgroundImage: `url(${bg_mediaview})`}}>
                    {/*<main className={is_subscription ? 'ads_disabled' : 'ads_enabled'}>    */}
                        {/*<div className={is_subscription ? 'ads_component_disabled' : 'modadtop1 ads_component_enabled'} >/*}
                            {/*<ModAdTop1 />*/}
                        {/*</div>*/}
                        <div className='main-container'>
                            <Switch>
                               <AppRouter />                
                            </Switch>
                        </div>                  
                    </main>
                    {/*<div className={is_subscription ? 'ads_component_disabled' : 'modright ads_component_enabled'}>*/}
                      {/*<ModRight1 />*/}
                    {/*</div>*/}
                </div>
                <footer>
                    <Footer />
                </footer>
                <nav>
                    <NavBottom />
                </nav>
              {/*</Provider>*/}
            </ScrollToTop>
            </ThroughProvider>
            </BrowserRouter>
          </div>
        );
    }
}



App.propTypes = {
    setUser: PropTypes.func,
    id: PropTypes.string,
    username: PropTypes.string,
    email: PropTypes.string,
    newsletter: PropTypes.number,
    subscription: PropTypes.number,
    subscription_plan_id: PropTypes.string,
    expiry_date: PropTypes.string,
    fields: PropTypes.object,
    messages: PropTypes.object,
    toggleSubscription: PropTypes.func,
    is_subscription: PropTypes.bool,
    toggleLoadingIndicator: PropTypes.func,
    is_loading_indicator: PropTypes.bool,
    toggleLoadingModule: PropTypes.func,
    is_loading_module: PropTypes.bool,
    setCustom1: PropTypes.func,
};

App.defaultProps = {
    setUser: () => {},
    id: null,
    username: null,
    email: null,
    newsletter: null,
    subscription: -1,
    subscription_plan_id: '',
    expiry_date: null,
    fields: { id: null, username: null, email: null, newsletter: null, subscription: -1, subscription_plan_id: '', expiry_date: null },
    messages: { loginNo: '', loginYes: '' },
    toggleSubscription: () => {},
    is_subscription: false,
    toggleLoadingIndicator: () => {},
    is_loading_indicator: false,
    setCustom1: () => {},
    // toggleLoadingModule: () => {},
    // is_loading_module: false,
}

export const mapStateToProps = state => ({
    id: state.user.id,
    username: state.user.username,
    email: state.user.email,
    newsletter: state.user.newsletter,
    subscription: state.user.subscription,
    subscription_plan_id: state.user.subscription_plan_id,
    expiry_date: state.user.expiry_date,
    fields: state.fields,
    messages: state.messages,
    is_subscription: state.app.is_subscription,
    is_loading_indicator: state.app.is_loading_indicator,
    playlist: state.custom1.playlist,
    // is_loading_module: state.app.is_loading_module,
});

export const mapDispatchToProps = dispatch => ({
    setUser: (id, username, email, newsletter, subscription, subscription_plan_id, expiry_date) => dispatch(setUser(id, username, email, newsletter, subscription, subscription_plan_id, expiry_date)),
    toggleSubscription: value => dispatch(toggleSubscription(value)),
    toggleLoadingIndicator: value => dispatch(toggleLoadingIndicator(value)),
    setCustom1: (playlist) => dispatch(setCustom1(playlist)),
    // toggleLoadingModule: value => dispatch(toggleLoadingModule(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

// export default App;