
//copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import {Helmet} from "react-helmet";

export class AboutBSEG extends Component {

  render() {

    return (
      <div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>BSE - About BSEG</title>
            <link rel="canonical" href="https://bigstreamentertainment.com/about_big_screen_entertainment_group" />
            <meta name="description" content="BSE - Big Screen Entertainment Group (Stock: BSEG) creator and provider of the BSE streaming app." />
            <meta name="keywords" content="kimberley kates, stephen eckelberry, sandro monetti, camelCode, michael manasseri, big stream, bseg, big screen entertainment, bseg holdings" />
        </Helmet>
        <article>
        
          <h1>About</h1>
          <h3>Big Screen Entertainment Group</h3>
          <h4>Stock: BSEG</h4>
          <p>Our mission is to become global leader in the independent film, film education and television industry through our development, production and distribution of commercially viable, high-quality entertainment products that will provide short-term return on capital and long-term residual income to our investors and partners.</p>
          <p>Headquartered at our corporate offices on Wilshire Blvd. in Beverly Hills, California and Rochester Hills, Michigan, our films have premiered at the world’s most prestigious independent film festivals and played at the largest theatrical chains in the United States, the United Kingdom, Japan, India and Germany. Our films have been exhibited in America’s top retail outlets and formats, including Wal-Mart, Showtime, Time Warner, Amazon, Playstation, Starz, Redbox, iTunes, DISH, Comcast, Netflix, The Movie Channel, Best Buy, Target, Xbox, Google Play, YouTube and many other VOD distributors. Our relationships with top Hollywood studios and talent, as well as our corporate distribution alliances in films and games, have positioned us to continue our growth in the coming years.</p>
          <p>BSEG website: <a href="https://bigscreenentgroup.com"  target="_blank" rel="noopener noreferrer" alt="Big Screen Entertainment Group Disclosures (Stock: BSEG)">https://bigscreenentgroup.com</a>.</p>
          <p>You can view more information about the company prospectus here: <a href="https://otcmarkets.com/stock/BSEG/disclosure" target="_blank" rel="noopener noreferrer" alt="Big Screen Entertainment Group Disclosures (Stock: BSEG)">www.otcmarkets.com/stock/BSEG/disclosure</a>.</p>
          <h3>BSEG and BSE Team</h3>
          <h4>Kimberley Kates CEO and Director</h4>
          <p>Kimberley Kates is a multi-award-winning producer who runs the Los Angeles-based publicly traded, production and global distribution company, Big Screen Entertainment Group (BSEG).</p>
          <p>She brings more than 20 years of Hollywood experience to the role, has worked with many of the biggest names in the business and oversees a wide range of tasks at Big Screen, including producing feature films for the company.</p>
          <h4>Stephen Eckelberry Chairman and Director</h4>
          <p>When Stephen Eckelberry joined Big Screen in 2004, he came as a filmmaker with over 20 years experience.  Since then he has spearheaded multiple projects spanning the globe, and has put a focus on streaming for a larger portion of BSEG's activities.</p>
          <h4>Michael Manasseri - Director and Consultant of BSEG</h4>
          <p>Michael Manasseri runs the day to day operations of BSEG’s Midwest production and distribution arm, Big Screen Michigan, headquartered in Rochester Hills, Michigan.</p>
          <h4>Dr. Bruce Lee Director</h4>
          <p>Bruce B. Lee, MD is the co-founder and Chief Medical Officer of Halt Medical, Inc., and inventor of the Acessa procedure, a safe and effective minimally invasive surgery for the treatment of uterine fibroid tumors. The procedure has received FDA clearance and is also licensed/cleared in Europe, Canada, and Mexico.</p>
          <h4>Dr. Jimmy Jiang Director and CEO of BSEG Capital Subsidiary</h4>
          <p>Dr. Jimmy Jiang is an M.D. and has a PH.D in Urology in China; Dr. Jiang was one of the top kidney transplant surgeons in the country before coming to the United States to study Immunology at UCLA.</p>
          <p>Dr. Jiang is the CEO of FEGifund Trust, a subsidiary of Fairfax Entertainment Group, and the biggest shareholder of BSEG.</p>
          <h4>camelCode LLC</h4>
          <p>camelCode LLC was founded by a skilled development team and contracted out by BSEG, which will be working to advance Big Screen Entertainment Group (Stock: BSEG) digital presence. Working to build out the BSE streaming platform and framework along with building and supporting a Big Stream Entertainment Roku channel.</p>
        </article>
      </div>
    );
  }
} 

export default AboutBSEG;
