//copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import '../index.css';
import article00002_directors_films from '../_images/article00002_directors_films.jpg';
import article00002_directors_set2 from '../_images/article00002_directors_set2.jpg';
import {Helmet} from "react-helmet";

export class Article00002 extends Component {

    render() {
        
        return (
          <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>BSE Hollywood International Film Magazine - The Directors Who Changed the Film Industry</title>
                <link rel="canonical" href="https://bigstreamentertainment.com/the_directors_who_changed_the_film_industry" />
                <meta name="description" content="BSE Hollywood International Film Magazine - Some directors do more than just make great movies, they also change film history in the process." />
                <meta name="keywords" content="hollywood, international, film, magazine, big screen, entertainment, big stream, movies, history, directors, steven spielberg, alfred hitchcock, charlie chaplin, orson welles, kathryn bigelow" />
            </Helmet>
            <article>
              <div className="row">
                <h1>THE DIRECTORS WHO CHANGED <span className="white">THE FILM INDUSTRY</span></h1>
                <p>Some directors do more than just make great movies, they also change film history in the process. Such is the case with the international movie making legends listed here.</p>
                <p>They came from different places but remain united forever by their influential and impactful achievements on camera. Here are Hollywood International Filmmaker Magazine's greatest directors in history and how they moved the industry forward.</p>
              </div>
              <div className="row">
                <div className="column-75">
                  <div className="director hitchcock">
                  <h3><span className="h1red">ALFRED </span>HITCHCOCK</h3>
                  <h5>Achievement: Master of suspense</h5>
                  <p>Analysis: Often copied, never bettered he put the scares into cinema like no one else.  Psycho is still terrifying all these years later.</p>
                  </div>
                  <div className="director spielberg">
                  <h3><span className="h1red">STEVEN </span>SPIELBERG</h3>
                  <h5>Achievement: Made the first summer blockbuster, Jaws</h5>
                  <p>Analysis: Balanced the commercial hits (Jurassic Park, ET, Indiana Jones) with the acclaimed movies (Schindler's List, Saving Private Ryan)</p>
                  </div>
                  <div className="director chaplin">
                  <h3><span className="h1red">CHARLIE </span>CHAPLIN</h3>
                  <h5>Achievement: First ever movie star to switch to directing his own films.</h5>
                  <p>Analysis: Dominated silent movie comedy with his Little Tramp character.</p>
                  </div>
                  <div className="director welles">
                  <h3><span className="h1red">ORSON </span>WELLES</h3>
                  <h5>Achievement: Made what is most widely considered the best movie of all time, Citizen Kane.</h5>
                  <p>Analysis: Distinctive film-making style during a long and varied career made him the ultimate auteur.</p>
                  </div>
                  <div className="director bigelow">
                  <h3><span className="h1red">KATHRYN </span>BIGELOW</h3>
                  <h5>Achievement: Achievement: First woman to win the Oscar for best director (The Hurt Locker).</h5>
                  <p>Analysis: Makes complex, personal and thrilling films which inspire as well as entertain.</p>
                  </div>
                  <div className="director fellini">
                  <h3><span className="h1red">FEDERICO </span>FELLINI</h3>
                  <h5>Achievement: Has won more best foreign language film Oscars (four) than anyone else.</h5>
                  <p>Analysis: An artist with the camera.  His films, such as La Dolce Vita, had a beautifully distinctive look.</p>
                  </div>
                  <div className="director kurosawa">
                  <h3><span className="h1red">AKIRA </span>KUROSAWA</h3>
                  <h5>Achievement: Opened up wester film markets to Japanese films.</h5>
                  <p>Analysis: Master of the historical epic whose great films, like Seven Samurai, more than stand the test of time.</p>
                  </div>
                  <div className="director ford">
                  <h3><span className="h1red">JOHN </span>FORD</h3>
                  <h5>Achievement: Has won the best director Oscar more times than anyone else (four).</h5>
                  <p>Analysis: The most important figure in the history of film westerns (Stagecoach, The Searchers) he also directed adaptations of great novels (The Grapes of Wrath).</p>
                  </div>
                  <div className="director cameron">
                  <h3><span className="h1red">JAMES </span>CAMERON</h3>
                  <h5>Achievement: Directed the biggest box office hit of all time, Avatar.</h5>
                  <p>Analysis: A string of other hits from Terminator to Titanic made him king of the multiplex.</p>
                  </div>
                  <div className="director demille">
                  <h3><span className="h1red">CECIL </span>B DEMILLE</h3>
                  <h5>Achievement: Known as the father of cinema for being the creator of cinematic showmanship, making films on a grand scale.</h5>
                  <p>Analysis: In the vast majority of his 70 films he achieved commercial as well as critical success.</p>
                  </div>
                </div>
                <div className="column-25"> 
                  <img className="w100" src={article00002_directors_films} alt="Legendary Directors Hit Films" />
                </div>
                <div className="column">
                  <img className="w100 mh350" src={article00002_directors_set2} alt="More Notable Directors" />
                </div>
              </div>  
            </article>        
          </div>
        );
    }
}

export default Article00002;