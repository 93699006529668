//copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleSearchMenu } from '../../redux/actions/appActions';
import { toggleSubMenuAbout } from '../../redux/actions/appActions';
import { toggleSubMenuCategory } from '../../redux/actions/appActions';
import { toggleCategory } from '../../redux/actions/appActions';
import { toggleSubscription } from '../../redux/actions/appActions';
import Routes from '../../containers/AppRouter/routes';
import { withRouter } from 'react-router';
import store from '../../redux/store';
import { setUser } from '../../redux/actions/userActions';
import { curDate } from '../Helpers';
// import { setCustom1 } from '../../redux/actions/customActions';
import { logout } from '../../components/Helpers';
import ico_loggedOut from '../../images/ico_loggedOut.png';
import ico_loggedIn from '../../images/ico_loggedIn.png';
import { epochTime } from '../Helpers';
import LoadingIndicator from '../Widgets/LoadingIndicator';
import { toggleLoadingIndicator } from '../../redux/actions/appActions';
import TagManager from 'react-gtm-module';
// import { DFPManager } from 'react-dfp';
import axios from 'axios';



//styles
import './index.css';


export class UserStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: {},
            prevImpression: -1,
            curImpression: parseInt(props.startTimeInSeconds, 10) || 0,
        };
    }




    static propTypes = {
        // match: PropTypes.object.isRequired,
        // location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }


    //This is the global control of ticking up the ads so long as they are not on user profile.
    //this was recently moved from each ad unit into the now UserStatus global controller.
    // tick() {
    // this.setState(state => ({
    //     prevImpression: state.curImpression,
    //     curImpression: state.curImpression + 1
    // }));
    // // console.log(this.state.curImpression);
    // if (this.state.prevImpression !== this.state.curImpression) {           
    // //     // DFPManager.getGoogletag().then((googletag) => { googletag.cmd.push(() => { googletag.pubads().refresh(); }); }); // for archive usage switchonoff
    // }
    // }

    handleFormSubmit(event) {
        // event.preventDefault();
    }
    componentDidMount() {
        const {
            history,
            toggleCategory,
            toggleSubscription,
            setUser,
        } = this.props;

        //this was to update the ads on the app from adsense originally... for now all that is disabled but still set in the code
        // this.interval = setInterval(() => this.tick(), 40000);
        let state = store.getState();
        // if (state.user.id) {

        //post to api to password verify and get paypal bearer token... use that throughout session with local storage... for paypal api
        axios({
                //url: 'https://api-m.sandbox.paypal.com/v1/oauth2/token', // for testing usage switchonoff
                url: 'https://api-m.paypal.com/v1/oauth2/token', // for production usage switchonoff
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Accept-Language': 'en_US',
                    'content-type': 'application/x-www-form-urlencoded',
                },
                auth: {
                    //username: 'AaA91dknmZx584p8LgHKg-Sx7sqaxC4j7grUF38i4IusoQdUh6H5ia53xURdeLS9P0-YDYMCx8iDvqtb', // for testing usage switchonoff
                    username: 'AQfmJ5bDKbqqFziW2BrIKTGX6dDR3V_8c09cwXWg4U-GA3w-Eo91VRqrUiwcDpHc__fp6hRh2j3y3PRV', // for production usage switchonoff
                    //password: 'EACQiCE_wW7O--lrsBWzba4r_jJlPiuLlpIh-47qFdMhyfTHUXXpK0gtbVctQ761nIFtwuTcysovnsYK', // for testing usage switchonoff
                    password: 'EBewyCbtVz0m5Vz8t9vgJsW1TJrexOAgcyyCZHf6BMTe_2gX4o3CG7AFAXbhcRlyfThSKhggmSJxhmKS' // for production usage switchonoff
                },
                params: { 'grant_type': 'client_credentials' }
            })
            .then((response) => {
                //handle success
                //you are only getting the token to cancel a subscription at this point so you get the token 
                if (response.data.access_token) {

                    // console.log('PAYPAL API connected...');

                    localStorage.setItem('paypal_token', response.data.access_token);

                    let state = store.getState();

                    // TODO if subscription_plan_id is undefined then gotta fix that shit
                    // console.log(state);
                    // console.log(localStorage.getItem('paypal_token'));
                    // check paypal user subscription
                    // console.log('https://api-m.sandbox.paypal.com/v1/billing/subscriptions/' + state.user.subscription_plan_id);
                    //let api_url = 'https://api-m.sandbox.paypal.com/v1/billing/subscriptions/' + state.user.subscription_plan_id; // for testing usage switchonoff
                    let api_url = 'https://api-m.paypal.com/v1/billing/subscriptions/' + state.user.subscription_plan_id; // for production usage switchonoff
                    // console.log(api_url);
                    // https://developer.paypal.com/docs/api/subscriptions/v1/#subscriptions_cancel

                    let config = {};

                    // START AXIOS CALL for handleGetPaypalSubscription
                    // START AXIOS CALL for handleGetPaypalSubscription
                    // START AXIOS CALL for handleGetPaypalSubscription
                    axios({
                            url: api_url,
                            method: 'get',
                            headers: {
                                // 'X-PAYPAL-SECURITY-CONTEXT': '{"consumer":{"accountNumber":1181198218909172527,"merchantId":"5KW8F2FXKX5HA"},"merchant":{"accountNumber":1659371090107732880,"merchantId":"2J6QB8YJQSJRJ"},"apiCaller":{"clientId":"AdtlNBDhgmQWi2xk6edqJVKklPFyDWxtyKuXuyVT-OgdnnKpAVsbKHgvqHHP","appId":"APP-6DV794347V142302B","payerId":"2J6QB8YJQSJRJ","accountNumber":"1659371090107732880"},"scopes":["https://api-m.paypal.com/v1/subscription/.*","https://uri.paypal.com/services/subscription","openid"]}',
                                'Content-Type': 'application/json',
                                // 'Accept': 'application/json'
                                'Authorization': 'Bearer ' + localStorage.getItem('paypal_token') // for now you have to manually get the token, but todo: get token executre post/get on promise fulfilled from token, then revoke token
                            }
                        })
                        .then((response) => {
                            // console.log(response);
                            // console.log(response.data.status);
                            if (response.data.status != 'ACTIVE') {
                                var fData = new URLSearchParams();

                                fData.append('id', state.user.id);
                                if (curDate() < state.user.expiry_date) {
                                    fData.append('subscription_plan_id', 'REGISTERED');
                                    fData.append('subscription', 20); // 20 is registered free limited, todo: if expiry date passed, next login will set to 10
                                } else {
                                    fData.append('subscription_plan_id', 'EXPIRED');
                                    fData.append('subscription', 10); // 10 expiry date passed, expired trial/or cancelled subscription and outside trial expiry date
                                }

                                const config = {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Accept': 'application/json'
                                    }
                                };

                                axios({
                                        method: 'post',
                                        //url: 'http://127.0.0.1:8080/api_v1-008/user_subscription_update_status.php', // for testing usage switchonoff
                                        url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_subscription_update_status.php', // for production usage switchonoff
                                        data: fData,
                                        config
                                    })
                                    .then((response) => {
                                        //handle success
                                        // console.log(response);
                                        //jwt set to local storage
                                        if (response.status === 200 && response.data.subscription && response.data.subscription_plan_id) {

                                            let state = store.getState();

                                            // console.log(state);

                                            //reset the props to dispatch for setUser again after axios promise fulfilled                    
                                            const {
                                                setUser,
                                                toggleSubscription,
                                                is_subscription,
                                            } = this.props;

                                            // console.log(setUser);
                                            setUser(state.user.id, state.user.username, state.user.email, state.user.newsletter, parseInt(response.data.subscription), response.data.subscription_plan_id, response.data.expiry_date);

                                            toggleSubscription(false);

                                            let messages = {}
                                            // state.user.setState({
                                            //     id: state.user.id,
                                            //     username: state.user.username,
                                            //     email: state.user.email,
                                            //     newsletter: state.user.newsletter,
                                            //     subscription: parseInt(response.data.subscription),
                                            //     subscription_plan_id: response.data.subscription_plan_id,
                                            //     expiry_date: state.user.expiry_date
                                            // });
                                            // console.log(state.user);
                                            // localStorage.removeItem('paypal_token');
                                        }
                                        // const {
                                        //     toggleLoadingIndicator,
                                        //     is_loading_indicator,
                                        // } = this.props
                                        // toggleLoadingIndicator(!is_loading_indicator);
                                    })
                                    .catch((error) => {
                                        //handle error
                                        // const {
                                        //     toggleLoadingIndicator,
                                        //     is_loading_indicator,
                                        // } = this.props
                                        // toggleLoadingIndicator(!is_loading_indicator);
                                        // console.log(error);
                                    });
                            }

                        }).catch((error) => {
                            // console.log(error);

                            var fData = new URLSearchParams();

                            fData.append('id', state.user.id);

                            //TODO: SETUP THIS EXPIRY CHECK IN A FUNCTION its used in multiple places
                            if (curDate() < state.user.expiry_date) {
                                fData.append('subscription_plan_id', 'REGISTERED');
                                fData.append('subscription', 20); // 20 is registered free limited, todo: if expiry date passed, next login will set to 10
                            } else {
                                fData.append('subscription_plan_id', 'EXPIRED');
                                fData.append('subscription', 10); // 10 expiry date passed, expired trial/or cancelled subscription and outside trial expiry date
                            }

                            // for now just set back to tier 20 normal registered... todo: add in expiry check

                            const config = {
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Accept': 'application/json'
                                }
                            };

                            axios({
                                    method: 'post',
                                    //url: 'http://127.0.0.1:8080/api_v1-008/user_subscription_update_status.php', // for testing usage switchonoff
                                    url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_subscription_update_status.php', // for production usage switchonoff
                                    data: fData,
                                    config
                                })
                                .then((response) => {
                                    //handle success
                                    // console.log(response);
                                    //jwt set to local storage
                                    if (response.status === 200 && response.data.subscription && response.data.subscription_plan_id) {
                                        let state = store.getState();

                                        //reset the props to dispatch for setUser again after axios promise fulfilled                    
                                        const {
                                            setUser,
                                            toggleSubscription,
                                            is_subscription,
                                        } = this.props;

                                        // console.log(setUser);
                                        setUser(state.user.id, state.user.username, state.user.email, state.user.newsletter, parseInt(response.data.subscription), response.data.subscription_plan_id, response.data.expiry_date);
                                        toggleSubscription(false);

                                        let messages = {}
                                        this.setState({ messages: messages });
                                        // localStorage.removeItem('paypal_token');
                                    }
                                    // const {
                                    //     toggleLoadingIndicator,
                                    //     is_loading_indicator,
                                    // } = this.props
                                    // toggleLoadingIndicator(!is_loading_indicator);
                                })
                                .catch((error) => {
                                    // handle error BSE API user_subscription_update_status
                                    // console.log(error);
                                });

                        });
                    // END AXIOS CALL for handleGetPaypalSubscription
                    // END AXIOS CALL for handleGetPaypalSubscription
                    // END AXIOS CALL for handleGetPaypalSubscription

                } else {
                    // console.log('Recieved RESPONSE, but did not get token for handleGetPaypalSubscription')
                }
            })
            .catch((error) => {
                //handle error for handleGetPaypalSubscription
                // console.log(error);
            });
        // }

        history.listen((location, action) => {
            const state = store.getState();
            // console.log('HISTORY LISTENER EVENT!'); // for console usage switchonoff
            // console.log(history.location.pathname); // for console usage switchonoff
            // const switch_category = history.location.pathname.replace(/\//i, ''); // later this could be an issue with nested routes and histories, for now this will effectively place the route needed since its one level only
            // if (switch_category.length > 0) {
            //     toggleCategory(switch_category);
            // } else {
            //     toggleCategory('home');
            // }
            if (history.location.pathname.match(/userprofile/i)) {
                // console.log(history.location.pathname)
                let access_token = localStorage.getItem('access_token');
                let expire_at = localStorage.getItem('expire_at');
                // console.log('Access token: ' + access_token);
                // console.log('EXPAT: ' + expire_at);
                // console.log('CTIME: ' + epochTime());
                // console.log(state);
            }

            if (epochTime() > localStorage.getItem('expire_at') && localStorage.getItem('expire_at') != null) {
                toggleSubscription(false);
                logout(setUser);
            }
        })
    }
    render() {
        const {
            setUser,
            is_search_menu,
            is_subscription,
            // is_submenu_about,
            // is_submenu_category,
            is_category,
            toggleSearchMenu,
            toggleSubMenuAbout,
            toggleSubMenuCategory,
            toggleCategory,
            toggleSubscription,
            toggleLoadingIndicator,
            is_loading_indicator,
            history
        } = this.props;
        const state = store.getState();
        return (
            <React.Fragment>
                {is_loading_indicator ? <div className="loader-placeholder"><LoadingIndicator /></div> : <div className="loader-placeholder"></div>}
                <div className={state.user.subscription >= 0 ? 'submenu-toggle login' : 'submenu-toggle'} onClick={()=> {
                    toggleSubMenuAbout(false);
                    toggleSubMenuCategory(false);
                    // if (is_search_menu) {toggleSearchMenu(!is_search_menu)};
                    toggleCategory('userprofile');
                    history.push(Routes.userprofile);
                }}>
                
                {/*{console.log('RENDER is_category: ' + is_category)}*/}
                <img alt="hamburger_ico" src={state.user.subscription >= 0 ? ico_loggedIn : ico_loggedOut} />
             </div>
            </React.Fragment>
        );
    }
}
UserStatus.propTypes = {
    setUser: PropTypes.func,
    toggleSearchMenu: PropTypes.func,
    toggleSubscription: PropTypes.func,
    is_search_menu: PropTypes.bool,
    is_subscription: PropTypes.bool,
    toggleLoadingIndicator: PropTypes.func,
    is_loading_indicator: PropTypes.bool,
    is_category: PropTypes.string,
    // setCustom1: PropTypes.func,
}
UserStatus.defaultProps = {
    setUser: () => {},
    toggleSearchMenu: () => {},
    toggleSubscription: () => {},
    toggleCategory: () => {},
    is_search_menu: true,
    is_subscription: false,
    toggleLoadingIndicator: () => {},
    is_loading_indicator: false,
    // setCustom1: () => {},
}
export const mapStateToProps = state => ({
    id: state.user.id,
    username: state.user.username,
    email: state.user.email,
    newsletter: state.user.newsletter,
    subscription: state.user.subscription,
    subscription_plan_id: state.user.subscription_plan_id,
    expiry_date: state.user.expiry_date,
    is_search_menu: state.app.is_search_menu,
    is_submenu_about: state.app.is_submenu_about,
    is_submenu_category: state.app.is_submenu_category,
    is_category: state.app.is_category,
    is_subscription: state.app.is_subscription,
    is_loading_indicator: state.app.is_loading_indicator,
    // playlist: state.custom1.playlist,
});
export const mapDispatchToProps = dispatch => ({
    setUser: (id, username, email, newsletter, subscription, subscription_plan_id, expiry_date) => dispatch(setUser(id, username, email, newsletter, subscription, subscription_plan_id, expiry_date)),
    toggleSearchMenu: value => dispatch(toggleSearchMenu(value)),
    toggleSubMenuAbout: value => dispatch(toggleSubMenuAbout(value)),
    toggleSubMenuCategory: value => dispatch(toggleSubMenuCategory(value)),
    toggleCategory: value => dispatch(toggleCategory(value)),
    toggleSubscription: value => dispatch(toggleSubscription(value)),
    toggleLoadingIndicator: value => dispatch(toggleLoadingIndicator(value)),
    // setCustom1: (playlist) => dispatch(setCustom1(playlist)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserStatus));
// export default UserStatus;