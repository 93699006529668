//copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Routes from '../../containers/AppRouter/routes';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import store from '../../redux/store';
import axios from 'axios';
import { setUser } from '../../redux/actions/userActions';
import { setCustom1 } from '../../redux/actions/customActions';
import { toggleSubscription } from '../../redux/actions/appActions';
import { toggleLoadingIndicator } from '../../redux/actions/appActions';
import LoadingIndicator from '../Widgets/LoadingIndicator';
import './index.css';

export class UserRegisterVerify extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            fields: { verify_code: '' },
            messages: {},
        };
    }
    componentDidMount() {
        
    }
    componentWillUnmount() {}
    

    handleSubmitVerifyResend() {
        let messages = {};
        // event.preventDefault();
        var fData = new URLSearchParams();
        fData.append('id', this.id);
        fData.append('email', this.email);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        axios({
                // method: 'get',
                method: 'post',
                 //url: 'http://127.0.0.1:8080/api_v1-008/user_register_resend.php', // for testing usage switchonoff
                url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_register_resend.php', // for production usage switchonoff 
                data: fData,
                config
            })
            .then((response) => {
                //handle success
                if (response.data === 1) {
                    messages['verify_sent'] = true;
                    messages['status'] = 'Email sent... Enter the new verification code from the email, below.'
                }
                this.setState({ messages: messages });
                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props
                toggleLoadingIndicator(!is_loading_indicator);
            })
            .catch((error) => {
                //handle error
                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props
                toggleLoadingIndicator(!is_loading_indicator);
                // console.log(error);
            });
    }

    handleSubmitVerifyCode() {
        let messages = {};
        var fData = new URLSearchParams();
        const state = store.getState();
        // console.log(this.state.fields['verify_code']);
        fData.append('username', this.username);
        // console.log(this.username);
        fData.append('verify_code', this.state.fields['verify_code']);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        axios({
                // method: 'get',
                method: 'post',
                 //url: 'http://127.0.0.1:8080/api_v1-008/user_register_verify.php', // for testing usage switchonoff
                url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_register_verify.php', // for production usage switchonoff 
                data: fData,
                config
            })
            .then((response) => {
                //handle success
                // console.log(response.data)
                if (response.status === 200 && response.data.id && response.data.subscription_plan_id) {
                    let messages = {};
                    this.id = response.data.id;
                    this.username = response.data.username;
                    this.email = response.data.email;
                    this.newsletter = parseInt(response.data.newsletter);
                    this.subscription = parseInt(response.data.subscription);
                    this.subscription_plan_id = response.data.subscription_plan_id;
                    this.expiry_date = response.data.expiry_date;
                    messages['verify_sent'] = '';
                    messages['status'] = 'Verification successful!';
                    const {
                        setUser,
                        // toggleSubscription,
                        // is_subscription,
                        // location,
                        history
                    } = this.props;
                    setUser(this.id, this.username, this.email, this.newsletter, this.subscription, this.subscription_plan_id, this.expiry_date);
                    history.push(Routes.userprofile);
                } else if (response.data.match(/code\s*expired|code not found/i)) {
                    messages['verify_sent'] = false;
                    messages['status'] = response.data;
                }
                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props
                toggleLoadingIndicator(!is_loading_indicator);
                this.setState({ messages: messages });
            })
            .catch((error) => {
                //handle error
                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props
                toggleLoadingIndicator(!is_loading_indicator);
                // console.log(error); // for console usage switchonoff
            });
    }
    

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }
    handleVerifyResend(e, is_loading_indicator, toggleLoadingIndicator) {
        e.preventDefault();
        toggleLoadingIndicator(!is_loading_indicator);
        this.handleSubmitVerifyResend();
    }
    handleVerifyCode(e, is_loading_indicator, toggleLoadingIndicator) {
        e.preventDefault();
        toggleLoadingIndicator(!is_loading_indicator);
        this.handleSubmitVerifyCode();
    }
    render() {
        const {
            setUser,
            toggleSubscription,
            is_subscription,
            toggleLoadingIndicator,
            is_loading_indicator,
            match,
            location,
            history
        } = this.props;
        // let access_token = localStorage.getItem('access_token');
        // let expire_at = localStorage.getItem('expire_at');
        const state = store.getState();
        this.id = state.user.id;
        this.username = state.user.username;
        this.email = state.user.email;
        this.newsletter = state.user.newsletter;
        this.subscription = state.user.subscription;
        this.subscription_plan_id = state.user.subscription_plan_id;
        this.expiry_date = state.user.expiry_date;
        const playlist = state.custom1.playlist;

        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>BSE - User Register Verify</title>
                    <link rel="canonical" href="https://bigstreamentertainment.com/userregisterverify" />
                    <meta name="description" content="BSE - Big Stream Entertainment streaming and entertainment portal user register verify page (Stock: BSEG)" />
                    <meta name="keywords" content="big stream, bseg, big screen entertainment, big stream, entertainment, user register verify" />
                </Helmet>
                {this.email ?
                    <React.Fragment>
                    <h2>Thank you for registering!</h2>
                    <h3>To enable more features please check your email you registered with to verify your email.</h3>
                    <p>Please allow a few minutes for email to arrive, if you do not see the email from Big Stream in your inbox, please check your spam folder also.</p>
                    <p>Verify Code <small>(Copy and paste in the verification code from your email)</small></p>                                        
                    <p><input type='text' name='verify_code' value={this.state.fields['verify_code']} onChange={this.handleChange.bind(this, 'verify_code')}/></p>
                    <p><input type="submit" onClick={e => this.handleVerifyCode(e, is_loading_indicator, toggleLoadingIndicator)} value="Verify Code" /></p>
                    {this.state.messages['verify_sent'] === true ?
                        <React.Fragment>
                            <p>Email verification re-sent, please allow a few minutes. Check your inbox for the verification email, if not there please check your spam box. Thank you.</p>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <p><span style={{color: "red"}}>{this.state.messages["status"]}</span></p>
                            <p>Resend verification email <small>(If your code expired or you lost your code you can have another verification code sent to your email.)</small></p>
                            <p><input type="submit" onClick={e => this.handleVerifyResend(e, is_loading_indicator, toggleLoadingIndicator)} value="ReSend Verify Email" /></p>
                        </React.Fragment>
                    }
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <h2>Register Verify</h2>
                        <h3>Currently no user session info for a Registration Verification Request or Verification Resend Request.</h3>
                        <p>Please login again, with the username and password you registered with.</p>

                    </React.Fragment>
                }
            </div>
        );
    };
}







UserRegisterVerify.propTypes = {
    setUser: PropTypes.func,
    toggleSubscription: PropTypes.func,
    is_subscription: PropTypes.bool,
    toggleLoadingIndicator: PropTypes.func,
    is_loading_indicator: PropTypes.bool,
    setCustom1: PropTypes.func,
}
UserRegisterVerify.defaultProps = {
    setUser: () => {},
    toggleSubscription: () => {},
    is_subscription: false,
    toggleLoadingIndicator: () => {},
    is_loading_indicator: false,
    setCustom1: () => {},
}
export const mapStateToProps = state => ({
    id: state.user.id,
    username: state.user.username,
    email: state.user.email,
    newsletter: state.user.newsletter,
    subscription: state.user.subscription,
    subscription_plan_id: state.user.subscription_plan_id,
    expiry_date: state.user.expiry_date,
    is_subscription: state.app.is_subscription,
    is_loading_indicator: state.app.is_loading_indicator,
    playlist: state.custom1.playlist,
});
export const mapDispatchToProps = dispatch => ({
    setUser: (id, username, email, newsletter, subscription, subscription_plan_id, expiry_date) => dispatch(setUser(id, username, email, newsletter, subscription, subscription_plan_id, expiry_date)),
    toggleSubscription: value => dispatch(toggleSubscription(value)),
    toggleLoadingIndicator: value => dispatch(toggleLoadingIndicator(value)),
    setCustom1: (playlist) => dispatch(setCustom1(playlist)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserRegisterVerify));