//copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import './index.css';
import {Helmet} from "react-helmet";
import RoutesHIFM from '../HIFMRouter/routeshifm';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';


export class HIFM extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    }
    render() {
      const {
          history
      } = this.props;
      
      return (
        <div>
          <Helmet>
              <meta charSet="utf-8" />
              <title>Hollywood International Filmmaker Magazine</title>
              <link rel="canonical" href="https://bigstreamentertainment.com/hollywood_international_film_magazine" />
              <meta name="description" content="BSE Hollywood International Filmmaker Magazine - Entertainment industry news, opinion articles, and events." />
              <meta name="keywords" content="hollywood, international, filmmaker, magazine, big screen, entertainment, big stream, movies, history, directors, steven spielberg, alfred hitchcock, charlie chaplin, orson welles, kathryn bigelow" />
          </Helmet>
          <article>
            <div className="row">
              <h1>Hollywood International<br /><span className="white">Filmmaker Magazine</span></h1>
              <div className="column">
                <div className="summary_wrapper">
                  <h3>The Key to Christmas</h3>
                  <p><small>2020-05-22</small></p>
                  <p>Big Screen Entertainment Group (stock: BSEG) is developing a christmas movie which looks set to give family audiences a festive treat for years to come.</p>
                  <p>
                    <div className="link" onClick={() => { history.push(RoutesHIFM.article00001); }}>Read More: The Key to Chrismas</div>
                  </p>
                </div>
                <div className="summary_wrapper">
                  <h3>The Directors Who Changed the Film Industry</h3>
                  <p><small>2020-04-20</small></p>
                  <p>Some directors do more than just make great movies, they also change film history in the process.</p>
                  <p>Such is the case with the international movie making legends listed here. They came from different places but remain united forever by their influential and impactful achievements on camera. Here are Hollywood International Filmmaker Magazine's greatest directors in history and how they moved the industry forward.</p>
                  <p>
                    <div className="link" onClick={() => { history.push(RoutesHIFM.article00002); }}>Read More: The Directors Who Changed the Film Industry</div>
                  </p>
                </div>
                <div className="summary_wrapper">
                  <h3>Collecting History</h3>
                  <p><small>2020-03-26</small></p>
                  <p>Would you pay a quarter of a million dollars for a superhero costume?</p>
                  <p>One bidder did just that, parting with $228,000 for a genuine Captain America outfit at a Marvel auction.</p>
                    <div className="link" onClick={() => { history.push(RoutesHIFM.article00003); }}>Read More: Collecting History</div>
                </div>
              </div>
            </div>
          </article>
        </div>
    );
  }
}

export default withRouter(HIFM);