
//copyright: Big Screen Entertainment Group Inc.
export const TOGGLE_SEARCH_MENU = 'TOGGLE_SEARCH_MENU';
export const TOGGLE_SUBMENU_ABOUT = 'TOGGLE_SUBMENU_ABOUT';
export const TOGGLE_SUBMENU_CATEGORY = 'TOGGLE_SUBMENU_CATEGORY';
export const TOGGLE_CATEGORY = 'TOGGLE_CATEGORY';
export const TOGGLE_SUBSCRIPTION = 'TOGGLE_SUBSCRIPTION';
export const TOGGLE_LOADING_INDICATOR = 'TOGGLE_LOADING_INDICATOR';
export const TOGGLE_LOADING_MODULE = 'TOGGLE_LOADING_MODULE';
export const TOGGLE_OVERFLOW = 'TOGGLE_OVERFLOW';
export const TOGGLE_OVERFLOW_SCROLLBOTTOM = 'TOGGLE_OVERFLOW_SCROLLBOTTOM';

export function toggleLoadingModule(value) {
  return {
    type: TOGGLE_LOADING_MODULE,
    value
  };
}

export function toggleLoadingIndicator(value) {
  return {
    type: TOGGLE_LOADING_INDICATOR,
    value
  };
}

export function toggleSubscription(value) {
  return {
    type: TOGGLE_SUBSCRIPTION,
    value
  };
}


export function toggleSearchMenu(value) {
  return {
    type: TOGGLE_SEARCH_MENU,
    value
  };
}

export function toggleSubMenuAbout(value) {
  return {
    type: TOGGLE_SUBMENU_ABOUT,
    value
  };
}

export function toggleSubMenuCategory(value) {
  return {
    type: TOGGLE_SUBMENU_CATEGORY,
    value
  };
}

export function toggleCategory(value) {
  return {
    type: TOGGLE_CATEGORY,
    value
  };
}

export function toggleOverflow(value) {
  return {
    type: TOGGLE_OVERFLOW,
    value
  };
}

export function toggleOverflowScrollBottom(value) {
  return {
    type: TOGGLE_OVERFLOW_SCROLLBOTTOM,
    value
  };
}