
//copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import img_logo from './logo.png';
import './index.css';

export class Logo extends Component {
  render() {
    return (
      <div>
        <img src={img_logo} alt="Big Stream Entertainment (Stock: BSEG)" />
      </div>
    );
  }
}

export default Logo;
