//copyright: Big Screen Entertainment Group Inc.
//Helpers
// import React, { Component } from 'react';
import { format } from 'date-fns';
import axios from 'axios';
import store from '../../redux/store';
// import { setUser } from '../../redux/actions/userActions';
// import { useEffect } from 'react';




export function curYear() {
    return new Date().getFullYear();
}

export function epochTime() {
    const now = new Date()
    const epochTime = Math.round(now.getTime() / 1000);
    return epochTime;
    // return new Date().getTime();
}

export function curDate() {
    return format(new Date(), 'yyyy-MM-dd');
}

export function curDateMediaStatsAdd() {
    return format(new Date(), 'yyyy');
}

export function getMedia(pitem, mitems) {
    for (var m = 0; m < mitems.length; m++) {
        return mitems[m].id === pitem ? mitems[m] : false
    }
}


export function getResources(pItems, mItems, search_input) {
    let resources = [];
    // console.log(search_input);
    // console.log(pItems);
    pItems.forEach(item => {
        // console.log(search_input)
        const mediaItem = mItems.find(i => i.id === item);
        // console.log("found: " +  mediaItem.title);
        if (mediaItem) {
            // var re = new RegExp(search_input, 'ig');
            // if (search_input === '') {
            //     resources.push(mediaItem);
            // } else if (mediaItem.title.match(re)) {
            // for (var x = 0; x < resources.length; x++) {

            // }
            // console.log(mediaItem.id)
            // console.log(resources);
            // console.log(resources.find(r => r.id === mediaItem.id));

            // if (!resources.find(i => i.id === mediaItem.id)) {
            // console.log(mediaItem)
            // return mediaItem;
            
            resources.push(mediaItem);
            // }
            // }
        }
    });
    return resources;
}


//get muks and then create the playlist within 
export function getPlaylistResources(playlistIDs) {
    let resources = {
        "name": "playlist",
        "itemIds": []
    };

    playlistIDs.forEach(playlistID => {
        // console.log(playlistID.mku);
        resources.itemIds.push(playlistID.mku);
    });
    // console.log(resources.length)
    return resources;
}

// this was the old get items from bse_media which got all the data but was hard to implement in home at a reasonable level
// export function getPlaylistResources(mItems, playlistIDs) {
//     let resources = [];
//     playlistIDs.forEach(playlistID => {
//         console.log(playlistID.mku);
//         var re = new RegExp(playlistID.mku);

//         mItems.forEach(item => {
//             if (item.id.match(re)) {
//                 resources.push(item);
//             }    
//         });
//     });
//     // console.log(resources.length)
//     return resources;
// }

export function getSearchResources(mItems, search_input) {
    let resources = [];
    var re = new RegExp(search_input, 'ig');
    mItems.forEach(item => {
        if (item.title.match(re)) {
            resources.push(item);
        }
    });

    return resources;
}




export function logout(setUser) {
    // console.log('localStorage before logout: ' + localStorage.getItem("expire_at"));
    localStorage.removeItem("access_token");
    localStorage.removeItem("expire_at");
    localStorage.removeItem("paypal_token");
    // localStorage.setItem("subscription", -1);
    // console.log(setUser)
    //setUser fields are (id, username, email, newsletter, subscription#, subscription_plan_id, expiry_date)
    setUser(null, null, null, null, -1, null, null);
    // console.log('logOut');
    // console.log('localStorage after logout: ' + localStorage.getItem("expire_at"));
}

// export function Ad => () {
//     return (
//         <div key={is_category}>
//          <div className="gads-container">
//                      <ins className="adsbygoogle"
//                          style={{display: 'block'}}
//                          overlays={{bottom: true}}
//                          data-ad-client="ca-pub-0685933526151888"
//                          data-ad-slot="2825561520"
//                          data-ad-format="auto"
//                          data-full-width-responsive="true">
//                      </ins>
//                  </div>
//                  {console.log('MODELEFT is_category: ' + is_category)}
//              </div>
//     );
// }


export function getResource(mkuId, mItems) {
    let resource = [];
    mItems.forEach(item => {

        const mediaItem = (mkuId === item.id ? item.id : false);

        // console.log("found", mediaItem);
        if (mediaItem) {
            resource.push(mediaItem);
        }
    });
    return resource;
}


// export function useDfpSlot() {
//     const DfpSlot = ({ path, size, id }) => {
//         useEffect(() => {
//             const googletag = window.googletag || {}
//             googletag.cmd = window.googletag || [];
//             googletag.cmd.push(function() {
//                 googletag.defineSlot(path, size.id).addService(googletag.pubads());
//                 googletag.pubads().enableSingleRequest();
//                 googletag.enableServices();
//             });
//             googletag.cmd.push(function() {
//                 googletag.display(id);
//             });
//         }, [path, size, id]);
//     };
// }

