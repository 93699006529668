//copyright: Big Screen Entertainment Group Inc.

import React, { Component } from 'react';

import HIFM from '../HIFM';
import Article00001 from '../HIFM/Article00001';
import Article00002 from '../HIFM/Article00002';
import Article00003 from '../HIFM/Article00003';
import RoutesHIFM from './routeshifm';
import { Route } from 'react-router-dom';

class HIFMRouter extends Component {
    render() {
        return (
            <React.Fragment>        	
		    <Route path={RoutesHIFM.hifm} exact component={HIFM} />
		    <Route path={RoutesHIFM.article00001} component={Article00001} />
		    <Route path={RoutesHIFM.article00002} component={Article00002} />
		    <Route path={RoutesHIFM.article00003} component={Article00003} />
      	</React.Fragment>
        );
    }
}

export default HIFMRouter;