import * as ActionTypes from '../actions/userActions';

export const initialState = {
  id: null,  
  username: null,
  email: null,
  newsletter: null,
  subscription: -1,
  subscription_plan_id: null,
  expiry_date: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_USER:
      return Object.assign({}, state, {
        id: action.id,
        username: action.username,
        email: action.email,
        newsletter: action.newsletter,
        subscription: action.subscription,
        subscription_plan_id: action.subscription_plan_id,
        expiry_date: action.expiry_date,
      });
    default:
        return state;
  }
};
