//copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import Home from '../Home';
import About from '../About';
import AboutBSEG from '../AboutBSEG';
import Media from '../Media';
import Category from '../Category';
import Privacy from '../Privacy';
import Tos from '../Tos';
import UserProfile from '../UserProfile';
import UserRegister from '../../components/UserRegister';
import UserPasswordVerify from '../../components/UserPasswordVerify';
import UserRegisterVerify from '../../components/UserRegisterVerify';
import Routes from './routes';
import HIFMRouter from '../HIFMRouter';
import { Route } from 'react-router-dom';
class AppRouter extends Component {
	render() {
		return (
			<React.Fragment>
				<Route path={Routes.home} exact component={Home} />
				<Route path={Routes.about} component={About} />
				<Route path={Routes.aboutbseg} component={AboutBSEG} />
				<Route path={Routes.media} component={Media} />
				<Route path={Routes.category} component={Category} />
				<Route path={Routes.featured} component={Category} />
				<Route path={Routes.userplaylist} component={Category} />
				<Route path={Routes.recently_added} component={Category} />
				<Route path={Routes.hidden_chambers} component={Category} />
				<Route path={Routes.documentary} component={Category} />
				<Route path={Routes.comedy} component={Category} />
				<Route path={Routes.drama} component={Category} />
				<Route path={Routes.adventure} component={Category} />
				<Route path={Routes.privacy} component={Privacy} />
				<Route path={Routes.tos} component={Tos} />
				<Route path={Routes.userprofile} component={UserProfile} />
				<Route path={Routes.userregister} component={UserRegister} />
				<Route path={Routes.userpasswordverify} component={UserPasswordVerify} />
				<Route path={Routes.userregisterverify} component={UserRegisterVerify} />
				<HIFMRouter />
				<Route path={Routes.mku_bseg_doc_2023_techtothefuture} component={Media} />
				<Route path={Routes.mku_bseg_fl_2019_hiddenchambers} component={Media} />
                <Route path={Routes.mku_bseg_fl_1999_felons} component={Media} />
                <Route path={Routes.mku_allied_sf_1923_aliceswonderland} component={Media} />
                <Route path={Routes.mku_allied_fl_1952_jackandthebeanstalk} component={Media} />
                <Route path={Routes.mku_allied_sf_1941_supermanmadscientist} component={Media} />
                <Route path={Routes.mku_allied_sf_1942_supermanbilliondollarlimited} component={Media} />
                <Route path={Routes.mku_allied_sf_1936_christmascomesbutonceayear} component={Media} />
                <Route path={Routes.mku_allied_tv_1941_thewoodywoodpeckershowpantrypan} component={Media} />
                <Route path={Routes.mku_allied_fl_1972_theadventuresofpinocchio} component={Media} />
                <Route path={Routes.mku_allied_tv_1944_thewoodywoodpeckershowthebarber} component={Media} />
                <Route path={Routes.mku_allied_doc_1975_whosoutthere} component={Media} />               
				<Route path={Routes.mku_bseg_sf_2019_ihaveadatetonight} component={Media} />
				<Route path={Routes.mku_bseg_sf_2020_cabinstay} component={Media} />
				<Route path={Routes.mku_bseg_ws_2018_hollywoodbehindthescenes} component={Media} />
				<Route path={Routes.mku_allied_doc_1941_womenindefense} component={Media} />
				<Route path={Routes.mku_allied_doc_1942_thebattleofmidway} component={Media} />
				<Route path={Routes.mku_allied_doc_1942_winningyourwings} component={Media} />
				<Route path={Routes.mku_allied_doc_1943_treeinatesttube} component={Media} />
				<Route path={Routes.mku_allied_doc_1964_ninefromlittlerock} component={Media} />
				<Route path={Routes.mku_allied_doc_1983_ronaldreaganevilempirespeech} component={Media} />
				<Route path={Routes.mku_allied_doc_1984_ronaldreagangeneralassemblyoft} component={Media} />
				<Route path={Routes.mku_allied_doc_1984_ronaldreaganpresidentialnomina} component={Media} />
				<Route path={Routes.mku_allied_doc_1987_ronaldreagansovietussummitmeet} component={Media} />
				<Route path={Routes.mku_allied_doc_1988_ronaldreaganfarewellspeechatre} component={Media} />
				<Route path={Routes.mku_allied_doc_1988_ronaldreaganwearenotfinishedye} component={Media} />
				<Route path={Routes.mku_allied_sf_1917_somebondsihaveknown} component={Media} />
				<Route path={Routes.mku_allied_sf_1923_aliceswonderland} component={Media} />
				<Route path={Routes.mku_allied_sf_1936_christmascomesbutonceayear} component={Media} />
				<Route path={Routes.mku_allied_sf_1941_supermanmadscientist} component={Media} />
				<Route path={Routes.mku_allied_sf_1942_supermanbilliondollarlimited} component={Media} />
				<Route path={Routes.mku_allied_sf_1942_supermanelectricearthquake} component={Media} />
				<Route path={Routes.mku_allied_sf_1947_bridelessgroom} component={Media} />
				<Route path={Routes.mku_allied_sf_1969_adayinthedeathofdonnyb} component={Media} />
				<Route path={Routes.mku_allied_tr_1930_kingofjazz} component={Media} />
				<Route path={Routes.mku_allied_tv_1941_thewoodywoodpeckershowpantrypan} component={Media} />
				<Route path={Routes.mku_allied_tv_1944_thewoodywoodpeckershowthebarber} component={Media} />
				<Route path={Routes.mku_bseg_ws_2019_hiddenchambers} component={Media} />
				<Route path={Routes.mku_bseg_ws_2019_hiddenchambers1} component={Media} />
				<Route path={Routes.mku_bseg_ws_2019_hiddenchambers2} component={Media} />
				<Route path={Routes.mku_bseg_ws_2019_hiddenchambers3} component={Media} />
				<Route path={Routes.mku_bseg_ws_2019_hiddenchambers4} component={Media} />
				<Route path={Routes.mku_bseg_ws_2019_hiddenchambers5} component={Media} />
				<Route path={Routes.mku_allied_doc_1918_gasalarm} component={Media} />
				<Route path={Routes.mku_allied_doc_1935_universalcurrenteventsvol1issu} component={Media} />
				<Route path={Routes.mku_allied_doc_1936_meuseargonneoffensive} component={Media} />
				<Route path={Routes.mku_allied_doc_1936_trainingofcoloredtroops1918} component={Media} />
				<Route path={Routes.mku_allied_doc_1942_fellowamericans} component={Media} />
				<Route path={Routes.mku_allied_doc_1943_airwarineurope} component={Media} />
				<Route path={Routes.mku_allied_doc_1943_recognitionofthejapanesezerofi} component={Media} />
				<Route path={Routes.mku_allied_doc_1944_battlestations} component={Media} />
				<Route path={Routes.mku_allied_doc_1944_dday} component={Media} />
				<Route path={Routes.mku_allied_doc_1945_armyairforces} component={Media} />
				<Route path={Routes.mku_allied_doc_1945_armyairforcespacific} component={Media} />
				<Route path={Routes.mku_allied_doc_1945_furyinthepacific} component={Media} />
				<Route path={Routes.mku_allied_doc_1945_surrenderinthepacific} component={Media} />
				<Route path={Routes.mku_allied_doc_1946_seedsofdestiny} component={Media} />
				<Route path={Routes.mku_allied_doc_1950_thecrimeofkorea} component={Media} />
				<Route path={Routes.mku_allied_doc_1950_unaidsinrepublicofkorea} component={Media} />
				<Route path={Routes.mku_allied_doc_1951_ourcitiesmustfight} component={Media} />
				<Route path={Routes.mku_allied_doc_1953_thekoreastory} component={Media} />
				<Route path={Routes.mku_allied_doc_1958_operationinchon} component={Media} />
				<Route path={Routes.mku_allied_doc_1967_airrescueinsoutheastasia} component={Media} />
				<Route path={Routes.mku_allied_doc_1967_anotherdayofwar} component={Media} />
				<Route path={Routes.mku_allied_doc_1976_f15_alert_eagle} component={Media} />
				<Route path={Routes.mku_allied_doc_1977_historyoftheairforce19651977} component={Media} />
				<Route path={Routes.mku_allied_doc_1978_f16_bird_of_prey} component={Media} />
				<Route path={Routes.mku_allied_doc_1978_f16_falcon_design_and_testing} component={Media} />
				<Route path={Routes.mku_allied_doc_1978_patriotismhaveityourway} component={Media} />
				<Route path={Routes.mku_allied_doc_2008_unitednationsforcescrossthe38t} component={Media} />
				<Route path={Routes.mku_allied_sf_1918_hisbestgift} component={Media} />
				<Route path={Routes.mku_allied_sf_1952_duckandcover} component={Media} />
				<Route path={Routes.mku_bseg_tr_2006_forgetaboutit} component={Media} />
				<Route path={Routes.mku_bseg_tr_2009_babysitterwanted} component={Media} />
				<Route path={Routes.mku_bseg_tr_2011_sodiumbabies} component={Media} />
				<Route path={Routes.mku_bseg_tr_2012_youcantkillstephenking} component={Media} />
				<Route path={Routes.mku_bseg_tr_2013_mosquitoman} component={Media} />
				<Route path={Routes.mku_bseg_tr_2013_singularityprinciple} component={Media} />
				<Route path={Routes.mku_allied_doc_2005_thebeatleslovemedo} component={Media} />
				<Route path={Routes.mku_allied_fl_1951_atwarwiththearmy} component={Media} />
				<Route path={Routes.mku_allied_fl_1975_gonewiththewest} component={Media} />
				<Route path={Routes.mku_bseg_fl_2006_dirtylove} component={Media} />
				<Route path={Routes.mku_bseg_fl_2006_forgetaboutit} component={Media} />
				<Route path={Routes.mku_bseg_fl_2008_targetpractice} component={Media} />
				<Route path={Routes.mku_bseg_fl_2009_babysitterwanted} component={Media} />
				<Route path={Routes.mku_bseg_fl_2011_sodiumbabies} component={Media} />
				<Route path={Routes.mku_bseg_fl_2012_youcantkillstephenking} component={Media} />
				<Route path={Routes.mku_bseg_fl_2013_crimsonwinter} component={Media} />
				<Route path={Routes.mku_bseg_fl_2013_mosquitoman} component={Media} />
				<Route path={Routes.mku_bseg_fl_2013_singularityprinciple} component={Media} />
				<Route path={Routes.mku_bseg_sf_2020_felicitymakesafriend} component={Media} />
				<Route path={Routes.mku_allied_doc_1941_targetfortonight} component={Media} />
				<Route path={Routes.mku_allied_doc_1943_decemberseventh} component={Media} />
				<Route path={Routes.mku_allied_doc_1943_photographicintelligenceforbom} component={Media} />
				<Route path={Routes.mku_allied_doc_1943_reportfromthealeutians} component={Media} />
				<Route path={Routes.mku_allied_doc_1943_wingsup} component={Media} />
				<Route path={Routes.mku_allied_doc_1944_attackbattlefornewbrittain} component={Media} />
				<Route path={Routes.mku_allied_doc_1944_thenegrosoldier} component={Media} />
				<Route path={Routes.mku_allied_doc_1947_nurembergtrial} component={Media} />
				<Route path={Routes.mku_allied_doc_1948_towardindependence} component={Media} />
				<Route path={Routes.mku_allied_doc_1950_cold_war_atomic_attack} component={Media} />
				<Route path={Routes.mku_allied_doc_1950_douglas_macarthur} component={Media} />
				<Route path={Routes.mku_allied_doc_1950_dwight_d_eisenhower} component={Media} />
				<Route path={Routes.mku_allied_doc_1950_mao_doc} component={Media} />
				<Route path={Routes.mku_allied_doc_1957_a_day_called_x} component={Media} />
				<Route path={Routes.mku_allied_doc_1960_jfkelectionnightlatenews} component={Media} />
				<Route path={Routes.mku_allied_doc_1961_cold_war_checkpoint_charlie_us} component={Media} />
				<Route path={Routes.mku_allied_doc_1962_tokeepandbeararms} component={Media} />
				<Route path={Routes.mku_allied_doc_1963_hollywoodwithoutmakeup} component={Media} />
				<Route path={Routes.mku_allied_doc_1964_thestoryofgeneraljohnjpershing} component={Media} />
				<Route path={Routes.mku_allied_doc_1966_ayeartowardstomorrow} component={Media} />
				<Route path={Routes.mku_allied_doc_1966_ho_shi_min} component={Media} />
				<Route path={Routes.mku_allied_doc_1969_illegalgamblingnumbersdiceandb} component={Media} />
				<Route path={Routes.mku_allied_doc_1972_thedistantdrummerflowersofdark} component={Media} />
				<Route path={Routes.mku_allied_doc_1973_nixonsaddressonwatergateinvest} component={Media} />
				<Route path={Routes.mku_allied_doc_1976_f15agileeagle} component={Media} />
				<Route path={Routes.mku_allied_doc_1976_wingsofgold} component={Media} />
				<Route path={Routes.mku_allied_doc_1976_wingsofsilver} component={Media} />
				<Route path={Routes.mku_allied_doc_1981_general_bradley} component={Media} />
				<Route path={Routes.mku_allied_doc_1981_ronaldreaganfarewelladdresstot} component={Media} />
				<Route path={Routes.mku_allied_doc_1987_ronaldreaganberlinwallspeech} component={Media} />
				<Route path={Routes.mku_allied_doc_2009_michaeljacksonthelegacy} component={Media} />
				<Route path={Routes.mku_allied_doc_2010_nightfall} component={Media} />
				<Route path={Routes.mku_allied_fl_1933_ashriekinthenight} component={Media} />
				<Route path={Routes.mku_allied_fl_1935_theshadowofsilklennox} component={Media} />
				<Route path={Routes.mku_allied_fl_1936_mymangodfrey} component={Media} />
				<Route path={Routes.mku_allied_fl_1937_troubleintexas} component={Media} />
				<Route path={Routes.mku_allied_fl_1938_tarzansrevenge} component={Media} />
				<Route path={Routes.mku_allied_fl_1939_gulliverstravels} component={Media} />
				<Route path={Routes.mku_allied_fl_1939_loveaffair} component={Media} />
				<Route path={Routes.mku_allied_fl_1939_thelittleprincess} component={Media} />
				<Route path={Routes.mku_allied_fl_1940_hisgirlfriday} component={Media} />
				<Route path={Routes.mku_allied_fl_1941_meetjohndoe} component={Media} />
				<Route path={Routes.mku_allied_fl_1941_thephiladelphiastory} component={Media} />
				<Route path={Routes.mku_allied_fl_1942_toomanywomen} component={Media} />
				<Route path={Routes.mku_allied_fl_1945_whistlestop} component={Media} />
				<Route path={Routes.mku_allied_fl_1946_gilda} component={Media} />
				<Route path={Routes.mku_allied_fl_1946_heartbeat} component={Media} />
				<Route path={Routes.mku_allied_fl_1946_theoutlaw} component={Media} />
				<Route path={Routes.mku_allied_fl_1948_ladyfromshanghai} component={Media} />
				<Route path={Routes.mku_allied_fl_1950_theasphaltjungle} component={Media} />
				<Route path={Routes.mku_allied_fl_1950_themanwhocheatedhimself} component={Media} />
				<Route path={Routes.mku_allied_fl_1951_hometownstory} component={Media} />
				<Route path={Routes.mku_allied_fl_1951_royalwedding} component={Media} />
				<Route path={Routes.mku_allied_fl_1952_belalugosimeetsagorilla} component={Media} />
				<Route path={Routes.mku_allied_fl_1952_dontbothertoknock} component={Media} />
				<Route path={Routes.mku_allied_fl_1952_jackandthebeanstalk} component={Media} />
				<Route path={Routes.mku_allied_fl_1952_kansaspacific} component={Media} />
				<Route path={Routes.mku_allied_fl_1952_roadtobali} component={Media} />
				<Route path={Routes.mku_allied_fl_1954_sabrina} component={Media} />
				<Route path={Routes.mku_allied_fl_1954_suddenly} component={Media} />
				<Route path={Routes.mku_allied_fl_1955_blackwidow} component={Media} />
				<Route path={Routes.mku_allied_fl_1955_daughterofhorror} component={Media} />
				<Route path={Routes.mku_allied_fl_1955_teaserama} component={Media} />
				<Route path={Routes.mku_allied_fl_1957_plan9fromouterspace} component={Media} />
				<Route path={Routes.mku_allied_fl_1960_santaclaus} component={Media} />
				<Route path={Routes.mku_allied_fl_1964_santaclausconquersthemartians} component={Media} />
				<Route path={Routes.mku_allied_fl_1968_nightofthelivingdead} component={Media} />
				<Route path={Routes.mku_allied_fl_1971_evelknievel} component={Media} />
				<Route path={Routes.mku_allied_fl_1972_theadventuresofpinocchio} component={Media} />
				<Route path={Routes.mku_allied_fl_1981_christmasmountain} component={Media} />
				<Route path={Routes.mku_allied_fl_1986_tobymcteague} component={Media} />
				<Route path={Routes.mku_allied_sf_1964_armyinactionthefinesttradition} component={Media} />
				<Route path={Routes.mku_allied_sf_1977_imafool} component={Media} />
				<Route path={Routes.mku_allied_tv_1953_thegift} component={Media} />
				<Route path={Routes.mku_allied_tv_1954_meetcorlisarcherthechristmassto} component={Media} />
				<Route path={Routes.mku_allied_tv_1954_theanswer} component={Media} />
				<Route path={Routes.mku_bseg_fl_2019_hiddenchambers} component={Media} />
				<Route path={Routes.mku_bseg_fl_2021_marilynandsinatra} component={Media} />
				<Route path={Routes.mku_bseg_ws_2021_whosthebestdoctorwhomonsterwithsy} component={Media} />
				<Route path={Routes.mku_allied_doc_1917_thebattleofthesomme} component={Media} />
				<Route path={Routes.mku_allied_doc_1924_classic_aviation_1909_1924} component={Media} />
				<Route path={Routes.mku_allied_doc_1925_classic_aviation_1925} component={Media} />
				<Route path={Routes.mku_allied_doc_1926_classic_aviation_1926} component={Media} />
				<Route path={Routes.mku_allied_doc_1935_theyanksarecoming} component={Media} />
				<Route path={Routes.mku_allied_doc_1939_invasionofpolandbythegermanarm} component={Media} />
				<Route path={Routes.mku_allied_doc_1941_bomber} component={Media} />
				<Route path={Routes.mku_allied_doc_1942_preludetowar} component={Media} />
				<Route path={Routes.mku_allied_doc_1942_warandnavydepartmentpresentdec} component={Media} />
				<Route path={Routes.mku_allied_doc_1943_combatamerica} component={Media} />
				<Route path={Routes.mku_allied_doc_1943_howtoflythebseventeen} component={Media} />
				<Route path={Routes.mku_allied_doc_1943_learnandlive} component={Media} />
				<Route path={Routes.mku_allied_doc_1943_reconnaissancepilot} component={Media} />
				<Route path={Routes.mku_allied_doc_1943_thebattleofrussia} component={Media} />
				<Route path={Routes.mku_allied_doc_1943_the_rear_gunner} component={Media} />
				<Route path={Routes.mku_allied_doc_1943_thunderbolt_combat_film_report} component={Media} />
				<Route path={Routes.mku_allied_doc_1944_aafreport1944} component={Media} />
				<Route path={Routes.mku_allied_doc_1944_attackinthepacific} component={Media} />
				<Route path={Routes.mku_allied_doc_1944_classic_aviation_1942_1944} component={Media} />
				<Route path={Routes.mku_allied_doc_1944_expansion_air_power} component={Media} />
				<Route path={Routes.mku_allied_doc_1944_landandliveinthejungle} component={Media} />
				<Route path={Routes.mku_allied_doc_1944_thebattleforthemarianas} component={Media} />
				<Route path={Routes.mku_allied_doc_1944_thebattleofchina} component={Media} />
				<Route path={Routes.mku_allied_doc_1944_the_memphis_belle_flying_fortr} component={Media} />
				<Route path={Routes.mku_allied_doc_1944_tunisianvictory} component={Media} />
				<Route path={Routes.mku_allied_doc_1944_withthemarinesattarawa} component={Media} />
				<Route path={Routes.mku_allied_doc_1945_appointmentintokyo} component={Media} />
				<Route path={Routes.mku_allied_doc_1945_knowyourenemyjapan} component={Media} />
				<Route path={Routes.mku_allied_doc_1945_thefightinglady} component={Media} />
				<Route path={Routes.mku_allied_doc_1945_thefleetthatcametostay} component={Media} />
				<Route path={Routes.mku_allied_doc_1945_thetrueglory} component={Media} />
				<Route path={Routes.mku_allied_doc_1945_the_fight_for_the_sky} component={Media} />
				<Route path={Routes.mku_allied_doc_1945_thunderbolt} component={Media} />
				<Route path={Routes.mku_allied_doc_1945_totheshoresofiwojima} component={Media} />
				<Route path={Routes.mku_allied_doc_1945_warcomestoamerica} component={Media} />
				<Route path={Routes.mku_allied_doc_1946_deathmills} component={Media} />
				<Route path={Routes.mku_allied_doc_1946_lettherebelight} component={Media} />
				<Route path={Routes.mku_allied_doc_1947_diaryofasergeant} component={Media} />
				<Route path={Routes.mku_allied_doc_1951_oneyearinkorea} component={Media} />
				<Route path={Routes.mku_allied_doc_1951_thepacificineuruption} component={Media} />
				<Route path={Routes.mku_allied_doc_1951_thisiskorea} component={Media} />
				<Route path={Routes.mku_allied_doc_1952_unoffensive} component={Media} />
				<Route path={Routes.mku_allied_doc_1953_themarinesinkorea} component={Media} />
				<Route path={Routes.mku_allied_doc_1956_communistblueprintforconquest} component={Media} />
				<Route path={Routes.mku_allied_doc_1956_woodrowwilsonspoksmanfortomorr} component={Media} />
				<Route path={Routes.mku_allied_doc_1958_classic_aviation_1944_1958} component={Media} />
				<Route path={Routes.mku_allied_doc_1959_thecommunistweaponofallure} component={Media} />
				<Route path={Routes.mku_allied_doc_1960_dwightdeisenhowerstory} component={Media} />
				<Route path={Routes.mku_allied_doc_1960_payoffinthepacific} component={Media} />
				<Route path={Routes.mku_allied_doc_1960_the24thinfantrydivisionintheko} component={Media} />
				<Route path={Routes.mku_allied_doc_1961_kamikaze} component={Media} />
				<Route path={Routes.mku_allied_doc_1962_operationabolition} component={Media} />
				<Route path={Routes.mku_allied_doc_1962_theberlinwall} component={Media} />
				<Route path={Routes.mku_allied_doc_1962_theroadtothewall} component={Media} />
				<Route path={Routes.mku_allied_doc_1963_attackonpearlharbor} component={Media} />
				<Route path={Routes.mku_allied_doc_1963_generalgeorgespatton} component={Media} />
				<Route path={Routes.mku_allied_doc_1963_thehiddenwarinvietnam} component={Media} />
				<Route path={Routes.mku_allied_doc_1964_oneweekinoctobercubanmissilecr} component={Media} />
				<Route path={Routes.mku_allied_doc_1964_operationmontagnard} component={Media} />
				<Route path={Routes.mku_allied_doc_1965_whyvietnam} component={Media} />
				<Route path={Routes.mku_allied_doc_1966_marines66} component={Media} />
				<Route path={Routes.mku_allied_doc_1967_adayinvietnam} component={Media} />
				<Route path={Routes.mku_allied_doc_1967_anationbuildsunderfire} component={Media} />
				<Route path={Routes.mku_allied_doc_1967_chinarootsofmadness} component={Media} />
				<Route path={Routes.mku_allied_doc_1967_riverpatrol} component={Media} />
				<Route path={Routes.mku_allied_doc_1967_thereisaway} component={Media} />
				<Route path={Routes.mku_allied_doc_1967_thescreamingeaglesinvietnam} component={Media} />
				<Route path={Routes.mku_allied_doc_1968_thebattleofkhesanh} component={Media} />
				<Route path={Routes.mku_allied_doc_1968_unitedstatesairforceusafcombat} component={Media} />
				<Route path={Routes.mku_allied_doc_1968_vietnamcrucible} component={Media} />
				<Route path={Routes.mku_allied_doc_1969_11tharmoredcalvaryregimentinvi} component={Media} />
				<Route path={Routes.mku_allied_doc_1969_4thinfantrydivisioninvietnam} component={Media} />
				<Route path={Routes.mku_allied_doc_1969_tosaveasoldier} component={Media} />
				<Route path={Routes.mku_allied_doc_1969_wherethegirlsare} component={Media} />
				<Route path={Routes.mku_allied_doc_1971_firstinfantrydivisioninvietnam} component={Media} />
				<Route path={Routes.mku_allied_doc_1971_thedrillsergeant} component={Media} />
				<Route path={Routes.mku_allied_doc_1971_thefacesofrescue} component={Media} />
				<Route path={Routes.mku_allied_doc_1971_vietnamvietnam} component={Media} />
				<Route path={Routes.mku_allied_doc_1972_thepresidentoftheusainthesovie} component={Media} />
				<Route path={Routes.mku_allied_doc_1978_classic_aviation_1961_1978} component={Media} />
				<Route path={Routes.mku_allied_doc_1980s_night_stalkers} component={Media} />
				<Route path={Routes.mku_allied_doc_1984_fa18_hornet_design_and_testing} component={Media} />
				<Route path={Routes.mku_allied_doc_1999_thunder_from_above} component={Media} />
				<Route path={Routes.mku_allied_doc_1999_worldwariiincolorvolumeianewwo} component={Media} />
				<Route path={Routes.mku_allied_doc_1999_worldwariiincolorvolumeitotalw} component={Media} />
				<Route path={Routes.mku_allied_doc_2005_defender_of_the_fleet} component={Media} />
				<Route path={Routes.mku_allied_doc_2007_jets_of_the_cold_war} component={Media} />
				<Route path={Routes.mku_allied_doc_2007_jets_of_the_us_air_force} component={Media} />
				<Route path={Routes.mku_allied_doc_2008_armedforcesassistancetokorea} component={Media} />
				<Route path={Routes.mku_allied_doc_2008_armymarinecounterattack} component={Media} />
				<Route path={Routes.mku_allied_doc_2008_christmasinkorea1953} component={Media} />
				<Route path={Routes.mku_allied_doc_2008_koreanoperations} component={Media} />
				<Route path={Routes.mku_allied_doc_2008_koreantrucetalk} component={Media} />
				<Route path={Routes.mku_allied_doc_2008_the2ndinfantrydivisioninthekor} component={Media} />
				<Route path={Routes.mku_allied_doc_2008_theartilleryinkoreanwar} component={Media} />
				<Route path={Routes.mku_allied_doc_2008_thetrainingonkoreansoldiers} component={Media} />
				<Route path={Routes.mku_allied_doc_2008_theunitedstatesarmyrangers} component={Media} />
				<Route path={Routes.mku_allied_doc_2008_theusarmytransportcorps} component={Media} />
				<Route path={Routes.mku_allied_doc_2008_unitednationsconsolidatebelowt} component={Media} />
				<Route path={Routes.mku_allied_doc_2018_amotionpicturehistoryofthrkore} component={Media} />
				<Route path={Routes.mku_allied_fl_1945_resistingenemyinterrogation} component={Media} />
				<Route path={Routes.mku_allied_sf_1944_ditchandlive} component={Media} />
				<Route path={Routes.mku_allied_sf_1959_savingsbondandstamppromotions} component={Media} />
				<Route path={Routes.mku_allied_sf_1962_rednightmare} component={Media} />
			</React.Fragment>
		);
	}
}

export default AppRouter;