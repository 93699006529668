//copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import '../index.css';
import article00001_the_key_to_christmas_001 from '../_images/article00001_the_key_to_christmas_001.jpg';
import article00001_the_key_to_christmas_002 from '../_images/article00001_the_key_to_christmas_002.jpg';
import article00001_the_key_to_christmas_003 from '../_images/article00001_the_key_to_christmas_003.jpg';
import {Helmet} from "react-helmet";

export class Article00003 extends Component {

    render() {
        
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>BSE Hollywood International Film Magazine - Collecting History</title>
                    <link rel="canonical" href="https://bigstreamentertainment.com/the_directors_who_changed_the_film_industry" />
                    <meta name="description" content="BSE Hollywood International Film Magazine - Would you pay a quarter of a million dollars for a superhero costume." />
                    <meta name="keywords" content="hollywood, international, marvel, captain America, collectibles, auction, history, i-collector" />
                </Helmet>
                <article>
                    <div className="row">          
                        <h1>THE KEY TO <span className="white">CHRISTMAS</span></h1>
                    </div>
                    <div className="row">
                        <div>
                            <img className="w100 pad_t10" src={article00001_the_key_to_christmas_001} alt="Big Screen Entertainment Group BSEG" />
                        </div>
                        <div className="intro">
                        <p>Big Screen Entertainment Group is developing a christmas movie which looks set to give family audiences a festive treat for years to come.</p>
                        <p>The Key to Christmas sees one kindly old man, two brothers and three gangsters mixed up in a hunt for treasure that all revolves around a very special dog.</p>                
                        <p>Big Screen Chief Executiv Kimberley Kates is directing the adventure, written by Angela Fratto, and the first pictures from filming can be seen from some of these photos.</p>
                        </div>
                        <div>
                            <img className="w100 pad_t10" src={article00001_the_key_to_christmas_002} alt="The Key to Christmas Set 1" />
                        </div>
                        <div className="intro">
                        <p>BSEG is a publicly traded company, based in Los Angeles, focused on making commercial content perfect for the fast changing media landscape.</p>
                        </div>
                        <div>
                            <img className="w100 pad_t10" src={article00001_the_key_to_christmas_003} alt="The Key to Christmas Set 2" />
                        </div> 
                    </div>                      
                </article>
            </div>
        );
    }
}

export default Article00003;