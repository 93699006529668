
//copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleSearchMenu } from '../../redux/actions/appActions';
// import { toggleAuthenticationLevel } from '../../redux/actions/appActions';
import Logo from '../../components/Logo';
// import LogoIcon from '../../components/LogoIcon';
import SearchMenu from '../SearchMenu';
import UserStatus from '../../components/UserStatus';
import Routes from '../AppRouter/routes';
import hamburger_ico from '../../images/hamburger.png';
import closed from '../../images/closed.png';
import './index.css';

// TODO: add SVG jsfile, css files for component
export class Header extends Component {
  render() {
    const {
      is_search_menu, toggleSearchMenu, 
    } = this.props;
    return (
      <div className='header-container'>
        <div className="header-submenu">
          <div className='submenu-fixed' onClick={()=>{ toggleSearchMenu(!is_search_menu); }}>
            {!is_search_menu ? <img alt="hamburger_ico" src={hamburger_ico} /> : <img alt="closed" src={closed} />}
          </div>          
        </div>
        <div className="logo-container">
        <div href={Routes.home}>
          <Logo />
        </div>
        </div>
        <div>
          <UserStatus />
        </div>          
        {is_search_menu && <SearchMenu />}
      </div>
    );  
  }
}

Header.propTypes = {
  toggleSearchMenu: PropTypes.func,
  is_search_menu: PropTypes.bool,
  // toggleAuthenticationLevel: PropTypes.func,
  // authentication_level: PropTypes.string,
}

Header.defaultProps = {
  toggleSearchMenu: () => {},
  is_search_menu: false,
  // toggleAuthenticationLevel: () => {},
  // authentication_level: 'guest',
}

export const mapStateToProps = state => ({
  is_search_menu: state.app.is_search_menu,
  // authentication_level: state.app.authentication_level,
});

export const mapDispatchToProps = dispatch => ({
  toggleSearchMenu: value => dispatch(toggleSearchMenu(value)),
  // toggleAuthenticationLevel: value => dispatch(toggleAuthenticationLevel(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
