import { PayPalButton } from "react-paypal-button-v2";
import React from 'react';
export function PayPalBtnSub1(props) {
    const { amount, currency, createSubscription, onApprove, catchError, onError, onCancel } = props;
    // console.log(props)
    //const client_id = "AaA91dknmZx584p8LgHKg-Sx7sqaxC4j7grUF38i4IusoQdUh6H5ia53xURdeLS9P0-YDYMCx8iDvqtb" // for testing usage switchonoff
    const client_id = "AQfmJ5bDKbqqFziW2BrIKTGX6dDR3V_8c09cwXWg4U-GA3w-Eo91VRqrUiwcDpHc__fp6hRh2j3y3PRV"; // for production usage switchonoff
    return (
        <PayPalButton
			amount={amount}
			currency={currency}
			createSubscription={(data, details) => createSubscription(data, details)}
			onApprove={(data, details) => onApprove(data, details)}
			onError={(err) => onError(err)}
			catchError={(err) => catchError(err)}
			onCancel={(err) => onCancel(err)}
			options={{
				clientId: client_id,
				vault:true,
				intent:'subscription'
			}}
			style={{
				shape: 'rect',
				color: 'blue',
				layout: 'vertical',
				label: 'subscribe',
			}}
		/>
	);
}
export default PayPalBtnSub1;