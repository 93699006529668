
//copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import './index.css';
import Routes from '../AppRouter/routes';
import { curYear } from '../../components/Helpers';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

export class Footer extends Component {
  static propTypes = {
        // match: PropTypes.object.isRequired,
        // location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }
    render() {
        const {          
            history
        } = this.props;
        const url_support = 'https://support.bigstreamentertainment.com';
        const url_bseg = 'https://bigscreenentgroup.com/';
        return (
          <div>
            <h2 style={{marginTop: '20px'}}>Links & More Info</h2>
            <div className='footer'>
              <div className="link footer-link" onClick={() => {                
                history.push(Routes.privacy);
              }}>Privacy Policy</div>
              <div className="link footer-link" onClick={() => window.open(url_support, '_blank')}>
                Big Stream Support
              </div>
            </div>            
            <div className='footer'>            
              <div className="link footer-link" onClick={() => {              
                history.push(Routes.tos);
              }}>Terms of Service</div>
              <div className="link footer-link" onClick={() => window.open(url_bseg, '_blank')}>
                Big Screen Site (Stock: BSEG)
              </div>
            </div>          
            <div className='footer-center'>
              <h6>Big Stream Entertainment &copy;{curYear()} All rights reserved</h6>
              <h6 className='mc400 fclear justify'><small>All trademarks, logos and service marks displayed are property of Big Screen Entertainment Group and other third-parties, and users of BSE are not permitted to use these marks without our prior written consent, and/or the consent of third parties which own the Marks/property.</small></h6>
              
            </div>
          </div>
        );
    }
}

// export default Footer;


export default withRouter(Footer);