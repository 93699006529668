import React, { Component } from 'react';
import videojs from 'video.js';
import 'videojs-contrib-ads';
import 'videojs-ima';
import 'video.js/dist/video-js.css';
import './index.css';
import { curDateMediaStatsAdd } from '../../components/Helpers';
import axios from 'axios';
import 'videojs-landscape-fullscreen';
import store from '../../redux/store';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setCustom1 } from '../../redux/actions/customActions';

// import PayPalBtnRent1 from '../PayPalBtnRent1';
import PayPalBtnSub1 from '../PayPalBtnSub1';
import LoadingIndicator from '../Widgets/LoadingIndicator';
import { toggleLoadingIndicator } from '../../redux/actions/appActions';

import Routes from '../../containers/AppRouter/routes';
import { withRouter } from 'react-router';

export class VideoPlayer extends Component {


    // Instantiate a Video.js player when the component mounts
    componentDidMount() {
        //THIS LINK PLAYS THE GOOGLE AD MANAGER VIDEO
        //IN TIME THIS WILL BE FULLY CUSTOMIZED TO HANDLE 
        let imaOptions = {
            id: 'vjs_video_3_html5_api',
            adTagUrl: 'https://pubads.g.doubleclick.net/gampad/ads?iu=/21851179924/bse_video_ad_general_001&description_url=http%3A%2F%2Fbigscreenentertainment.com&tfcd=0&npa=0&sz=400x300%7C640x480&min_ad_duration=5000&max_ad_duration=120000&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=&vad_type=linear', // for production usage switchonoff
            adLabel: ''
        }
        //VIDEO PLAYER
        // console.log(imaOptions);
        this.player = videojs(this.videoNode, this.props, () => {

            // this.player.log('onPlayerReady', this, this.player.ima(imaOptions)); //old way no need for log
            // console.log(this.player);
            // console.log(this.props);
            this.player.on('adsmanager', () => {
                //   var adsManager = response.adsManager;
                // console.log('AdsManager');
                //   console.log(adsManager);
            });

            this.player.on('ready', () => {
                // console.log('ready');                
                this.setState({ ready: true });
                //PLAYLIST CHECK - ONLOAD READY CHECK TO SEE IF MEDIA IS ALREADY ADDED 
                //PLAYLIST CHECK - IF SO THEN NOTIFY
                //TODO add remove functionality

                const state = store.getState();
                var fData = new URLSearchParams();
                fData.append('id', state.user.id);
                fData.append('mku', state.current_media.mku);
                // console.log(state)
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                };

                axios({
                        method: 'post',
                        //url: 'http://127.0.0.1:8080/api_v1-008/user_playlist_check.php', // for testing usage switchonoff
                        url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_playlist_check.php', // for production usage switchonoff
                        data: fData,
                        config
                    })
                    .then((response) => {
                        //handle success
                        // console.log(response.data);
                        // console.log(this.state);
                        if (response.data.message == 'playlist-exists') {
                            this.setState({ 
                                playlist: true,
                                license: response.data.license
                            });

                        } else if (response.data.message == 'playlist-checked') {
                            this.setState({ 
                                playlist: false,
                                license: false
                            });
                        }


                        //TODO need to check if it already exists in componentDidMount.
                    })
                    .catch((error) => {
                        // console.log('ERROR ' + error)
                    }); //end axios playlist exist
                // this.player.on('loadstart', () => {
                //     // this.setState({ ready: true });
                //     console.log('loadstart');
                // })
                toggleLoadingIndicator(false);
            }); //end player on ready

            this.player.on('error', () => {
                // console.log('inside the error listener');
                // console.log(this.player.error())
            });

            this.player.on('play', () => {
                // console.log('videoJS play');
                this.player.requestFullscreen();
                let start_time = new Date().getTime();

                this.setState({ start_time: start_time });
                // console.log(start_time);
            });

            this.player.on('touchend', () => {
                if (this.player.hasStarted()) {
                    this.player.requestFullscreen();
                }
            });

            this.player.on('seeking', () => {
                // console.log('handleSeeking');               
            });

            this.player.on('playing', () => {
                // console.log('handlePlaying');
            })

            this.player.on('pause', () => {
                let stop_time = new Date().getTime();
                let update_time = stop_time - this.state.start_time;
                update_time = parseInt(update_time / 1000);
                let total_time = this.state.seconds_played + update_time;
                this.setState({ seconds_played: total_time });
                // console.log(this.state.seconds_played);
            })

            this.player.on('fullscreenchange', () => {
                if (this.player.isFullscreen()) {
                    // console.log("Video fullscreenchange: Video entered fullscreen");
                    this.player.removeClass('video-hidden');
                } else {
                    this.player.pause();
                    // this.updateMediaStats()
                    // console.log("Video fullscreenchange: Video exited fullscreen");
                    this.player.addClass('video-hidden');
                }
            });
        });
        //VIDEO AD OPTIONS
        //PASS THE CONFIG ABOVE INTO THE PLAYER IMA FOR GOOGLE ADS  ... this to be customized to play depending on subscription etc.
        this.player.ima(imaOptions); //needed and this needs to eb just outside the this.player initialization as it needs to both initialize in the same tick... inreact that can happen async i right next to each other
        // this.setupBeforeUnloadListener();
        this.player.landscapeFullscreen({
            fullscreen: {
                enterOnRotate: true,
                exitOnRotate: true,
                alwaysInLandscapeMode: true,
                iOS: true
            }
        })
    }



    handleIMAPlay() {
        this.player.play();
    }

    updateMediaStats() {

        // console.log(this.state);
        if ((this.state.seconds_played > 1 || this.state.hit === 1) && this.state.posted === false) {
            // console.log(this.state);
            var fData = new URLSearchParams();
            var rData = JSON.parse(localStorage.getItem('rData'));
            // console.log(localStorage);
            fData.append('mku', rData.mku);
            // console.log(curDateMediaStatsAdd() + ' ' + rData.mku + ' ' + this.state.seconds_played + ' ' + this.state.hit);
            fData.append('media_date', curDateMediaStatsAdd());
            fData.append('seconds_played', this.state.seconds_played);
            fData.append('hit', (this.state.hit === 1 ? 1 : 0));
            this.setState({ posted: true });
            this.setState({ hit: 0 });

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            };

            axios({
                    method: 'post',
                    //url: 'http://127.0.0.1:8080/api_v1-008/media_stats.php', // for testing usage switchonoff
                    url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/media_stats.php', // for production usage switchonoff
                    data: fData,
                    config
                })
                .then((response) => {
                    //handle success
                    // console.log('SUCCESS' + response.data)
                    this.setState({ posted: false });

                    this.setState({ hit: 0 });
                    if (typeof response.data === 'string') {} else if (response.data === true) {}
                })
                .catch((error) => {
                    // handle error
                    // console.log('ERROR' + error)
                    this.setState({ hit: 1 });
                });
        }
    }



    componentWillUnmount() {
        // Dispose the player when the component will unmount
        // console.log('unmounting...');
        if (this.player) {
            this.player.dispose();
            this.setState({ ready: false });
            // console.log('player disposed');
        }
        // console.log(this.player);

        //update media stats
        window.removeEventListener("beforeunload", this.beforeUnLoadListener, false);
        // this.updateMediaStats();
        this.setState({ seconds_played: 0 });

    }

    handlePlay = () => {}

    handlePause = () => {
        // console.log(this.state.seconds_played);
    }

    //PLAYLIST ADD
    //
    handleUserPlaylistAdd() {
        // console.log('handleInsertUserPlaylist')

        const state = store.getState();
        var fData = new URLSearchParams();

        fData.append('id', state.user.id);
        fData.append('mku', state.current_media.mku);
        fData.append('title', state.current_media.title);
        fData.append('desc', state.current_media.description);
        fData.append('url', state.current_media.url);
        fData.append('thumbnail', state.current_media.thumbnail);
        if (state.user.subscription > 20) {
            this.state.license = 'SUBSCRIBER'
        }
        console.log(this.state.license)
        fData.append('license', this.state.license);
        // console.log(state.user.id)
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        axios({
                method: 'post',
                //url: 'http://127.0.0.1:8080/api_v1-008/user_playlist_add.php', // for testing usage switchonoff
                url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_playlist_add.php', // for production usage switchonoff
                data: fData,
                config
            })
            .then((response) => {
                //handle success
                //console.log(response.data); // for testing usage switchonoff
                //console.log(this.state); // for testing usage switchonoff
                if (response.data.message == 'playlist-added') {
                    this.setState({ 
                        playlist: true,
                        license: response.data.license
                     });
                    axios({
                            method: 'post',
                            //url: 'http://127.0.0.1:8080/api_v1-008/user_playlist_list.php', // for testing usage switchonoff
                            url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_playlist_list.php', // for production usage switchonoff
                            data: fData,
                            config
                        })
                        .then((response) => {
                            //handle success

                            //console.log(response.data); // for testing usage switchonoff

                            const {
                                setCustom1,
                                toggleLoadingIndicator,
                                is_loading_indicator,
                            } = this.props;
                            //console.log(is_loading_indicator); // for testing usage switchonoff
                            
                            toggleLoadingIndicator(false);
                            setCustom1(response.data);
                            //console.log(is_loading_indicator); // for testing usage switchonoff

                        })
                        .catch((error) => {
                            // console.log('ERROR ' + error)
                        }); //end axios playlist exist
                } else if (response.data.message(/duplicate entry/i)) {
                    // nothing for now
                }

                //TODO need to check if it already exists in componentDidMount.
            })
            .catch((error) => {
                // console.log('ERROR' + error)
                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props;
                toggleLoadingIndicator(false);
            });
    }

    //PLAYLIST REMOVE
    handleUserPlaylistRemove() {
        // console.log('handleInsertUserPlaylist')

        const state = store.getState();
        var fData = new URLSearchParams();

        // console.log(state);

        fData.append('id', state.user.id);
        fData.append('mku', state.current_media.mku);

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        axios({
                method: 'post',
                //url: 'http://127.0.0.1:8080/api_v1-008/user_playlist_remove.php', // for testing usage switchonoff
                url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_playlist_remove.php', // for production usage switchonoff
                data: fData,
                config
            })
            .then((response) => {
                //handle success
                // console.log(response.data);
                // console.log(this.state);
                if (response.data.message == 'playlist-item-removed') {
                    this.setState({ 
                        playlist: false,
                        license: false
                    });

                    axios({
                            method: 'post',
                            //url: 'http://127.0.0.1:8080/api_v1-008/user_playlist_list.php', // for testing usage switchonoff
                            url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_playlist_list.php', // for production usage switchonoff
                            data: fData,
                            config
                        })
                        .then((response) => {
                            //handle success
                            const {
                                setCustom1,
                            } = this.props;
                            // console.log(response.data);
                            const {
                                toggleLoadingIndicator,
                                is_loading_indicator,
                            } = this.props;
                            toggleLoadingIndicator(false);
                            setCustom1(response.data);

                        })
                        .catch((error) => {
                            // console.log('ERROR ' + error)
                        }); //end axios playlist exist
                } else if (response.data.message(/playlist-nodelete|playlist-na/i)) {
                    // nothing for now
                }

                //TODO need to check if it already exists in componentDidMount.
            })
            .catch((error) => {
                // console.log('ERROR' + error)
                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props;
                toggleLoadingIndicator(false);
            });
    }

    handleProgress = () => {
        // We only want to update time slider if we are not currently seeking
        if (!this.state.seeking) {
            // console.log('handleProgress')
            // let seconds_played = this.state.seconds_played;
            // seconds_played = seconds_played + 1;
            // this.setState({ seconds_played: seconds_played })
        }
    }

    constructor(props) {
        super(props);
        this.videoNode = {}
        this.state = {
            ready: false,
            url: null,
            pip: false,
            playing: false,
            controls: false,
            light: false,
            volume: 0.8,
            muted: false,
            played: 0,
            loaded: 0,
            duration: 0,
            playbackRate: 1.0,
            loop: false,
            played: false,
            start_time: 0,
            seconds_played: 0,
            posted: false,
            hit: 1,
            playlist: false,
            license: false
        }
    }

    handleLicenseSubscription = (subscription_plan_id, id) => { 

        this.state.license = subscription_plan_id;
        this.handleUserPlaylistAdd();
    }

    handleUserSubscriptionLicenseCancelGetToken() {
        const {
            toggleLoadingIndicator,
            is_loading_indicator,
        } = this.props;




        toggleLoadingIndicator(true);
        //post to login api to password verify and jwt response
        axios({
                //url: 'https://api-m.sandbox.paypal.com/v1/oauth2/token', // for testing usage switchonoff
                url: 'https://api-m.paypal.com/v1/oauth2/token', // for production usage switchonoff
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Accept-Language': 'en_US',
                    'content-type': 'application/x-www-form-urlencoded',
                },
                auth: {
                    //username: 'AaA91dknmZx584p8LgHKg-Sx7sqaxC4j7grUF38i4IusoQdUh6H5ia53xURdeLS9P0-YDYMCx8iDvqtb', // for testing usage switchonoff
                    username: 'AQfmJ5bDKbqqFziW2BrIKTGX6dDR3V_8c09cwXWg4U-GA3w-Eo91VRqrUiwcDpHc__fp6hRh2j3y3PRV', // for production usage switchonoff
                    //password: 'EACQiCE_wW7O--lrsBWzba4r_jJlPiuLlpIh-47qFdMhyfTHUXXpK0gtbVctQ761nIFtwuTcysovnsYK', // for testing usage switchonoff
                    password: 'EBewyCbtVz0m5Vz8t9vgJsW1TJrexOAgcyyCZHf6BMTe_2gX4o3CG7AFAXbhcRlyfThSKhggmSJxhmKS' // for production usage switchonoff
                },
                params: { 'grant_type': 'client_credentials' }
            })
            .then((response) => {
                //handle success
                //you are only getting the token to cancel a subscription at this point so you get the token 
                if (response.data.access_token) {
                    console.log('PAYPAL connect...');

                    //if license is instantly cancelled after purchase (same page never left), then get license from playlist
                    
                    const state = store.getState();
                    // console.log(state.custom1.playlist[state.current_media.mku].license)
                    // Object.keys(state.custom1.playlist).forEach(function(key) {
                    // console.log(key)
                    //   if (key == state.current_media.mku) {
                    //     this.state.license = state.custom1.playlist[state.current_media.mku].license;
                    //   }
                    // });
                    
                    // console.log(response.data);
                    localStorage.setItem('paypal_token', response.data.access_token)
                    // console.log(this.state.license);
                    //let api_url = 'https://api-m.sandbox.paypal.com/v1/billing/subscriptions/' + state.custom1.playlist[state.current_media.mku].license + '/cancel'; // for testing usage switchonoff
                    const api_url = 'https://api-m.paypal.com/v1/billing/subscriptions/' + state.custom1.playlist[state.current_media.mku].license + '/cancel'; // for production usage switchonoff

                    //https://developer.paypal.com/docs/api/subscriptions/v1/#subscriptions_cancel
                    let config = {};
                    config = {
                        url: api_url,
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('paypal_token') // for now you have to manually get the token, but todo: get token executre post/get on promise fulfilled from token, then revoke token
                        },
                        'data': {
                            'reason': 'Wanted to cancel.'
                        }
                    }
                    let msg = '...PAYPAL API response: Cancel User Subscription ' + state.user.username + '...';
                    this.handlePaypalCancelAPI(config, msg);

                } else if (response.data === 1) {

                } else {
                    // console.log(response.status);
                }
            })
            .catch((error) => {
                //handle error

                // console.log(error);
            });
    }

    handlePaypalCancelAPI(config, msg) {

        //post to login api to password verify and jwt response
        axios(config).then((response) => {
            //handle success
            const {
                toggleLoadingIndicator,
                is_loading_indicator,
            } = this.props;
            const state = store.getState();
            if (response) {}
            if (response.status === 204) {
                console.log('Cancelled subscription 204 status...');
                // console.log(response.data) // for console usage switchonoff
                // console.log(state.user) // for console usage switchonoff                
                this.handleUserPlaylistRemove();
                toggleLoadingIndicator(false);
                // localStorage.removeItem('subscription');
                localStorage.removeItem('paypal_token');
            }
        }).catch((error) => {
            //handle error
            // console.log(error);
        });
    }

    render() {
        // console.log(this.state);
        const state = store.getState();
        const {
            history,
            toggleLoadingIndicator,
            is_loading_indicator,
        } = this.props;
        // console.log(this.props)
        // const { bse_feed } = this.props;
        // console.log(bse_feed);

        const paypalSubscribe = (data, actions) => {
            // toggleLoadingIndicator(!is_loading_indicator);
            return actions.subscription.create({
                //'plan_id': "P-1VX93029B5878563MMY55V4A", // for testing usage switchonoff 
                'plan_id': "P-3B172779X3755913DMZCBAMA", // for production usage switchonoff
            });
        }

        const paypalOnError = (err) => {
            console.log("PaypalSubscribe Error: " + err);
            const {
                toggleLoadingIndicator,
                is_loading_indicator
            } = this.props;
            // toggleLoadingIndicator(!is_loading_indicator);
            //TODO NEED TO HAVE USER ERROR CHECKS TO SHOW
        }

        const paypalOnApprove = (data, detail) => {
            // call the backend api to store transaction details
            let state = store.getState();
            console.log("Paypal license approved");

            // console.log(JSON.stringify(data)); // for console usage switchonoff
            // console.log(JSON.stringify(detail)); // for console usage switchonoff
            // console.log(JSON.stringify(state)); // for console usage switchonoff
            this.handleLicenseSubscription(data.subscriptionID, state.user.id);
        };
        // console.log(this.props)
        return (
            <div>
                <p><strong>Viewing Options</strong></p>
                <hr />
                    {/*{(this.state.ready == true && state.user.subscription < 10) && <button onClick={()=>{history.push(Routes.userprofile);}}>Login/Subscribe to Play</button> }*/}
                    {/*{(this.state.ready == true && state.user.subscription === 10) && <button onClick={()=>{history.push(Routes.userprofile);}}>Subscribe to Play</button> }*/}
                    {((this.state.ready == true && state.user.subscription > 10) || (this.state.ready == true && state.user.subscription <= 10 && this.state.playlist === true)) && <button className="bse-buttons bse-buttons-play" onClick={() => this.handleIMAPlay()}></button> }
                    {(this.state.ready == true && state.user.subscription > 20) && 
                        <div>
                            <p><strong>Playlist Options</strong></p>
                            <hr />
                            {(this.state.playlist == false) ?
                                <div>
                                    <button className="bse-buttons bse-buttons-playlist-add playlist_false" onClick={() => this.handleUserPlaylistAdd()}></button> 
                                </div>
                                :
                                <div>                                    
                                    <button className="bse-buttons bse-buttons-playlist-remove playlist_true" onClick={() => this.handleUserPlaylistRemove()}></button> 
                                </div>
                            }
                        </div>
                    }
                
                <div className="thirdparty-buttons">
                
                    {(state.user.subscription <= 20 && state.user.subscription >= 10 && this.state.playlist == false) &&  // 30 if not paying then show subscribe button

                        <div>                        
                        <h4>Initial license subscription fee of $1.99 plus $1.49 each week until subscription cancelled.</h4><h5><strong><u>NOTE</u></strong>: Cancel anytime, once license subscription cancelled media play will be disabled unless re-licensed.</h5><br />
                            <PayPalBtnSub1
                                    currency = "USD"
                                    createSubscription={paypalSubscribe}
                                    onApprove={paypalOnApprove}
                                    catchError={paypalOnError}
                                    onError={paypalOnError}
                                    onCancel={paypalOnError}
                                    
                                />
                        </div>
                    }
                    {(state.user.subscription  < 10)  &&
                        <div>                             
                             <button onClick={()=>{history.push(Routes.userprofile);}}>Login/Register to View</button>
                        </div>
                    }
                    {(this.state.ready == true && state.user.subscription <= 10 && this.state.playlist === true) &&
                        <div> 
                            <p><strong>Cancel License/Rental</strong></p>
                            <hr />                            
                             {!is_loading_indicator ? <input type='button' onClick={e => this.handleUserSubscriptionLicenseCancelGetToken()} value='Cancel License' /> : <LoadingIndicator />}
                        </div>
                    }
                </div>
                <div data-vjs-player >
                    <video ref={node => this.videoNode = node} className="video-js video-hidden" controls  data-setup='{ "inactivityTimeout": 0 }'></video>
                </div>
            </div>
        );
    }
}


VideoPlayer.propTypes = {
    setCustom1: PropTypes.func,
    toggleLoadingIndicator: PropTypes.func,
    is_loading_indicator: PropTypes.bool,
};
VideoPlayer.defaultProps = {
    setCustom1: () => {},
    toggleLoadingIndicator: () => {},
    is_loading_indicator: false,
}
export const mapStateToProps = state => ({
    playlist: state.custom1.playlist,
    is_loading_indicator: state.app.is_loading_indicator,
});
export const mapDispatchToProps = dispatch => ({
    setCustom1: (playlist) => dispatch(setCustom1(playlist)),
    toggleLoadingIndicator: value => dispatch(toggleLoadingIndicator(value)),
});


// export default VideoPlayer;
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VideoPlayer));