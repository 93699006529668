//copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
// import { findDOMNode } from 'react-dom';
// import screenfull from 'screenfull';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { setMedia } from '../../redux/actions/currentMediaActions';
import HorizontalScrollBar from '../../components/HorizontalScrollBar';
import { useHistory } from 'react-router-dom';
// import useScript from '../../hooks/useScript';
import { getResource } from '../../components/Helpers';
import bse_feed from '../../data/bse_feed.json';
import axios from 'axios';
import './index.css';

import store from '../../redux/store';

import VideoPlayer from '../../components/VideoPlayer';

export class Media extends Component {

    constructor(props) {
        super(props);
        this.state = {
            url: null,
            pip: false,
            playing: false,
            controls: false,
            light: false,
            volume: 0.8,
            muted: false,
            played: 0,
            loaded: 0,
            duration: 0,
            playbackRate: 1.0,
            loop: false,
            played: false,
            seconds_played: 0,
            posted: false,
            hit: 1,
            bse_feed_alltitles: null,
            bse_media: {},
            videoJsOptions: {},
            current_media: {},
        }

    }

    componentDidMount() {
        // console.log('mount')
        const { mku, title, url, description, thumbnail, license1, license2, videoJsOptions, setmedia, history } = this.props;
        
        // const state = store.getState();
        console.log(this.props)
        if (this.props.mku == null) {
            axios.get('data-876dba1376e6c3ee12a07a6e0db389d6/bse_feed-alltitles.json')
                .then((response) => {
                    console.log(response.data)
                    const { mku, title, url, description, thumbnail, license1, license2, setMedia, history } = this.props;
                    this.setState({ bse_feed_alltitles: response.data });
                    // console.log(this.state.bse_feed_alltitles)
                    var win_mku = window.location.href.match(/mku.+/i)[0];
                    // console.log(win_mku)
                    const bse_media = this.state.bse_feed_alltitles;
                    // setMedia()
                    for (var r = 0; r < bse_media.length; r++) {
                        //id is used in the bse feed json because it coincides with what ROKU is also looking for.  ID here is MKU everywhere else
                        // console.log(bse_media[r].id + ' ' + win_mku)
                        if (bse_media[r].id === win_mku) {
                            const videoJsOptions = {
                                autoplay: false,
                                controls: true,
                                // fill: true,
                                responsive: true,
                                sources: [{
                                    src: bse_media[r].content.videos[0].url,
                                    type: 'video/mp4'
                                }]
                            }
                            // console.log('Start');
                            setMedia(bse_media[r].id,
                                bse_media[r].title,
                                bse_media[r].shortDescription,
                                bse_media[r].content.videos[0].url,
                                bse_media[r].thumbnail,
                                bse_media[r].content.videos[0].license1,
                                bse_media[r].content.videos[0].license2,
                                videoJsOptions,

                            )
                            // console.log('Continue');
                            continue;
                        }
                    }
                })
                .catch((error) => {
                    // console.log(error);
                })
        } else {
            
        }
    }

    render() {

        const { mku, title, url, description, thumbnail, episodes, license1, license2, videoJsOptions, setMedia, history } = this.props;
        // console.log(this.props)
        // const this.props = props => {
        //   useScript('https://bigstreamentertainment.com/data/ads.js');
        //   useScript('https://imasdk.googleapis.com/js/sdkloader/ima3.js');
        // }

        const meta_title = "BSE - " + title;
        // const state = store.getState();
        //console.log(this.props.videoJsOptions) // for testing usage switchonoff
        //console.log(this.props.episodes) // for testing usage switchonoff

        return (
            <div>
                <div className="container">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{meta_title}</title>
                        <meta name="description" content={description} />
                        <link rel="canonical" href={window.location.href} />
                        <meta property="og:title" content={title} />
                        <meta property="og:image" content={thumbnail} />
                        <meta property="og:image:type" content="image/jpg" />
                    </Helmet>
                               
                    <div className='player-overlay-background'>
                        {console.log(this.props)}
                        <div className='player-overlay-content'>
                            {/*<img src={retData.thumbnail} />*/}                     
                            <div className='player-overlay-thumbnail'>
                                <img src={thumbnail} />
                            </div>
                            <div className='player-overlay-description'>
                                <h1>{title}</h1>
                                <p>{description}</p>
                            </div>
                        </div>
                    
                        <div className="player-overlay-buttons">
                            {this.props.videoJsOptions && 
                                <VideoPlayer {...this.props.videoJsOptions} />     
                            }
                        </div>
                    </div>                    
                </div>
                {this.props.episodes &&
                    <div style={{width: '100%', float: 'left'}}>
                        <h3>Episodes</h3>
                        <HorizontalScrollBar key={title}
                            title={title}
                            clickAction={setMedia}
                            resources={episodes}
                            history={history}
                        />
                    </div>
                }
            </div>
        );
    }
}

Media.propTypes = {
    mku: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string,
    thumbnail: PropTypes.string,
    episodes: PropTypes.array,
    license1: PropTypes.number,
    license2: PropTypes.number,
    videoJsOptions: PropTypes.object,
    setMedia: PropTypes.func,
};

Media.defaultProps = {
    mku: '',
    title: '',
    description: '',
    url: '',
    thumbnail: '',
    episodes: null,
    license1: 0,
    license2: 0,
    videoJsOptions: {},
    setMedia: () => {},
};

function importJSON(r) {
    return r.keys().map(r);
}

export const mapStateToProps = state => ({
    mku: state.current_media.mku,
    title: state.current_media.title,
    description: state.current_media.description,
    url: state.current_media.url,
    thumbnail: state.current_media.thumbnail,
    episodes: state.current_media.episodes,
    license1: state.current_media.license1,
    license2: state.current_media.license2,
    videoJsOptions: state.current_media.videoJsOptions
});

export const mapDispatchToProps = dispatch => ({
    setMedia: (mku, title, desc, url, thumbnail, episodes, license1, license2, videoJsOptions) => dispatch(setMedia(mku, title, desc, url, thumbnail, episodes, license1, license2, videoJsOptions)),
    // setCustom1: (playlist) => dispatch(setCustom1(playlist)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Media);