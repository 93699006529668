//copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleSubscription } from '../../redux/actions/appActions';
import { handleGetPaypalSubscriptionStatus } from '../Helpers';

import store from '../../redux/store';
// import { epochTime } from '../Helpers';
import { curDate } from '../Helpers';
import { setUser } from '../../redux/actions/userActions';
import { setCustom1 } from '../../redux/actions/customActions';
// import { loadReCaptcha } from 'react-recaptcha-v3';
// import { ReCaptcha } from 'react-recaptcha-v3';
import './index.css';
import LoadingIndicator from '../Widgets/LoadingIndicator';
import { toggleLoadingIndicator } from '../../redux/actions/appActions';

import UserPasswordVerify from '../UserPasswordVerify';
import Routes from '../../containers/AppRouter/routes';
import { withRouter } from 'react-router';

export class UserLogin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: { username_email: '', password: '', verify_code: '', verify_sent: false, password_reset: '', confirm_password_reset: '' },
            messages: {},
        };
    }

    handleFormSubmitLogin(event) {    
        var fData = new URLSearchParams();
        fData.append('username_email', this.state.fields['username_email']);
        fData.append('password', this.state.fields['password']);
        
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        
        axios({
                method: 'post',
                //url: 'http://127.0.0.1:8080/api_v1-008/user_login_jwt.php', // for testing usage switchonoff 
                url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_login_jwt.php', // for production usage switchonoff 
                data: fData,
                config
            })
            .then((response) => {
                //handle success
                //console.log(response.data); // for console usage switchonoff     
                this.setState({
                    fields: { username_email: '', password: '', verify_code: '', verify_sent: false, password_reset: '', confirm_password_reset: '' },
                    messages: {}
                });
                //jwt set to local storage
                if (response.status === 200 && response.data.jwt && response.data.expireAt) {
                    let jwt = response.data.jwt;
                    let expire_at = response.data.expireAt;
                    let messages = {};
                    this.state.fields['id'] = response.data.id;
                    this.state.fields['username'] = response.data.username;
                    this.state.fields['email'] = response.data.email;
                    this.state.fields['newsletter'] = parseInt(response.data.newsletter);
                    this.state.fields['subscription'] = parseInt(response.data.subscription);
                    this.state.fields['subscription_plan_id'] = response.data.subscription_plan_id;
                    this.state.fields['expiry_date'] = response.data.expiry_date;
                    //set localStorage of application user.  This data will be used until token expiry or logout
                    localStorage.setItem('id', this.state.fields['id'])
                    localStorage.setItem('access_token', jwt);
                    localStorage.setItem('expire_at', expire_at);
                    //paypal get bearer token
                    if (this.state.fields['subscription'] > 0) {
                        axios({
                                //url: 'https://api-m.sandbox.paypal.com/v1/oauth2/token', // for testing usage switchonoff
                                url: 'https://api-m.paypal.com/v1/oauth2/token', // for production usage switchonoff
                                method: 'post',
                                headers: {
                                    Accept: 'application/json',
                                    'Accept-Language': 'en_US',
                                    'content-type': 'application/x-www-form-urlencoded',
                                },
                                auth: {
                                    //username: 'AaA91dknmZx584p8LgHKg-Sx7sqaxC4j7grUF38i4IusoQdUh6H5ia53xURdeLS9P0-YDYMCx8iDvqtb', // for testing usage switchonoff
                                    username: 'AQfmJ5bDKbqqFziW2BrIKTGX6dDR3V_8c09cwXWg4U-GA3w-Eo91VRqrUiwcDpHc__fp6hRh2j3y3PRV', // for production usage switchonoff
                                    //password: 'EACQiCE_wW7O--lrsBWzba4r_jJlPiuLlpIh-47qFdMhyfTHUXXpK0gtbVctQ761nIFtwuTcysovnsYK', // for testing usage switchonoff
                                    password: 'EBewyCbtVz0m5Vz8t9vgJsW1TJrexOAgcyyCZHf6BMTe_2gX4o3CG7AFAXbhcRlyfThSKhggmSJxhmKS' // for production usage switchonoff
                                },
                                params: { 'grant_type': 'client_credentials' }
                            })
                            .then((response) => {
                                //handle success - bearer token received
                                if (response.data.access_token) {
                                    localStorage.setItem('paypal_token', response.data.access_token);
                                    let state = store.getState();
                                    //check paypal user subscription
                                    //let api_url = 'https://api-m.sandbox.paypal.com/v1/billing/subscriptions/' + state.user.subscription_plan_id; // for testing usage switchonoff
                                    const api_url = 'https://api-m.paypal.com/v1/billing/subscriptions/' + state.user.subscription_plan_id; // for production usage switchonoff

                                    //paypal get paypal subscription
                                    axios({
                                            url: api_url,
                                            method: 'get',
                                            headers: {
                                                // 'X-PAYPAL-SECURITY-CONTEXT': '{"consumer":{"accountNumber":1181198218909172527,"merchantId":"5KW8F2FXKX5HA"},"merchant":{"accountNumber":1659371090107732880,"merchantId":"2J6QB8YJQSJRJ"},"apiCaller":{"clientId":"AdtlNBDhgmQWi2xk6edqJVKklPFyDWxtyKuXuyVT-OgdnnKpAVsbKHgvqHHP","appId":"APP-6DV794347V142302B","payerId":"2J6QB8YJQSJRJ","accountNumber":"1659371090107732880"},"scopes":["https://api-m.paypal.com/v1/subscription/.*","https://uri.paypal.com/services/subscription","openid"]}',
                                                'Content-Type': 'application/json',
                                                // 'Accept': 'application/json'
                                                'Authorization': 'Bearer ' + localStorage.getItem('paypal_token') // for now you have to manually get the token, but todo: get token executre post/get on promise fulfilled from token, then revoke token
                                            }
                                        })
                                        .then((response) => {                                            
                                            if (response.data.status != 'ACTIVE') {
                                                var fData = new URLSearchParams();
                                                fData.append('id', state.user.id);

                                                if (curDate() < state.user.expiry_date) {
                                                    fData.append('subscription_plan_id', 'REGISTERED');
                                                    fData.append('subscription', 20); // 20 is registered free limited, todo: if expiry date passed, next login will set to 10
                                                } else {
                                                    fData.append('subscription_plan_id', 'EXPIRED');
                                                    fData.append('subscription', 10); // 10 expiry date passed, expired trial/or cancelled subscription and outside trial expiry date
                                                }

                                                let config = {
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Accept': 'application/json'
                                                    }
                                                };
                                                //bse-api paypal NO subscription update user subscription status
                                                axios({
                                                        method: 'post',
                                                        //url: 'http://127.0.0.1:8080/api_v1-008/user_subscription_update_status.php', // for testing usage switchonoff
                                                        url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_subscription_update_status.php', // for production usage switchonoff
                                                        data: fData,
                                                        config
                                                    })
                                                    .then((response) => {
                                                        //bse-api handle success update user subscription for no paypal subscription registered or expired
                                                        if (response.status === 200 && response.data.subscription && response.data.subscription_plan_id) {
                                                            let state = store.getState();
                                                            //reset the props to dispatch for setUserAction again after axios promise fulfilled                    
                                                            const {
                                                                setUserAction,
                                                                toggleSubscription,
                                                                is_subscription,
                                                            } = this.props;

                                                            // console.log(setUserAction); //console usage switchonoff
                                                            setUserAction(state.user.id, state.user.username, state.user.email, state.user.newsletter, parseInt(response.data.subscription), response.data.subscription_plan_id, state.user.expiry_date);
                                                            toggleSubscription(false);
                                                            let messages = {}                                                            
                                                        }
                                                        // const {
                                                        //     toggleLoadingIndicator,
                                                        //     is_loading_indicator,
                                                        // } = this.props
                                                        // toggleLoadingIndicator(!is_loading_indicator);
                                                    })
                                                    .catch((error) => {
                                                        //handle error
                                                        // const {
                                                        //     toggleLoadingIndicator,
                                                        //     is_loading_indicator,
                                                        // } = this.props
                                                        // toggleLoadingIndicator(!is_loading_indicator);
                                                        // console.log(error);
                                                    });
                                            }

                                        }).catch((error) => {
                                            // console.log(error);
                                            var fData = new URLSearchParams();
                                            fData.append('id', state.user.id);
                                            //TODO: SETUP THIS EXPIRY CHECK IN A FUNCTION its used in multiple places
                                            if (curDate() < state.user.expiry_date) {
                                                fData.append('subscription_plan_id', 'REGISTERED');
                                                fData.append('subscription', 20); // 20 is registered free limited, todo: if expiry date passed, next login will set to 10
                                            } else {
                                                fData.append('subscription_plan_id', 'EXPIRED');
                                                fData.append('subscription', 10); // 10 expiry date passed, expired trial/or cancelled subscription and outside trial expiry date
                                            }
                                            const config = {
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    'Accept': 'application/json'
                                                }
                                            };

                                            axios({
                                                    method: 'post',
                                                    //url: 'http://127.0.0.1:8080/api_v1-008/user_subscription_update_status.php', // for testing usage switchonoff
                                                    url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_subscription_update_status.php', // for production usage switchonoff
                                                    data: fData,
                                                    config
                                                })
                                                .then((response) => {
                                                    //handle success
                                                    // console.log(response)
                                                    //jwt set to local storage
                                                    if (response.status === 200 && response.data.subscription && response.data.subscription_plan_id) {
                                                        let state = store.getState();
                                                        //reset the props to dispatch for setUserAction again after axios promise fulfilled                    
                                                        const {
                                                            setUserAction,
                                                            toggleSubscription,
                                                            is_subscription,
                                                        } = this.props;

                                                        // console.log(setUserAction);
                                                        setUserAction(state.user.id, state.user.username, state.user.email, state.user.newsletter, parseInt(response.data.subscription), response.data.subscription_plan_id, state.user.expiry_date);
                                                        toggleSubscription(false);

                                                        // let messages = {}
                                                        // this.setState({ messages: messages });
                                                        // localStorage.removeItem('paypal_token');
                                                    }
                                                    // const {
                                                    //     toggleLoadingIndicator,
                                                    //     is_loading_indicator,
                                                    // } = this.props
                                                    // toggleLoadingIndicator(!is_loading_indicator);
                                                })
                                                .catch((error) => {
                                                    // handle error BSE API user_subscription_update_status
                                                    // console.log(error);
                                                });

                                        });
                                    // END AXIOS CALL for handleGetPaypalSubscription
                                } else {
                                    // console.log('Received RESPONSE, but did not get token for handleGetPaypalSubscription')
                                }
                            })
                            .catch((error) => {
                                //handle error for handleGetPaypalSubscription
                                // console.log(error);
                            });
                        // END registered user paypal token and info check etc.
                    } // END if subscription === 0 check if its unverified login user... is unverified then send to verify page
                    //reset the props to dispatch for setUserAction again after axios promise fulfilled                    
                    const {
                        setUserAction,
                        toggleSubscription,
                        is_subscription,
                        history
                    } = this.props;
                    // the cur local user time
                    // epochTime();

                    //now that guest subscription is also sorted... set the subscription to local storage as well as UserAction state.
                    localStorage.setItem('subscription', this.state.fields['subscription']);
                    // console.log(setUserAction);
                    setUserAction(this.state.fields['id'], this.state.fields['username'], this.state.fields['email'], this.state.fields['newsletter'], this.state.fields['subscription'], this.state.fields['subscription_plan_id'], this.state.fields['expiry_date']);
                    if (this.state.fields['subscription'] > 1) {
                        toggleSubscription(!is_subscription);
                    } else if (this.state.fields['subscription'] === 0) {
                        history.push(Routes.userregisterverify)
                    }
                    //set user response success

                    //NOW THAT USER is VERIFIED or not... see about playlists if SUBSCRIPTION is 30+
                    const state = store.getState();
                    // console.log(state);
                    // if (state.user.subscription >= 30) { //is a paying subscriber and has playlist functionality
                        // console.log(state.user.subscription);
                        var fData = new URLSearchParams();
                        fData.append('id', state.user.id);
                        // console.log(state)
                        const config = {
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            }
                        };

                        axios({
                                method: 'post',
                                //url: 'http://127.0.0.1:8080/api_v1-008/user_playlist_list.php', // for testing usage switchonoff
                                url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_playlist_list.php', // for production usage switchonoff
                                data: fData,
                                config
                            })
                            .then((response) => {
                                //handle success
                                const {
                                    setCustom1,
                                } = this.props;
                                // console.log(response.data);
                                setCustom1(response.data);

                            })
                            .catch((error) => {
                                // console.log('ERROR ' + error)
                            }); //end axios playlist exist
                    // }
                } else if (response.status === 401 || response.data.match(/access denied/i)) {
                    // console.log('Permission denied...');
                    let fields = this.state.fields;
                }

                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props;
                let messages = {}
                toggleLoadingIndicator(!is_loading_indicator);
                // console.log(this.state);                
            })
            .catch((error) => {
                //handle error
                let messages = {}
                // this.setState({ messages: {} });
                if (error.response) {
                    if (error.response.status === 500) {
                        // console.log('500 Unable to reach API server...');
                        messages['loginNo'] = 'Unable to connect to server...';
                    } else {
                        // console.log(error)
                        messages['loginNo'] = 'Error! - Invalid credentials...';
                    }
                    this.setState({ messages: messages });
                } else {
                    // messages['loginNo'] = 'Please wait...';
                }


                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props
                toggleLoadingIndicator(!is_loading_indicator);
            });
    }

    handleFormSubmitResetPassword(event) {

        let messages = {};
        let fields = {};
        var fData = new URLSearchParams();
        console.log(this.state.fields['username_email'])
        fData.append('username_email', this.state.fields['username_email']);
        // console.log('RESET : : : ')
        // console.log(this.state.fields['username_email'])
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        // console.log(this.state.fields['username_email'])
        //post to login api to password verify and jwt response
        axios({
                // method: 'get',
                method: 'post',
                //url: 'http://127.0.0.1:8080/api_v1-008/user_reset_password.php', // for testing usage switchonoff
                url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_reset_password.php', // for production usage switchonoff 
                data: fData,
                config
            })
            .then((response) => {
                //handle success
                // console.log(response.status);
                console.log(response.data);
                // console.log(response.data.username_email);
                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props

                if (response.status === 200 && response.data.username_email !== 'mailfail') {
                    fields['verify_sent'] = true;
                    fields['username_email'] = response.data.username_email;

                    const {
                        setUserAction,
                        history
                    } = this.props;
                    setUserAction(null, fields['username_email'], null, null, -1, null, null);
                    console.log(setUserAction(null, fields['username_email'], null, null, -1, null, null))
                    toggleLoadingIndicator(!is_loading_indicator);
                    history.push(Routes.userpasswordverify);
                } else if (response.status === 200 && response.data.username_email === 'mailfail' || response.status === 401) {
                    // console.log(response.data)
                    messages['status'] = 'No username or email address found.  Please enter your BSE username or email address you signed up with.'
                    this.setState({ messages: messages });
                    this.setState({ fields: { verify_sent: fields['verify_sent'], username_email: fields['username_email'] } });
                    toggleLoadingIndicator(!is_loading_indicator);
                }
            })
            .catch((error) => {
                //handle error
                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props
                toggleLoadingIndicator(!is_loading_indicator);
                // console.log(error);
            });
    }


    handleVerifyResend(e, is_loading_indicator, toggleLoadingIndicator) {
        e.preventDefault();
        toggleLoadingIndicator(!is_loading_indicator);
        this.handleSubmitVerifyResend();
    }


    handleValidationLogin() {
        let fields = this.state.fields;
        let messages = {};
        // console.log(this.state);
        let formIsValid = true;
        //Password
        if (!fields['username_email']) {
            formIsValid = false;
            messages['username_email'] = 'Cannot be empty';
        }
        //Password
        if (!fields['password']) {
            formIsValid = false;
            messages['password'] = 'Cannot be empty';
        }
        this.setState({ messages: messages });
        // console.log(formIsValid);
        return formIsValid;
    }

    handleValidationResetPassword() {
        let fields = this.state.fields;
        let messages = {};
        // console.log(this.state);
        let formIsValid = true;
        //Password
        if (!fields['username_email']) {
            formIsValid = false;
            messages['username_email'] = 'Cannot be empty';
        }

        this.setState({ messages: messages });
        // console.log(formIsValid);
        return formIsValid;
    }



    checkSubmitLogin(e, is_loading_indicator, toggleLoadingIndicator) {
        e.preventDefault();
        if (this.handleValidationLogin()) {

            toggleLoadingIndicator(!is_loading_indicator);
            // console.log('1 - ' + is_loading_indicator);
            this.setState({ messages: {} })
            this.handleFormSubmitLogin();
            // this.recaptcha.execute();
        } else {
            // alert('Form has messages.');
        }
    }


    checkSubmitResetPassword(e, is_loading_indicator, toggleLoadingIndicator) {
        e.preventDefault();
        if (this.handleValidationResetPassword()) {
            toggleLoadingIndicator(!is_loading_indicator);
            this.setState({ messages: {} })
            this.handleFormSubmitResetPassword();
            // this.recaptcha.execute();
        } else {
            // alert('Form has messages.');
        }
    }



    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        fields['username_email'] = fields['username_email'];
        // console.log(field)
        this.setState({ fields });
        // console.log(this.state.fields);
    }



    render() {
        // const { setUserAction } = this.props;
        // const { id, username, email, newsletter, subscription, fields, messages } = this.props;        
        const {
            toggleSubscription,
            is_subscription,
            toggleLoadingIndicator,
            is_loading_indicator,
        } = this.props;

        return (
            <div>
            <div className='user-login-container'>
                <h2 style={{margin: '0'}}>Login</h2>
                <h3 style={{margin: '0'}}>Already registered... Login</h3>
                <p>Not registered... scroll down for form to register.</p>
                <br />
                <br />
                <form>
                    {/*<button className='btn btn-primary' onClick = { this.register }>Sign up</button>*/}
                    <label>Username or Email</label> <span style={{color: 'red'}}>{this.state.messages.username_email}</span><br />
                    <input type='text' name='username_email' value={this.state.fields['username_email']} onLoad={this.handleChange.bind(this, 'username_email')} onChange={this.handleChange.bind(this, 'username_email')}/>
                    <br />
                    <label>Password</label> <span style={{color: 'red'}}>{this.state.messages['password']}</span><br />
                    <input type='password' name='password'  value={this.state.fields['password']} onLoad={this.handleChange.bind(this, 'password')} onChange={this.handleChange.bind(this, 'password')}/>
                    
                    <p style={{minHeight: '22px'}}><span style={{color: "red"}}>{this.state.messages["status"]}</span> <span className={this.state.messages['loginNo'] ? 'message_error' : 'message_success'}>{this.state.messages['loginNo'] ? this.state.messages['loginNo'] : this.state.messages['loginYes']}</span></p>
                    <input className="pleft" type='submit' onClick={e => this.checkSubmitLogin(e, is_loading_indicator, toggleLoadingIndicator)} value='Login' />                    
                    {!this.state.fields['verify_sent'] && 
                        <React.Fragment>
                            <input className="pleft" type="submit" onClick={e => this.checkSubmitResetPassword(e, is_loading_indicator, toggleLoadingIndicator)} value="Forgot Password" />
                            <p><small><strong>Forgot password?</strong><br />Type your username or email above first, and then click Forgot Password button.<br />A reset password email will be sent to email we have on record, that matches.</small></p>
                        </React.Fragment>
                    }
                    
                </form>                                
            </div>
        </div>

        );
    }
}


UserLogin.propTypes = {
    setUserAction: PropTypes.func,
    id: PropTypes.string,
    username: PropTypes.string,
    email: PropTypes.string,
    newsletter: PropTypes.number,
    subscription: PropTypes.number,
    subscription_plan_id: PropTypes.string,
    expiry_date: PropTypes.string,
    fields: PropTypes.object,
    messages: PropTypes.object,
    toggleSubscription: PropTypes.func,
    is_subscription: PropTypes.bool,
    toggleLoadingIndicator: PropTypes.func,
    is_loading_indicator: PropTypes.bool,
    setCustom1: PropTypes.func,
};
UserLogin.defaultProps = {
    setUserAction: () => {},
    id: null,
    username: null,
    email: null,
    newsletter: null,
    subscription: -1,
    subscription_plan_id: '',
    expiry_date: null,
    fields: { id: null, username: null, email: null, newsletter: null, subscription: null, subscription_plan_id: '', expiry_date: null },
    messages: { loginNo: '', loginYes: '' },
    toggleSubscription: () => {},
    is_subscription: false,
    toggleLoadingIndicator: () => {},
    is_loading_indicator: false,
    setCustom1: () => {},
}
export const mapStateToProps = state => ({
    id: state.user.id,
    username: state.user.username,
    email: state.user.email,
    newsletter: state.user.newsletter,
    subscription: state.user.subscription,
    subscription_plan_id: state.user.subscription_plan_id,
    expiry_date: state.user.expiry_date,
    fields: state.fields,
    messages: state.messages,
    is_subscription: state.app.is_subscription,
    is_loading_indicator: state.app.is_loading_indicator,
    playlist: state.custom1.playlist,
});
export const mapDispatchToProps = dispatch => ({
    setUser: (id, username, email, newsletter, subscription, subscription_plan_id, expiry_date) => dispatch(setUser(id, username, email, newsletter, subscription, subscription_plan_id, expiry_date)),
    toggleSubscription: value => dispatch(toggleSubscription(value)),
    toggleLoadingIndicator: value => dispatch(toggleLoadingIndicator(value)),
    setCustom1: (playlist) => dispatch(setCustom1(playlist)),
});
// export default UserLogin;
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserLogin));