//copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleSubscription } from '../../redux/actions/appActions';
import { handleGetPaypalSubscriptionStatus } from '../Helpers';

import store from '../../redux/store';
// import { epochTime } from '../Helpers';
import { curDate } from '../Helpers';
import { setUser } from '../../redux/actions/userActions';
import { setCustom1 } from '../../redux/actions/customActions';
// import { loadReCaptcha } from 'react-recaptcha-v3';
// import { ReCaptcha } from 'react-recaptcha-v3';
import './index.css';
import LoadingIndicator from '../Widgets/LoadingIndicator';
import { toggleLoadingIndicator } from '../../redux/actions/appActions';
import Routes from '../../containers/AppRouter/routes';
import { withRouter } from 'react-router';
export class UserPasswordVerify extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: { username_email: '', password: '', verify_code: '', verify_sent: false, password_reset: '', confirm_password_reset: '' },
            messages: {},
        };
    }

    

    handleSubmitVerifyCode() {
        let messages = {};
        let fields = {};
        var fData = new URLSearchParams();
        const state = store.getState();
        console.log(state)
        fData.append('username_email', state.user.username);
        fData.append('password_reset', this.state.fields['password_reset']);
        fData.append('verify_code', this.state.fields['verify_code']);
        // console.log('VERIFY CODE SUBMIT: : : ')
        // console.log(this.state.fields);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        axios({
                // method: 'get',
                method: 'post',
                //url: 'http://127.0.0.1:8080/api_v1-008/user_reset_password_verify.php', // for testing usage switchonoff
                url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_reset_password_verify.php', // for production usage switchonoff 
                data: fData,
                config
            })
            .then((response) => {
                //handle success

                if (response.status === 200) {
                    // setUser(this.id, this.username, this.email, this.newsletter, this.subscription, this.subscription_plan_id);
                    console.log(response);
                } else if (response.data.match(/code\s*expired|code not found/i)) {
                    fields['verify_sent'] = false;
                    messages['status'] = response.data;
                }
                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props
                toggleLoadingIndicator(!is_loading_indicator);
                this.setState({
                    fields: { username_email: '', password: '', verify_code: '', verify_sent: false, password_reset: '', confirm_password_reset: '' },
                    messages: { status: 'Verified - reset password successful! Please login again with username/email and new password.' },
                })
                const {
                    history
                } = this.props;

                history.push(Routes.userprofile);
            })
            .catch((error) => {
                //handle error
                console.log(error)
                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props
                toggleLoadingIndicator(!is_loading_indicator);
                // console.log(error);
            });
    }


    handleVerifyResend(e, is_loading_indicator, toggleLoadingIndicator) {
        e.preventDefault();
        toggleLoadingIndicator(!is_loading_indicator);
        this.handleSubmitVerifyResend();
    }

    handleResetPasswordVerifyCode(e, is_loading_indicator, toggleLoadingIndicator) {
        e.preventDefault();
        if (this.handleValidationResetPasswordVerifyCode()) {
            toggleLoadingIndicator(!is_loading_indicator);
            this.handleSubmitVerifyCode();
        }
    }


    handleValidationResetPassword() {
        let fields = this.state.fields;
        let messages = {};
        // console.log(this.state);
        let formIsValid = true;
        //Password
        if (!fields['username_email']) {
            formIsValid = false;
            messages['username_email'] = 'Cannot be empty';
        }

        this.setState({ messages: messages });
        // console.log(formIsValid);
        return formIsValid;
    }



    handleValidationResetPasswordVerifyCode() {
        let fields = this.state.fields;
        let messages = {};
        let formIsValid = true;
        // console.log('FIELDS: : : ')
        // console.log(fields);
        //Password
        if (!fields["password_reset"]) {
            formIsValid = false;
            messages["password_reset"] = "Cannot be empty";
        }

        if (typeof fields["password_reset"] !== "undefined") {
            if (!fields["password_reset"].length >= 8) {
                formIsValid = false;
                messages["password_reset"] = "Minimum length of password must be 8.";
            }
            if (!fields["password_reset"].match(/[a-zA-Z]/) || !fields["password_reset"].match(/[0-9]/) || !fields["password_reset"].match(/_|-|!|\*|\./)) {
                formIsValid = false;
                messages["password_reset"] = "Must include letters, number(s), and at least one symbol: '!', '_', '-', '*'";
            }
            //check for 
        }

        if (!fields["confirm_password_reset"]) {
            formIsValid = false;
            messages["confirm_password_reset"] = "Cannot be empty";
        } else if (typeof fields["confirm_password_reset"] !== "undefined") {
            if (fields["password_reset"] !== fields["confirm_password_reset"]) {
                formIsValid = false;
                messages["confirm_password_reset"] = "Password confirmation does not match";
            }
        }

        this.setState({ messages: messages });
        return formIsValid;
    }

    

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        fields['username_email'] = fields['username_email'];
        // console.log(field)
        this.setState({ fields });
        // console.log(this.state.fields);
    }



    render() {
        // const { setUserAction } = this.props;
        // const { id, username, email, newsletter, subscription, fields, messages } = this.props;        
        const {
            toggleSubscription,
            is_subscription,
            toggleLoadingIndicator,
            is_loading_indicator,
        } = this.props;

        return (
            <div>
            <div className='user-login-container'>
                <h1>Verify Email</h1>
                <p><small><strong>Email verification sent</strong>, please allow a few minutes. Check your inbox or spam box for the reset password email.</small></p>
                <p><small>Enter your new password below, confirm your password, and enter verification code from email to update password.</small></p>
                <form>
                    <label><strong>New Password</strong></label><br/>
                    <label><small>Minimum length of 8, must include letters, and one number, and acceptable symbol: ! _ - . *</small></label><br/>
                    <label><input type="password" name="password_reset" value={this.state.fields["password_reset"]} onChange={this.handleChange.bind(this, "password_reset")}/></label><br/>
                    <label><strong>Confirm Password</strong></label><br/>
                    <input type="password" name="confirm_password_reset" value={this.state.fields["confirm_password_reset"]} onChange={this.handleChange.bind(this, "confirm_password_reset")}/><br/>
                    <label><strong>Verify Code</strong></label><br/>
                    <input type='text' name='verify_code' value={this.state.fields['verify_code']} onChange={this.handleChange.bind(this, 'verify_code')}/><br/>
                    <p style={{minHeight: '22px'}}><span style={{color: "red"}}>{this.state.messages["password_reset"]}</span> <span style={{color: "red"}}>{this.state.messages["confirm_password_reset"]}</span></p>
                    {this.state.fields['verify_code'].length === 0 ? <p><input type="submit" className="disabled" value="Verify Code" disabled /></p> : <p><input type="submit" onClick={e => this.handleResetPasswordVerifyCode(e, is_loading_indicator, toggleLoadingIndicator)} value="Verify Code" /></p>}
                </form>
            </div>
        </div>

        );
    }
}


UserPasswordVerify.propTypes = {
    setUserAction: PropTypes.func,
    id: PropTypes.string,
    username: PropTypes.string,
    email: PropTypes.string,
    newsletter: PropTypes.number,
    subscription: PropTypes.number,
    subscription_plan_id: PropTypes.string,
    expiry_date: PropTypes.string,
    fields: PropTypes.object,
    messages: PropTypes.object,
    toggleSubscription: PropTypes.func,
    is_subscription: PropTypes.bool,
    toggleLoadingIndicator: PropTypes.func,
    is_loading_indicator: PropTypes.bool,
};
UserPasswordVerify.defaultProps = {
    setUserAction: () => {},
    id: null,
    username: null,
    email: null,
    newsletter: null,
    subscription: -1,
    subscription_plan_id: '',
    expiry_date: null,
    fields: { id: null, username: null, email: null, newsletter: null, subscription: null, subscription_plan_id: '', expiry_date: null },
    messages: { loginNo: '', loginYes: '' },
    toggleSubscription: () => {},
    is_subscription: false,
    toggleLoadingIndicator: () => {},
    is_loading_indicator: false,
}
export const mapStateToProps = state => ({
    id: state.user.id,
    username: state.user.username,
    email: state.user.email,
    newsletter: state.user.newsletter,
    subscription: state.user.subscription,
    subscription_plan_id: state.user.subscription_plan_id,
    expiry_date: state.user.expiry_date,
    fields: state.fields,
    messages: state.messages,
    is_subscription: state.app.is_subscription,
    is_loading_indicator: state.app.is_loading_indicator,
    playlist: state.custom1.playlist,
});
export const mapDispatchToProps = dispatch => ({
    setUser: (id, username, email, newsletter, subscription, subscription_plan_id, expiry_date) => dispatch(setUser(id, username, email, newsletter, subscription, subscription_plan_id, expiry_date)),
    toggleSubscription: value => dispatch(toggleSubscription(value)),
    toggleLoadingIndicator: value => dispatch(toggleLoadingIndicator(value)),
});
// export default UserPasswordVerify;
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserPasswordVerify));