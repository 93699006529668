//copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import store from '../../redux/store';
import './index.css';
import PayPalBtnSub1 from '../PayPalBtnSub1';
import { toggleSubscription } from '../../redux/actions/appActions';
import LoadingIndicator from '../Widgets/LoadingIndicator';
import { toggleLoadingIndicator } from '../../redux/actions/appActions';
import { logout, curDate } from '../Helpers';
import { setUser } from '../../redux/actions/userActions';



export class UserSubscription extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            messages: {},
        };
    }

    //START CANCEL SUBSCRIPTION
    //START CANCEL SUBSCRIPTION
    //START CANCEL SUBSCRIPTION
    //START CANCEL SUBSCRIPTION

    handleUserSubscriptionCancelGetToken() {

        //post to login api to password verify and jwt response
        axios({
                //url: 'https://api-m.sandbox.paypal.com/v1/oauth2/token', // for testing usage switchonoff
                url: 'https://api-m.paypal.com/v1/oauth2/token', // for production usage switchonoff
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Accept-Language': 'en_US',
                    'content-type': 'application/x-www-form-urlencoded',
                },
                auth: {
                    //username: 'AaA91dknmZx584p8LgHKg-Sx7sqaxC4j7grUF38i4IusoQdUh6H5ia53xURdeLS9P0-YDYMCx8iDvqtb', // for testing usage switchonoff
                    username: 'AQfmJ5bDKbqqFziW2BrIKTGX6dDR3V_8c09cwXWg4U-GA3w-Eo91VRqrUiwcDpHc__fp6hRh2j3y3PRV', // for production usage switchonoff
                    //password: 'EACQiCE_wW7O--lrsBWzba4r_jJlPiuLlpIh-47qFdMhyfTHUXXpK0gtbVctQ761nIFtwuTcysovnsYK', // for testing usage switchonoff
                    password: 'EBewyCbtVz0m5Vz8t9vgJsW1TJrexOAgcyyCZHf6BMTe_2gX4o3CG7AFAXbhcRlyfThSKhggmSJxhmKS' // for production usage switchonoff
                },
                params: { 'grant_type': 'client_credentials' }
            })
            .then((response) => {
                //handle success
                //you are only getting the token to cancel a subscription at this point so you get the token 
                if (response.data.access_token) {
                    // console.log('PAYPAL connect...');
                    localStorage.setItem('paypal_token', response.data.access_token)
                    let state = store.getState();

                    //let api_url = 'https://api-m.sandbox.paypal.com/v1/billing/subscriptions/' + this.subscription_plan_id + '/cancel'; // for testing usage switchonoff
                    let api_url = 'https://api-m.paypal.com/v1/billing/subscriptions/' + this.subscription_plan_id + '/cancel'; // for production usage switchonoff

                    //https://developer.paypal.com/docs/api/subscriptions/v1/#subscriptions_cancel
                    let config = {};
                    config = {
                        url: api_url,
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('paypal_token') // for now you have to manually get the token, but todo: get token executre post/get on promise fulfilled from token, then revoke token
                        },
                        'data': {
                            'reason': 'Wanted to cancel.'
                        }
                    }
                    let msg = '...PAYPAL API response: Cancel User Subscription ' + state.user.username + '...';
                    this.handlePaypalCancelAPI(config, msg);

                } else if (response.data === 1) {

                } else {
                    // console.log(response.status);
                }
            })
            .catch((error) => {
                //handle error

                // console.log(error);
            });
    }





    handlePaypalCancelAPI(config, msg) {

        //post to login api to password verify and jwt response
        axios(config).then((response) => {
            //handle success
            const state = store.getState();
            if (response) {}
            if (response.status === 204) {
                console.log('Cancelled subscription 204 status...');
                // console.log(response.data) // for console usage switchonoff
                // console.log(state.user) // for console usage switchonoff
                this.updateDBUserSubscriptionCancel(state.user.id);
                // localStorage.removeItem('subscription');
                // localStorage.removeItem('paypal_token');
            }
        }).catch((error) => {
            //handle error
            // console.log(error);
        });
    }

    updateDBUserSubscriptionCancel = (id) => {

        var fData = new URLSearchParams();
        fData.append('id', id);
        const state = store.getState();
        //TODO: SETUP THIS EXPIRY CHECK IN A FUNCTION its used in multiple places
        if (curDate() < state.user.expiry_date) {
            fData.append('subscription_plan_id', 'REGISTERED');
            fData.append('subscription', 20); // 20 is registered free limited
        } else {
            fData.append('subscription_plan_id', 'EXPIRED');
            fData.append('subscription', 10); // 10 expiry date passed, expired trial/or cancelled subscription and outside trial expiry date
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        axios({
                method: 'post',
                //url: 'http://127.0.0.1:8080/api_v1-008/user_subscription_update_status.php', // for testing usage switchonoff
                url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_subscription_update_status.php', // for production usage switchonoff
                data: fData,
                config
            })
            .then((response) => {
                //handle success



                //jwt set to local storage
                if (response.status === 200 && response.data.subscription && response.data.subscription_plan_id) {
                    let state = store.getState();
                    //reset the props to dispatch for setUserAction again after axios promise fulfilled                    
                    const { setUserAction, toggleSubscription } = this.props;
                    setUserAction(state.user.id, state.user.username, state.user.email, state.user.newsletter, parseInt(response.data.subscription), response.data.subscription_plan_id, response.data.expiry_date);
                    toggleSubscription(false);

                    let messages = {}
                    this.setState({ messages: messages });
                    localStorage.removeItem('paypal_token');
                }
                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props
                toggleLoadingIndicator(!is_loading_indicator);
            })
            .catch((error) => {
                //handle error
                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props
                toggleLoadingIndicator(!is_loading_indicator);
                // console.log(error);
            });
    }

    //END CANCEL SUBSCRIPTION
    //END CANCEL SUBSCRIPTION
    //END CANCEL SUBSCRIPTION
    //END CANCEL SUBSCRIPTION

    handleUserSubscriptionNew = (subscription_plan_id, id) => {
        // console.log('Paypal handle subscription updates...');
        // // console.log('SPID- ' + subscription_plan_id) ; // for console usage switchonoff
        // // console.log('CUID- ' + id) ; // for console usage switchonoff
        var fData = new URLSearchParams();
        fData.append('subscription_plan_id', subscription_plan_id);
        fData.append('id', id);
        // for now just set to tier 30 because there is no other options... quick and dirty, but todo add more options
        fData.append('subscription', 30); //30 is registered paying subscriber on activation
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        axios({
                method: 'post',
                //url: 'http://127.0.0.1:8080/api_v1-008/user_subscription_update_status.php', // for testing usage switchonoff
                url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_subscription_update_status.php', // for production usage switchonoff
                data: fData,
                config
            })
            .then((response) => {

                // console.log('handleUserSubscription DB updated... setting state...');
                // console.log(response.data); // for testint switchonoff
                //jwt set to local storage
                if (response.status === 200 && response.data.subscription && response.data.subscription_plan_id) {

                    let state = store.getState();
                    // console.log(JSON.stringify(state)); // for console usage switchonoff
                    const { setUserAction, toggleSubscription } = this.props;
                    setUserAction(state.user.id, state.user.username, state.user.email, state.user.newsletter, parseInt(response.data.subscription), response.data.subscription_plan_id);
                    toggleSubscription(true);
                    let messages = {}
                    this.setState({ messages: messages });
                    // localStorage.removeItem('paypal_token');

                }
                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props
                toggleLoadingIndicator(!is_loading_indicator);
            })
            .catch((error) => {
                //handle error
                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props
                toggleLoadingIndicator(!is_loading_indicator);
                // console.log(error);
            });
    }


    //END SUBSCRIPTION ADD NEW
    //END SUBSCRIPTION ADD NEW
    //END SUBSCRIPTION ADD NEW
    //END SUBSCRIPTION ADD NEW



    bseDeleteAccount = (id) => {
        // console.log('Paypal handle subscription updates...');
        // // console.log('SPID- ' + subscription_plan_id) ; // for console usage switchonoff
        // console.log('CUID- ' + id) ; // for console usage switchonoff
        var fData = new URLSearchParams();
        fData.append('id', id);

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        axios({
                method: 'post',
                //url: 'http://127.0.0.1:8080/api_v1-008/user_delete_account.php', // for testing usage switchonoff
                url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_delete_account.php', // for production usage switchonoff
                data: fData,
                config
            })
            .then((response) => {

                // console.log('handleUserSubscription DB updated... setting state...');
                // console.log(response); // for testing switchonoff
                //jwt set to local storage
                if (response.status === 200) {
                    const {
                        setUser,

                    } = this.props;
                    logout(setUser);
                }
                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props
                toggleLoadingIndicator(!is_loading_indicator);
            })
            .catch((error) => {
                //handle error
                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props
                toggleLoadingIndicator(!is_loading_indicator);
                console.log(error);
            });
    }


    //END DELETE ACCOUNT
    //END DELETE ACCOUNT

    checkSubmit(e, field, is_loading_indicator, toggleLoadingIndicator) {

        e.preventDefault();
        let config = {};
        // console.log(localStorage.getItem("paypal_token"));
        //TODO this needs to be updated to either have or remove each instance of paypal token... for now we remove it to force the re-token creation
        if (field.match(/paypal-subscription-cancel/i)) {
            // if (localStorage.getItem("paypal_token") === null && field.match(/paypal-subscription-cancel/i)) {
            //... 
            const {
                toggleLoadingIndicator,
                is_loading_indicator,
            } = this.props
            toggleLoadingIndicator(!is_loading_indicator);
            this.handleUserSubscriptionCancelGetToken();
        }
        if (field.match(/bse-delete-account/i)) {
            // if (localStorage.getItem("paypal_token") === null && field.match(/paypal-subscription-cancel/i)) {
            //... 
            const {
                toggleLoadingIndicator,
                is_loading_indicator,
            } = this.props
            toggleLoadingIndicator(!is_loading_indicator);
            let state = store.getState();
            this.bseDeleteAccount(state.user.id);
        }
    }


    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        // console.log('handleChange: ' + fields[field]);
        this.setState({ fields });
    }

    componentDidMount() {
        // const script1 = document.createElement("script");
        // script1.src = "https://www.paypal.com/sdk/js?client-id=yourpaypalsandboxorproductionclientidstring&vault=true&intent=subscription";
        // script1.async = false;
        // document.body.appendChild(script1);
        // const script2 = document.createElement("script");
        // script2.innerHTML = "paypal.Buttons().render('#paypal-button-container')";
        // script2.async = true;
        // document.body.appendChild(script2);
    }

    render() {

        const {
            setUser,
            toggleSubscription,
            is_subscription,
            toggleLoadingIndicator,
            is_loading_indicator,
        } = this.props;



        //PAYPAL this sets the plan_id that is used for this action.
        //PAYPAL the plan_id is what goes with the paypal subcription details.
        //PAYPAL todo: get all the paypal information and create a component to organize all the plans, products, services, payment gateways
        const paypalSubscribe = (data, actions) => {
            toggleLoadingIndicator(!is_loading_indicator);
            return actions.subscription.create({
                //'plan_id': "P-17854635NA814410DMCC6E5I", // for testing usage switchonoff 
                'plan_id': "P-2GL977048S7939718MZCNWFY", // for production usage switchonoff                
                ////////////'plan_id': "P-95690099NV543761UMCM7CBI", // for archive usage switchoff this is Tier 1 Basic $2
            });
        }

        const paypalOnError = (err) => {
            console.log("PaypalSubscribe Error: " + err);
            const {
                toggleLoadingIndicator
            } = this.props;
            toggleLoadingIndicator(!is_loading_indicator);
            //TODO NEED TO HAVE USER ERROR CHECKS TO SHOW
        }

        const paypalOnApprove = (data, detail) => {
            // call the backend api to store transaction details
            let state = store.getState();
            console.log("Paypal approved");
            // console.log(JSON.stringify(data)); // for console usage switchonoff
            // console.log(JSON.stringify(detail)); // for console usage switchonoff
            // console.log(JSON.stringify(state)); // for console usage switchonoff
            this.handleUserSubscriptionNew(data.subscriptionID, state.user.id);
        };

        const state = store.getState();
        //console.log(state);
        this.id = state.user.id;
        this.newsletter = state.user.newsletter;
        this.subscription = state.user.subscription;
        this.subscription_plan_id = state.user.subscription_plan_id;

        return (
            <React.Fragment>
            <div className='guest'>
                <h2>Subscriptions</h2>      
                <div className="tier_outline_outer">
                    <div className="tier_outline_inner tier_purchase">
                        <h3>Subscription Details</h3>
                        <hr />                  
                        {this.subscription < 30 ?  // 30 if not paying then show subscribe button
                            <div>
                                <p><strong>Cost</strong></p>
                                <p>$3.99 billed monthly.</p>
                                <p>This will grant access to most of BSE library media, and will also add user playlist feature.</p> 
                                <PayPalBtnSub1
                                    amount = "3.99"
                                    currency = "USD"
                                    createSubscription={paypalSubscribe}
                                    onApprove={paypalOnApprove}
                                    catchError={paypalOnError}
                                    onError={paypalOnError}
                                    onCancel={paypalOnError}
                                />
                            </div>
                        :
                            <div>
                                {!is_loading_indicator ? 
                                    <div className="subscription-details">
                                        <p><strong>Cost</strong></p>
                                        <p>$3.99 billed monthly.</p>
                                        <p>This will grant access to most of BSE library media, and will also add user playlist feature.</p> 
                                        <input type='button' onClick={e => this.checkSubmit(e, 'paypal-subscription-cancel', is_loading_indicator, toggleLoadingIndicator)} value='Cancel Subscription' />
                                    </div>
                                :
                                    <div className="subscription-details">                                        
                                        <div className="subscription-cancel-spinner">
                                            <LoadingIndicator />
                                        </div>
                                        <p>Cancelling BSE Paypal subscription.</p>
                                        <p>Please wait... </p>
                                        <p>Sorry to see you go.  You can still individually license titles, and subscribe again anytime!</p>
                                    </div>
                                }
                            </div>                            
                        }

                        {this.subscription >= 30 ?  // 30 + subscribers get more info, less gets delete account info.
                             <div>
                                <hr />
                                <p><strong>NOTE ACTIVE SUBSCRIPTION USERS</strong></p>
                                <p>If you are deleting your account from the application, and have an active subscription, please cancel your subscription first.</p>
                                <p>If you delete your account first before cancelling your subscription, you will have to login to your Paypal account and cancel the subscription.</p>
                                <p>Use the Delete Account button below to remove all your login and account data, as well as any newsletter email lists.</p>
                                <p>Once you click on Delete Account, you will be logged out, and there is no restoring your previous account and will have to sign up again.</p>
                                {<input type='button' onClick={e => this.checkSubmit(e, 'bse-delete-account', is_loading_indicator, toggleLoadingIndicator)} value='Delete Account' /> }
                            </div>
                            :
                            <div className="tier_purchase">
                                <hr />
                                <p>Use the Delete Account button below to remove all your login and account data, as well as any newsletter email lists.</p>
                                <p>Once you click on Delete Account button, you will be logged out, and there is no restoring your previous account and will have to sign up again.</p>
                                {<input type='button' onClick={e => this.checkSubmit(e, 'bse-delete-account', is_loading_indicator, toggleLoadingIndicator)} value='Delete Account' /> }
                            </div>  
                        }
                    </div>
                </div>
                <p><strong>Notes on Subscriptions</strong></p>
                <p><small>Big Stream does not store any financial information on the servers they use.  Payment and processing information is handled by Paypal Inc. and uses their CPI compliant payment processing services to facilitate payments for BSE subscriptions and purchases.</small></p>
                <p><small>Initiation of new subscriptions will be charged as soon as you complete the checkout process and subscription becomes activated.  You may cancel at anytime, but we do not at this time provide any refunds for newly activated subscriptions.</small></p>
                <p><small>You may cancel at any time, there are no full or partial refunds on any subscriptions.</small></p>
            </div>
        </React.Fragment>
        );


    }
}


UserSubscription.propTypes = {
    setUserAction: PropTypes.func,
    id: PropTypes.string,
    username: PropTypes.string,
    email: PropTypes.string,
    newsletter: PropTypes.number,
    subscription: PropTypes.number,
    subscription_plan_id: PropTypes.string,
    expiry_date: PropTypes.string,
    toggleSubscription: PropTypes.func,
    is_subscription: PropTypes.bool,
    toggleLoadingIndicator: PropTypes.func,
    is_loading_indicator: PropTypes.bool,
};

UserSubscription.defaultProps = {
    setUserAction: () => {},
    id: null,
    username: null,
    email: null,
    newsletter: null,
    subscription: -1,
    subscription_plan_id: null,
    expiry_date: null,
    toggleSubscription: () => {},
    is_subscription: false,
    toggleLoadingIndicator: () => {},
    is_loading_indicator: false,
}


export const mapStateToProps = state => ({
    id: state.user.id,
    username: state.user.username,
    email: state.user.email,
    newsletter: state.user.newsletter,
    subscription: state.user.subscription,
    subscription_plan_id: state.user.subscription_plan_id,
    expiry_date: state.user.expiry_date,
    is_subscription: state.app.is_subscription,
    is_loading_indicator: state.app.is_loading_indicator,
});


export const mapDispatchToProps = dispatch => ({
    setUser: (id, username, email, newsletter, subscription, subscription_plan_id, expiry_date) => dispatch(setUser(id, username, email, newsletter, subscription, subscription_plan_id, expiry_date)),
    toggleSubscription: value => dispatch(toggleSubscription(value)),
    toggleLoadingIndicator: value => dispatch(toggleLoadingIndicator(value)),
});
// export default UserSubscription;
export default connect(mapStateToProps, mapDispatchToProps)(UserSubscription);