//copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Routes from '../../containers/AppRouter/routes';
import FadeIn from 'react-fade-in';
import arrowLeft from '../../images/caret-left-regular.png';
import arrowRight from '../../images/caret-right-regular.png';
import './index.css';

// import { connect } from 'react-redux';
// import store from '../../redux/store';
// import LoadingModule from '../Widgets/LoadingIndicator';
// import { toggleLoadingModule } from '../../redux/actions/appActions';

export class HorizontalScrollBar extends Component {

    constructor(props) {
        super(props);
        this.handleShowLeft = this.handleShowLeft.bind(this);
        this.handleShowRight = this.handleShowRight.bind(this);
        this.scrollEl = React.createRef();
        this.state = {
            scrollArrowRight: false,
            scrollArrowLeft: false
        };
    }

    handleShowLeft() {
        var scrOffsetWidth = this.scrollEl.current.offsetWidth;
        // console.log('scrOffsetWidth:   ' + scrOffsetWidth);
        var scrOffsetLeft = this.scrollEl.current.scrollLeft;
        // console.log('scrOffsetLeft:   ' + scrOffsetLeft);
        var scrOffsetMod = 0;

        if (scrOffsetLeft > 0) {
            if (scrOffsetLeft > 0 && scrOffsetLeft <= scrOffsetWidth) {
                this.scrollEl.current.scrollLeft = 0;
                this.state.scrollArrowLeft = false;
            } else if (scrOffsetLeft > 0 && scrOffsetLeft >= scrOffsetWidth) {
                // scrOffsetMod = scrOffsetLeft % scrOffsetWidth;
                // console.log('scrOffsetMod:  ' + scrOffsetMod);
                // if (Math.floor(scrOffsetMod) <= 0) {
                //     scrOffsetMod = scrOffsetWidth;
                // }
                // this.scrollEl.current.scrollLeft = this.scrollEl.current.scrollLeft - scrOffsetMod;
                this.scrollEl.current.scrollLeft = this.scrollEl.current.scrollLeft - scrOffsetWidth;
                this.state.scrollArrowLeft = true;
            }
            // console.log('SCur: ' + this.state.scrollArrowLeft);
        }

    }

    handleShowRight() {
        var scrOffsetWidth = this.scrollEl.current.offsetWidth;
        var scrOffsetLeft = this.scrollEl.current.scrollLeft;
        var scrOffsetMod = 0;
        scrOffsetMod = scrOffsetWidth - scrOffsetMod;

        this.scrollEl.current.scrollLeft = this.scrollEl.current.scrollLeft + scrOffsetWidth;

    }

    componentDidMount() {
        const windowSize = [window.innerWidth, window.innerHeight];
        const rowChildren = this.scrollEl.current.children.length;
        if ((windowSize[0] > 0 && windowSize[0] <= 579) && rowChildren > 2) {
            this.setState({ scrollArrowRight: true });
        } else if ((windowSize[0] > 580 && windowSize[0] <= 1154) && rowChildren > 3) {
            this.setState({ scrollArrowRight: true });
        } else if ((windowSize[0] > 1155 && windowSize[0] <= 1519) && rowChildren > 4) {
            this.setState({ scrollArrowRight: true });
        } else if ((windowSize[0] > 1520) && rowChildren > 5) {
            this.setState({ scrollArrowRight: true });
        }

        // const { toggleLoadingModule, is_loading_module } = this.props;
        // toggleLoadingModule(!is_loading_module);     
    }

    // componentWillMount() {
    //     // console.log(this.props.title)   
    //     const { toggleLoadingModule, is_loading_module } = this.props;
    //     toggleLoadingModule(!is_loading_module);     
    // }

   

    render() {
        // const { title, clickAction, resources, history, toggleLoadingModule, is_loading_module } = this.props;
        // console.log(is_loading_module);
        const { title, clickAction, resources, history } = this.props;



        const onScroll = (e) => {
            // console.log('scr left: ' + e.target.scrollLeft);
            // console.log('offset width: ' + e.target.offsetWidth);
            if (e.target.scrollLeft === 0) {
                this.setState({ scrollArrowLeft: false })
            } else {

                this.setState({ scrollLast: e.target.scrollLeft });
                this.setState({ scrollArrowLeft: true })
            }

            var scrWidthDiff = e.target.scrollWidth - e.target.offsetWidth;
            // console.log('scr right: ' + e.target.scrollRight);
            // console.log('scr width: ' + e.target.scrollWidth);
            // console.log('scr width diff: ' + scrWidthDiff);
            if (e.target.scrollLeft >= (scrWidthDiff - 1)) {
                this.setState({ scrollArrowRight: false })
            } else {
                this.setState({ scrollArrowRight: true })
            }

            // console.log('e.tar.ScrollWidth: ' + scrWidthDiff + ' e.tar.ScrollLast: ' + this.state.scrollLast + ' e.tar.ScrollLeftPos:' + e.target.scrollLeft);

        };
        // console.log('res', resources);
        return (
            <React.Fragment>
            
                <div className="show-items-wrapper">
                    <h2>{title}</h2>
                    {/*{console.log(history.location.pathname)}*/}
                    <section onScroll={onScroll} ref={this.scrollEl} id={title} className={history.location.pathname == "/" ? "tile-row-standard" : "tile-row-category"}>
                    {/*{!is_loading_module ? <div className="loader-placeholder"><LoadingModule /></div> :*/}
                    {
                      //cycle through each category playlist json array, and drill down into nested detail json array
                        //resources.slice(0, this.state.showItems).map((resource) => 
                        resources.map((resource) => 
                        resource.content.videos.map((video) => {
                            //these fields are mapped from the bse feed json file
                            const {
                              id, 
                              title,
                              shortDescription,
                              thumbnail,
                              episodes,                         
                            } = resource;
                            const { url, license1, license2 } = video;
                            const videoJsOptions = {
                                autoplay: false,
                                controls: true,
                                // fill: true,
                                responsive: true,
                                sources: [{
                                    src: url,
                                    type: 'video/mp4'
                                }]
                            }

                            {/*console.log(videoOptions)*/}
                            return (
                                <div key={id} className='tile-standard tile-left'>                  
                                  <div className="tile-image">
                                    <div className="tile-menu-item" to={Routes.id} onClick={() => {
                                        // console.log(clickAction(id, title, shortDescription, url, thumbnail, license1, license2, videoJsOptions));
                                      clickAction(id, title, shortDescription, url, thumbnail, episodes, license1, license2, videoJsOptions);
                                      history.push({pathname: '/' + id});
                                    }}>
                                        <FadeIn> 
                                            <div>   
                                                <img src={thumbnail} alt={title} title={title}/>
                                            </div>
                                        </FadeIn>
                                        {/*<FadeIn>
                                          <h5 className='tile-title'>{title}</h5>
                                        </FadeIn>*/}
                                    </div>
                                  </div>
                                </div>
                            );
                        }))
                                      
                  }
                    </section>
                    { 
                        history.location.pathname == "/" &&
                        <React.Fragment>
                            { this.state.scrollArrowLeft && <div className="show-items left" onClick={this.handleShowLeft} style={{ backgroundImage: `url(${arrowLeft})` }}></div> }
                            { this.state.scrollArrowRight && <div className="show-items right" onClick={this.handleShowRight} style={{ backgroundImage: `url(${arrowRight})` }}></div> }
                        </React.Fragment>
                    }
                </div>
            
            </React.Fragment>
        );
    };
};

HorizontalScrollBar.propTypes = {
    title: PropTypes.string.isRequired,
    resources: PropTypes.arrayOf(PropTypes.object).isRequired,
    clickAction: PropTypes.func,
    // toggleLoadingModule: PropTypes.func,
    // is_loading_module: PropTypes.bool,
};

HorizontalScrollBar.defaultProps = {
    clickAction: () => {},
    // toggleLoadingModule: () => {},
    // is_loading_module: false,
}
// export const mapStateToProps = state => ({
//     is_loading_module: state.app.is_loading_module,
// });
// export const mapDispatchToProps = dispatch => ({
//     toggleLoadingModule: value => dispatch(toggleLoadingModule(value)),
// });

export default HorizontalScrollBar;

// export default connect(mapStateToProps, mapDispatchToProps)(HorizontalScrollBar);