//copyright: Big Screen Entertainment Group
export default {
    home: '/',
    about: '/about',
    aboutbseg: '/about_big_screen_entertainment_group',
    privacy: '/privacy',
    tos: '/tos',
    media: '/media',
    category: '/category',
    featured: '/featured',
    userplaylist: '/userplaylist',
    recently_added: '/recently added',
    hidden_chambers: '/hidden chambers',
    documentary: '/documentary',
    comedy: '/comedy',
    drama: '/drama',
    adventure: '/adventure',
    userprofile: '/userprofile',
    userregister: '/userregister',
    userpasswordverify: '/userpasswordverify',
    userregisterverify: '/userregisterverify',
    mku_bseg_doc_2023_techtothefuture: '/mku_bseg_doc_2023_techtothefuture',
    mku_bseg_fl_2019_hiddenchambers: '/mku_bseg_fl_2019_hiddenchambers',
    mku_bseg_fl_1999_felons: '/mku_bseg_fl_1999_felons',
    mku_allied_sf_1923_aliceswonderland: '/mku_allied_sf_1923_aliceswonderland',
    mku_allied_fl_1952_jackandthebeanstalk: '/mku_allied_fl_1952_jackandthebeanstalk',
    mku_allied_sf_1941_supermanmadscientist: '/mku_allied_sf_1941_supermanmadscientist',
    mku_allied_sf_1942_supermanbilliondollarlimited: '/mku_allied_sf_1942_supermanbilliondollarlimited',
    mku_allied_sf_1936_christmascomesbutonceayear: '/mku_allied_sf_1936_christmascomesbutonceayear',
    mku_allied_tv_1941_thewoodywoodpeckershowpantrypan: '/mku_allied_tv_1941_thewoodywoodpeckershowpantrypan',
    mku_allied_fl_1972_theadventuresofpinocchio: '/mku_allied_fl_1972_theadventuresofpinocchio',
    mku_allied_tv_1944_thewoodywoodpeckershowthebarber: '/mku_allied_tv_1944_thewoodywoodpeckershowthebarber',
    mku_allied_doc_1975_whosoutthere: '/mku_allied_doc_1975_whosoutthere',
    mku_bseg_sf_2019_ihaveadatetonight: '/mku_bseg_sf_2019_ihaveadatetonight',
    mku_bseg_sf_2020_cabinstay: '/mku_bseg_sf_2020_cabinstay',
    mku_bseg_ws_2018_hollywoodbehindthescenes: '/mku_bseg_ws_2018_hollywoodbehindthescenes',
    mku_allied_doc_1941_womenindefense: '/mku_allied_doc_1941_womenindefense',
    mku_allied_doc_1942_thebattleofmidway: '/mku_allied_doc_1942_thebattleofmidway',
    mku_allied_doc_1942_winningyourwings: '/mku_allied_doc_1942_winningyourwings',
    mku_allied_doc_1943_treeinatesttube: '/mku_allied_doc_1943_treeinatesttube',
    mku_allied_doc_1964_ninefromlittlerock: '/mku_allied_doc_1964_ninefromlittlerock',
    mku_allied_doc_1983_ronaldreaganevilempirespeech: '/mku_allied_doc_1983_ronaldreaganevilempirespeech',
    mku_allied_doc_1984_ronaldreagangeneralassemblyoft: '/mku_allied_doc_1984_ronaldreagangeneralassemblyoft',
    mku_allied_doc_1984_ronaldreaganpresidentialnomina: '/mku_allied_doc_1984_ronaldreaganpresidentialnomina',
    mku_allied_doc_1987_ronaldreagansovietussummitmeet: '/mku_allied_doc_1987_ronaldreagansovietussummitmeet',
    mku_allied_doc_1988_ronaldreaganfarewellspeechatre: '/mku_allied_doc_1988_ronaldreaganfarewellspeechatre',
    mku_allied_doc_1988_ronaldreaganwearenotfinishedye: '/mku_allied_doc_1988_ronaldreaganwearenotfinishedye',
    mku_allied_sf_1917_somebondsihaveknown: '/mku_allied_sf_1917_somebondsihaveknown',
    mku_allied_sf_1923_aliceswonderland: '/mku_allied_sf_1923_aliceswonderland',
    mku_allied_sf_1936_christmascomesbutonceayear: '/mku_allied_sf_1936_christmascomesbutonceayear',
    mku_allied_sf_1941_supermanmadscientist: '/mku_allied_sf_1941_supermanmadscientist',
    mku_allied_sf_1942_supermanbilliondollarlimited: '/mku_allied_sf_1942_supermanbilliondollarlimited',
    mku_allied_sf_1942_supermanelectricearthquake: '/mku_allied_sf_1942_supermanelectricearthquake',
    mku_allied_sf_1947_bridelessgroom: '/mku_allied_sf_1947_bridelessgroom',
    mku_allied_sf_1969_adayinthedeathofdonnyb: '/mku_allied_sf_1969_adayinthedeathofdonnyb',
    mku_allied_tr_1930_kingofjazz: '/mku_allied_tr_1930_kingofjazz',
    mku_allied_tv_1941_thewoodywoodpeckershowpantrypan: '/mku_allied_tv_1941_thewoodywoodpeckershowpantrypan',
    mku_allied_tv_1944_thewoodywoodpeckershowthebarber: '/mku_allied_tv_1944_thewoodywoodpeckershowthebarber',
    mku_bseg_ws_2019_hiddenchambers: '/mku_bseg_ws_2019_hiddenchambers',
    mku_bseg_ws_2019_hiddenchambers1: '/mku_bseg_ws_2019_hiddenchambers1',
    mku_bseg_ws_2019_hiddenchambers2: '/mku_bseg_ws_2019_hiddenchambers2',
    mku_bseg_ws_2019_hiddenchambers3: '/mku_bseg_ws_2019_hiddenchambers3',
    mku_bseg_ws_2019_hiddenchambers4: '/mku_bseg_ws_2019_hiddenchambers4',
    mku_bseg_ws_2019_hiddenchambers5: '/mku_bseg_ws_2019_hiddenchambers5',
    mku_allied_doc_1918_gasalarm: '/mku_allied_doc_1918_gasalarm',
    mku_allied_doc_1935_universalcurrenteventsvol1issu: '/mku_allied_doc_1935_universalcurrenteventsvol1issu',
    mku_allied_doc_1936_meuseargonneoffensive: '/mku_allied_doc_1936_meuseargonneoffensive',
    mku_allied_doc_1936_trainingofcoloredtroops1918: '/mku_allied_doc_1936_trainingofcoloredtroops1918',
    mku_allied_doc_1942_fellowamericans: '/mku_allied_doc_1942_fellowamericans',
    mku_allied_doc_1943_airwarineurope: '/mku_allied_doc_1943_airwarineurope',
    mku_allied_doc_1943_recognitionofthejapanesezerofi: '/mku_allied_doc_1943_recognitionofthejapanesezerofi',
    mku_allied_doc_1944_battlestations: '/mku_allied_doc_1944_battlestations',
    mku_allied_doc_1944_dday: '/mku_allied_doc_1944_dday',
    mku_allied_doc_1945_armyairforces: '/mku_allied_doc_1945_armyairforces',
    mku_allied_doc_1945_armyairforcespacific: '/mku_allied_doc_1945_armyairforcespacific',
    mku_allied_doc_1945_furyinthepacific: '/mku_allied_doc_1945_furyinthepacific',
    mku_allied_doc_1945_surrenderinthepacific: '/mku_allied_doc_1945_surrenderinthepacific',
    mku_allied_doc_1946_seedsofdestiny: '/mku_allied_doc_1946_seedsofdestiny',
    mku_allied_doc_1950_thecrimeofkorea: '/mku_allied_doc_1950_thecrimeofkorea',
    mku_allied_doc_1950_unaidsinrepublicofkorea: '/mku_allied_doc_1950_unaidsinrepublicofkorea',
    mku_allied_doc_1951_ourcitiesmustfight: '/mku_allied_doc_1951_ourcitiesmustfight',
    mku_allied_doc_1953_thekoreastory: '/mku_allied_doc_1953_thekoreastory',
    mku_allied_doc_1958_operationinchon: '/mku_allied_doc_1958_operationinchon',
    mku_allied_doc_1967_airrescueinsoutheastasia: '/mku_allied_doc_1967_airrescueinsoutheastasia',
    mku_allied_doc_1967_anotherdayofwar: '/mku_allied_doc_1967_anotherdayofwar',
    mku_allied_doc_1976_f15_alert_eagle: '/mku_allied_doc_1976_f15_alert_eagle',
    mku_allied_doc_1977_historyoftheairforce19651977: '/mku_allied_doc_1977_historyoftheairforce19651977',
    mku_allied_doc_1978_f16_bird_of_prey: '/mku_allied_doc_1978_f16_bird_of_prey',
    mku_allied_doc_1978_f16_falcon_design_and_testing: '/mku_allied_doc_1978_f16_falcon_design_and_testing',
    mku_allied_doc_1978_patriotismhaveityourway: '/mku_allied_doc_1978_patriotismhaveityourway',
    mku_allied_doc_2008_unitednationsforcescrossthe38t: '/mku_allied_doc_2008_unitednationsforcescrossthe38t',
    mku_allied_sf_1918_hisbestgift: '/mku_allied_sf_1918_hisbestgift',
    mku_allied_sf_1952_duckandcover: '/mku_allied_sf_1952_duckandcover',
    mku_bseg_tr_2006_forgetaboutit: '/mku_bseg_tr_2006_forgetaboutit',
    mku_bseg_tr_2009_babysitterwanted: '/mku_bseg_tr_2009_babysitterwanted',
    mku_bseg_tr_2011_sodiumbabies: '/mku_bseg_tr_2011_sodiumbabies',
    mku_bseg_tr_2012_youcantkillstephenking: '/mku_bseg_tr_2012_youcantkillstephenking',
    mku_bseg_tr_2013_mosquitoman: '/mku_bseg_tr_2013_mosquitoman',
    mku_bseg_tr_2013_singularityprinciple: '/mku_bseg_tr_2013_singularityprinciple',
    mku_allied_doc_2005_thebeatleslovemedo: '/mku_allied_doc_2005_thebeatleslovemedo',
    mku_allied_fl_1951_atwarwiththearmy: '/mku_allied_fl_1951_atwarwiththearmy',
    mku_allied_fl_1975_gonewiththewest: '/mku_allied_fl_1975_gonewiththewest',
    mku_bseg_fl_2006_dirtylove: '/mku_bseg_fl_2006_dirtylove',
    mku_bseg_fl_2006_forgetaboutit: '/mku_bseg_fl_2006_forgetaboutit',
    mku_bseg_fl_2008_targetpractice: '/mku_bseg_fl_2008_targetpractice',
    mku_bseg_fl_2009_babysitterwanted: '/mku_bseg_fl_2009_babysitterwanted',
    mku_bseg_fl_2011_sodiumbabies: '/mku_bseg_fl_2011_sodiumbabies',
    mku_bseg_fl_2012_youcantkillstephenking: '/mku_bseg_fl_2012_youcantkillstephenking',
    mku_bseg_fl_2013_crimsonwinter: '/mku_bseg_fl_2013_crimsonwinter',
    mku_bseg_fl_2013_mosquitoman: '/mku_bseg_fl_2013_mosquitoman',
    mku_bseg_fl_2013_singularityprinciple: '/mku_bseg_fl_2013_singularityprinciple',
    mku_bseg_sf_2020_felicitymakesafriend: '/mku_bseg_sf_2020_felicitymakesafriend',
    mku_allied_doc_1941_targetfortonight: '/mku_allied_doc_1941_targetfortonight',
    mku_allied_doc_1943_decemberseventh: '/mku_allied_doc_1943_decemberseventh',
    mku_allied_doc_1943_photographicintelligenceforbom: '/mku_allied_doc_1943_photographicintelligenceforbom',
    mku_allied_doc_1943_reportfromthealeutians: '/mku_allied_doc_1943_reportfromthealeutians',
    mku_allied_doc_1943_wingsup: '/mku_allied_doc_1943_wingsup',
    mku_allied_doc_1944_attackbattlefornewbrittain: '/mku_allied_doc_1944_attackbattlefornewbrittain',
    mku_allied_doc_1944_thenegrosoldier: '/mku_allied_doc_1944_thenegrosoldier',
    mku_allied_doc_1947_nurembergtrial: '/mku_allied_doc_1947_nurembergtrial',
    mku_allied_doc_1948_towardindependence: '/mku_allied_doc_1948_towardindependence',
    mku_allied_doc_1950_cold_war_atomic_attack: '/mku_allied_doc_1950_cold_war_atomic_attack',
    mku_allied_doc_1950_douglas_macarthur: '/mku_allied_doc_1950_douglas_macarthur',
    mku_allied_doc_1950_dwight_d_eisenhower: '/mku_allied_doc_1950_dwight_d_eisenhower',
    mku_allied_doc_1950_mao_doc: '/mku_allied_doc_1950_mao_doc',
    mku_allied_doc_1957_a_day_called_x: '/mku_allied_doc_1957_a_day_called_x',
    mku_allied_doc_1960_jfkelectionnightlatenews: '/mku_allied_doc_1960_jfkelectionnightlatenews',
    mku_allied_doc_1961_cold_war_checkpoint_charlie_us: '/mku_allied_doc_1961_cold_war_checkpoint_charlie_us',
    mku_allied_doc_1962_tokeepandbeararms: '/mku_allied_doc_1962_tokeepandbeararms',
    mku_allied_doc_1963_hollywoodwithoutmakeup: '/mku_allied_doc_1963_hollywoodwithoutmakeup',
    mku_allied_doc_1964_thestoryofgeneraljohnjpershing: '/mku_allied_doc_1964_thestoryofgeneraljohnjpershing',
    mku_allied_doc_1966_ayeartowardstomorrow: '/mku_allied_doc_1966_ayeartowardstomorrow',
    mku_allied_doc_1966_ho_shi_min: '/mku_allied_doc_1966_ho_shi_min',
    mku_allied_doc_1969_illegalgamblingnumbersdiceandb: '/mku_allied_doc_1969_illegalgamblingnumbersdiceandb',
    mku_allied_doc_1972_thedistantdrummerflowersofdark: '/mku_allied_doc_1972_thedistantdrummerflowersofdark',
    mku_allied_doc_1973_nixonsaddressonwatergateinvest: '/mku_allied_doc_1973_nixonsaddressonwatergateinvest',
    mku_allied_doc_1976_f15agileeagle: '/mku_allied_doc_1976_f15agileeagle',
    mku_allied_doc_1976_wingsofgold: '/mku_allied_doc_1976_wingsofgold',
    mku_allied_doc_1976_wingsofsilver: '/mku_allied_doc_1976_wingsofsilver',
    mku_allied_doc_1981_general_bradley: '/mku_allied_doc_1981_general_bradley',
    mku_allied_doc_1981_ronaldreaganfarewelladdresstot: '/mku_allied_doc_1981_ronaldreaganfarewelladdresstot',
    mku_allied_doc_1987_ronaldreaganberlinwallspeech: '/mku_allied_doc_1987_ronaldreaganberlinwallspeech',
    mku_allied_doc_2009_michaeljacksonthelegacy: '/mku_allied_doc_2009_michaeljacksonthelegacy',
    mku_allied_doc_2010_nightfall: '/mku_allied_doc_2010_nightfall',
    mku_allied_fl_1933_ashriekinthenight: '/mku_allied_fl_1933_ashriekinthenight',
    mku_allied_fl_1935_theshadowofsilklennox: '/mku_allied_fl_1935_theshadowofsilklennox',
    mku_allied_fl_1936_mymangodfrey: '/mku_allied_fl_1936_mymangodfrey',
    mku_allied_fl_1937_troubleintexas: '/mku_allied_fl_1937_troubleintexas',
    mku_allied_fl_1938_tarzansrevenge: '/mku_allied_fl_1938_tarzansrevenge',
    mku_allied_fl_1939_gulliverstravels: '/mku_allied_fl_1939_gulliverstravels',
    mku_allied_fl_1939_loveaffair: '/mku_allied_fl_1939_loveaffair',
    mku_allied_fl_1939_thelittleprincess: '/mku_allied_fl_1939_thelittleprincess',
    mku_allied_fl_1940_hisgirlfriday: '/mku_allied_fl_1940_hisgirlfriday',
    mku_allied_fl_1941_meetjohndoe: '/mku_allied_fl_1941_meetjohndoe',
    mku_allied_fl_1941_thephiladelphiastory: '/mku_allied_fl_1941_thephiladelphiastory',
    mku_allied_fl_1942_toomanywomen: '/mku_allied_fl_1942_toomanywomen',
    mku_allied_fl_1945_whistlestop: '/mku_allied_fl_1945_whistlestop',
    mku_allied_fl_1946_gilda: '/mku_allied_fl_1946_gilda',
    mku_allied_fl_1946_heartbeat: '/mku_allied_fl_1946_heartbeat',
    mku_allied_fl_1946_theoutlaw: '/mku_allied_fl_1946_theoutlaw',
    mku_allied_fl_1948_ladyfromshanghai: '/mku_allied_fl_1948_ladyfromshanghai',
    mku_allied_fl_1950_theasphaltjungle: '/mku_allied_fl_1950_theasphaltjungle',
    mku_allied_fl_1950_themanwhocheatedhimself: '/mku_allied_fl_1950_themanwhocheatedhimself',
    mku_allied_fl_1951_hometownstory: '/mku_allied_fl_1951_hometownstory',
    mku_allied_fl_1951_royalwedding: '/mku_allied_fl_1951_royalwedding',
    mku_allied_fl_1952_belalugosimeetsagorilla: '/mku_allied_fl_1952_belalugosimeetsagorilla',
    mku_allied_fl_1952_dontbothertoknock: '/mku_allied_fl_1952_dontbothertoknock',
    mku_allied_fl_1952_jackandthebeanstalk: '/mku_allied_fl_1952_jackandthebeanstalk',
    mku_allied_fl_1952_kansaspacific: '/mku_allied_fl_1952_kansaspacific',
    mku_allied_fl_1952_roadtobali: '/mku_allied_fl_1952_roadtobali',
    mku_allied_fl_1954_sabrina: '/mku_allied_fl_1954_sabrina',
    mku_allied_fl_1954_suddenly: '/mku_allied_fl_1954_suddenly',
    mku_allied_fl_1955_blackwidow: '/mku_allied_fl_1955_blackwidow',
    mku_allied_fl_1955_daughterofhorror: '/mku_allied_fl_1955_daughterofhorror',
    mku_allied_fl_1955_teaserama: '/mku_allied_fl_1955_teaserama',
    mku_allied_fl_1957_plan9fromouterspace: '/mku_allied_fl_1957_plan9fromouterspace',
    mku_allied_fl_1960_santaclaus: '/mku_allied_fl_1960_santaclaus',
    mku_allied_fl_1964_santaclausconquersthemartians: '/mku_allied_fl_1964_santaclausconquersthemartians',
    mku_allied_fl_1968_nightofthelivingdead: '/mku_allied_fl_1968_nightofthelivingdead',
    mku_allied_fl_1971_evelknievel: '/mku_allied_fl_1971_evelknievel',
    mku_allied_fl_1972_theadventuresofpinocchio: '/mku_allied_fl_1972_theadventuresofpinocchio',
    mku_allied_fl_1981_christmasmountain: '/mku_allied_fl_1981_christmasmountain',
    mku_allied_fl_1986_tobymcteague: '/mku_allied_fl_1986_tobymcteague',
    mku_allied_sf_1964_armyinactionthefinesttradition: '/mku_allied_sf_1964_armyinactionthefinesttradition',
    mku_allied_sf_1977_imafool: '/mku_allied_sf_1977_imafool',
    mku_allied_tv_1953_thegift: '/mku_allied_tv_1953_thegift',
    mku_allied_tv_1954_meetcorlisarcherthechristmassto: '/mku_allied_tv_1954_meetcorlisarcherthechristmassto',
    mku_allied_tv_1954_theanswer: '/mku_allied_tv_1954_theanswer',
    mku_bseg_fl_2019_hiddenchambers: '/mku_bseg_fl_2019_hiddenchambers',
    mku_bseg_fl_2021_marilynandsinatra: '/mku_bseg_fl_2021_marilynandsinatra',
    mku_bseg_ws_2021_whosthebestdoctorwhomonsterwithsy: '/mku_bseg_ws_2021_whosthebestdoctorwhomonsterwithsy',
    mku_allied_doc_1917_thebattleofthesomme: '/mku_allied_doc_1917_thebattleofthesomme',
    mku_allied_doc_1924_classic_aviation_1909_1924: '/mku_allied_doc_1924_classic_aviation_1909_1924',
    mku_allied_doc_1925_classic_aviation_1925: '/mku_allied_doc_1925_classic_aviation_1925',
    mku_allied_doc_1926_classic_aviation_1926: '/mku_allied_doc_1926_classic_aviation_1926',
    mku_allied_doc_1935_theyanksarecoming: '/mku_allied_doc_1935_theyanksarecoming',
    mku_allied_doc_1939_invasionofpolandbythegermanarm: '/mku_allied_doc_1939_invasionofpolandbythegermanarm',
    mku_allied_doc_1941_bomber: '/mku_allied_doc_1941_bomber',
    mku_allied_doc_1942_preludetowar: '/mku_allied_doc_1942_preludetowar',
    mku_allied_doc_1942_warandnavydepartmentpresentdec: '/mku_allied_doc_1942_warandnavydepartmentpresentdec',
    mku_allied_doc_1943_combatamerica: '/mku_allied_doc_1943_combatamerica',
    mku_allied_doc_1943_howtoflythebseventeen: '/mku_allied_doc_1943_howtoflythebseventeen',
    mku_allied_doc_1943_learnandlive: '/mku_allied_doc_1943_learnandlive',
    mku_allied_doc_1943_reconnaissancepilot: '/mku_allied_doc_1943_reconnaissancepilot',
    mku_allied_doc_1943_thebattleofrussia: '/mku_allied_doc_1943_thebattleofrussia',
    mku_allied_doc_1943_the_rear_gunner: '/mku_allied_doc_1943_the_rear_gunner',
    mku_allied_doc_1943_thunderbolt_combat_film_report: '/mku_allied_doc_1943_thunderbolt_combat_film_report',
    mku_allied_doc_1944_aafreport1944: '/mku_allied_doc_1944_aafreport1944',
    mku_allied_doc_1944_attackinthepacific: '/mku_allied_doc_1944_attackinthepacific',
    mku_allied_doc_1944_classic_aviation_1942_1944: '/mku_allied_doc_1944_classic_aviation_1942_1944',
    mku_allied_doc_1944_expansion_air_power: '/mku_allied_doc_1944_expansion_air_power',
    mku_allied_doc_1944_landandliveinthejungle: '/mku_allied_doc_1944_landandliveinthejungle',
    mku_allied_doc_1944_thebattleforthemarianas: '/mku_allied_doc_1944_thebattleforthemarianas',
    mku_allied_doc_1944_thebattleofchina: '/mku_allied_doc_1944_thebattleofchina',
    mku_allied_doc_1944_the_memphis_belle_flying_fortr: '/mku_allied_doc_1944_the_memphis_belle_flying_fortr',
    mku_allied_doc_1944_tunisianvictory: '/mku_allied_doc_1944_tunisianvictory',
    mku_allied_doc_1944_withthemarinesattarawa: '/mku_allied_doc_1944_withthemarinesattarawa',
    mku_allied_doc_1945_appointmentintokyo: '/mku_allied_doc_1945_appointmentintokyo',
    mku_allied_doc_1945_knowyourenemyjapan: '/mku_allied_doc_1945_knowyourenemyjapan',
    mku_allied_doc_1945_thefightinglady: '/mku_allied_doc_1945_thefightinglady',
    mku_allied_doc_1945_thefleetthatcametostay: '/mku_allied_doc_1945_thefleetthatcametostay',
    mku_allied_doc_1945_thetrueglory: '/mku_allied_doc_1945_thetrueglory',
    mku_allied_doc_1945_the_fight_for_the_sky: '/mku_allied_doc_1945_the_fight_for_the_sky',
    mku_allied_doc_1945_thunderbolt: '/mku_allied_doc_1945_thunderbolt',
    mku_allied_doc_1945_totheshoresofiwojima: '/mku_allied_doc_1945_totheshoresofiwojima',
    mku_allied_doc_1945_warcomestoamerica: '/mku_allied_doc_1945_warcomestoamerica',
    mku_allied_doc_1946_deathmills: '/mku_allied_doc_1946_deathmills',
    mku_allied_doc_1946_lettherebelight: '/mku_allied_doc_1946_lettherebelight',
    mku_allied_doc_1947_diaryofasergeant: '/mku_allied_doc_1947_diaryofasergeant',
    mku_allied_doc_1951_oneyearinkorea: '/mku_allied_doc_1951_oneyearinkorea',
    mku_allied_doc_1951_thepacificineuruption: '/mku_allied_doc_1951_thepacificineuruption',
    mku_allied_doc_1951_thisiskorea: '/mku_allied_doc_1951_thisiskorea',
    mku_allied_doc_1952_unoffensive: '/mku_allied_doc_1952_unoffensive',
    mku_allied_doc_1953_themarinesinkorea: '/mku_allied_doc_1953_themarinesinkorea',
    mku_allied_doc_1956_communistblueprintforconquest: '/mku_allied_doc_1956_communistblueprintforconquest',
    mku_allied_doc_1956_woodrowwilsonspoksmanfortomorr: '/mku_allied_doc_1956_woodrowwilsonspoksmanfortomorr',
    mku_allied_doc_1958_classic_aviation_1944_1958: '/mku_allied_doc_1958_classic_aviation_1944_1958',
    mku_allied_doc_1959_thecommunistweaponofallure: '/mku_allied_doc_1959_thecommunistweaponofallure',
    mku_allied_doc_1960_dwightdeisenhowerstory: '/mku_allied_doc_1960_dwightdeisenhowerstory',
    mku_allied_doc_1960_payoffinthepacific: '/mku_allied_doc_1960_payoffinthepacific',
    mku_allied_doc_1960_the24thinfantrydivisionintheko: '/mku_allied_doc_1960_the24thinfantrydivisionintheko',
    mku_allied_doc_1961_kamikaze: '/mku_allied_doc_1961_kamikaze',
    mku_allied_doc_1962_operationabolition: '/mku_allied_doc_1962_operationabolition',
    mku_allied_doc_1962_theberlinwall: '/mku_allied_doc_1962_theberlinwall',
    mku_allied_doc_1962_theroadtothewall: '/mku_allied_doc_1962_theroadtothewall',
    mku_allied_doc_1963_attackonpearlharbor: '/mku_allied_doc_1963_attackonpearlharbor',
    mku_allied_doc_1963_generalgeorgespatton: '/mku_allied_doc_1963_generalgeorgespatton',
    mku_allied_doc_1963_thehiddenwarinvietnam: '/mku_allied_doc_1963_thehiddenwarinvietnam',
    mku_allied_doc_1964_oneweekinoctobercubanmissilecr: '/mku_allied_doc_1964_oneweekinoctobercubanmissilecr',
    mku_allied_doc_1964_operationmontagnard: '/mku_allied_doc_1964_operationmontagnard',
    mku_allied_doc_1965_whyvietnam: '/mku_allied_doc_1965_whyvietnam',
    mku_allied_doc_1966_marines66: '/mku_allied_doc_1966_marines66',
    mku_allied_doc_1967_adayinvietnam: '/mku_allied_doc_1967_adayinvietnam',
    mku_allied_doc_1967_anationbuildsunderfire: '/mku_allied_doc_1967_anationbuildsunderfire',
    mku_allied_doc_1967_chinarootsofmadness: '/mku_allied_doc_1967_chinarootsofmadness',
    mku_allied_doc_1967_riverpatrol: '/mku_allied_doc_1967_riverpatrol',
    mku_allied_doc_1967_thereisaway: '/mku_allied_doc_1967_thereisaway',
    mku_allied_doc_1967_thescreamingeaglesinvietnam: '/mku_allied_doc_1967_thescreamingeaglesinvietnam',
    mku_allied_doc_1968_thebattleofkhesanh: '/mku_allied_doc_1968_thebattleofkhesanh',
    mku_allied_doc_1968_unitedstatesairforceusafcombat: '/mku_allied_doc_1968_unitedstatesairforceusafcombat',
    mku_allied_doc_1968_vietnamcrucible: '/mku_allied_doc_1968_vietnamcrucible',
    mku_allied_doc_1969_11tharmoredcalvaryregimentinvi: '/mku_allied_doc_1969_11tharmoredcalvaryregimentinvi',
    mku_allied_doc_1969_4thinfantrydivisioninvietnam: '/mku_allied_doc_1969_4thinfantrydivisioninvietnam',
    mku_allied_doc_1969_tosaveasoldier: '/mku_allied_doc_1969_tosaveasoldier',
    mku_allied_doc_1969_wherethegirlsare: '/mku_allied_doc_1969_wherethegirlsare',
    mku_allied_doc_1971_firstinfantrydivisioninvietnam: '/mku_allied_doc_1971_firstinfantrydivisioninvietnam',
    mku_allied_doc_1971_thedrillsergeant: '/mku_allied_doc_1971_thedrillsergeant',
    mku_allied_doc_1971_thefacesofrescue: '/mku_allied_doc_1971_thefacesofrescue',
    mku_allied_doc_1971_vietnamvietnam: '/mku_allied_doc_1971_vietnamvietnam',
    mku_allied_doc_1972_thepresidentoftheusainthesovie: '/mku_allied_doc_1972_thepresidentoftheusainthesovie',
    mku_allied_doc_1978_classic_aviation_1961_1978: '/mku_allied_doc_1978_classic_aviation_1961_1978',
    mku_allied_doc_1980s_night_stalkers: '/mku_allied_doc_1980s_night_stalkers',
    mku_allied_doc_1984_fa18_hornet_design_and_testing: '/mku_allied_doc_1984_fa18_hornet_design_and_testing',
    mku_allied_doc_1999_thunder_from_above: '/mku_allied_doc_1999_thunder_from_above',
    mku_allied_doc_1999_worldwariiincolorvolumeianewwo: '/mku_allied_doc_1999_worldwariiincolorvolumeianewwo',
    mku_allied_doc_1999_worldwariiincolorvolumeitotalw: '/mku_allied_doc_1999_worldwariiincolorvolumeitotalw',
    mku_allied_doc_2005_defender_of_the_fleet: '/mku_allied_doc_2005_defender_of_the_fleet',
    mku_allied_doc_2007_jets_of_the_cold_war: '/mku_allied_doc_2007_jets_of_the_cold_war',
    mku_allied_doc_2007_jets_of_the_us_air_force: '/mku_allied_doc_2007_jets_of_the_us_air_force',
    mku_allied_doc_2008_armedforcesassistancetokorea: '/mku_allied_doc_2008_armedforcesassistancetokorea',
    mku_allied_doc_2008_armymarinecounterattack: '/mku_allied_doc_2008_armymarinecounterattack',
    mku_allied_doc_2008_christmasinkorea1953: '/mku_allied_doc_2008_christmasinkorea1953',
    mku_allied_doc_2008_koreanoperations: '/mku_allied_doc_2008_koreanoperations',
    mku_allied_doc_2008_koreantrucetalk: '/mku_allied_doc_2008_koreantrucetalk',
    mku_allied_doc_2008_the2ndinfantrydivisioninthekor: '/mku_allied_doc_2008_the2ndinfantrydivisioninthekor',
    mku_allied_doc_2008_theartilleryinkoreanwar: '/mku_allied_doc_2008_theartilleryinkoreanwar',
    mku_allied_doc_2008_thetrainingonkoreansoldiers: '/mku_allied_doc_2008_thetrainingonkoreansoldiers',
    mku_allied_doc_2008_theunitedstatesarmyrangers: '/mku_allied_doc_2008_theunitedstatesarmyrangers',
    mku_allied_doc_2008_theusarmytransportcorps: '/mku_allied_doc_2008_theusarmytransportcorps',
    mku_allied_doc_2008_unitednationsconsolidatebelowt: '/mku_allied_doc_2008_unitednationsconsolidatebelowt',
    mku_allied_doc_2018_amotionpicturehistoryofthrkore: '/mku_allied_doc_2018_amotionpicturehistoryofthrkore',
    mku_allied_fl_1945_resistingenemyinterrogation: '/mku_allied_fl_1945_resistingenemyinterrogation',
    mku_allied_sf_1944_ditchandlive: '/mku_allied_sf_1944_ditchandlive',
    mku_allied_sf_1959_savingsbondandstamppromotions: '/mku_allied_sf_1959_savingsbondandstamppromotions',
    mku_allied_sf_1962_rednightmare: '/mku_allied_sf_1962_rednightmare',
};