//copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleSearchMenu } from '../../redux/actions/appActions';
import { toggleSubMenuAbout } from '../../redux/actions/appActions';
import { toggleSubMenuCategory } from '../../redux/actions/appActions';
import { toggleCategory } from '../../redux/actions/appActions';
import { toggleSubscription } from '../../redux/actions/appActions';
import Routes from '../../containers/AppRouter/routes';
import { withRouter } from 'react-router';
import store from '../../redux/store';
import LoadingIndicator from '../../components/Widgets/LoadingIndicator'
import { toggleLoadingIndicator } from '../../redux/actions/appActions';
import './index.css';
import nav_prev from '../../images/chevron-double-left-duotone.png';
import nav_next from '../../images/chevron-double-right-duotone.png';
import FadeIn from 'react-fade-in';

export class NavBottom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: {},
        };
    }
    static propTypes = {
        // match: PropTypes.object.isRequired,
        // location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }
    handleFormSubmit(event) {
        // event.preventDefault();
    }
    componentDidMount() {
        // console.log(window.history);
    }



    render() {
        const {
            is_search_menu,
            is_subscription,
            // is_submenu_about,
            // is_submenu_category,
            is_category,
            toggleSearchMenu,
            toggleSubMenuAbout,
            toggleSubMenuCategory,
            toggleCategory,
            toggleSubscription,
            toggleLoadingIndicator,
            is_loading_indicator,
            history
        } = this.props;
        const state = store.getState();
        // console.log(history);
        // console.log(state)
        return (
            <div>
                {(!history.location.pathname.match(/\/$/i)) &&
                    <div>
                        {is_category.match(/unknown/i) ?
                            <div className='nav-button'onClick={()=> {
                                toggleSubMenuAbout(false);
                                toggleSubMenuCategory(false);
                                toggleCategory('home');
                                if (is_search_menu) {toggleSearchMenu(!is_search_menu)};
                                history.push(Routes.home);
                            }}>
                                <div className="nav-prev">                   
                                    <img alt="Navigate back" src={nav_prev} />
                                </div>
                            </div>                        
                            :
                            <div className='nav-button'
                                onClick={()=> {
                                toggleSubMenuAbout(false);
                                toggleSubMenuCategory(false);                                
                                if (is_search_menu) {toggleSearchMenu(!is_search_menu)};
                                history.goBack();                   
                            }}>
                                <div className="nav-prev">
                                    <img alt="Navigate back" src={nav_prev} />
                                </div>                            
                            </div>
                        }
                    </div>
                }                    
            </div>
        );
    }
}
NavBottom.propTypes = {

    toggleSearchMenu: PropTypes.func,
    toggleSubscription: PropTypes.func,
    is_search_menu: PropTypes.bool,
    is_subscription: PropTypes.bool,
    toggleLoadingIndicator: PropTypes.func,
    is_loading_indicator: PropTypes.bool,
}
NavBottom.defaultProps = {

    toggleSearchMenu: () => {},
    toggleSubscription: () => {},
    is_search_menu: true,
    is_subscription: false,
    toggleLoadingIndicator: () => {},
    is_loading_indicator: false,
}
export const mapStateToProps = state => ({
    id: state.user.id,
    username: state.user.username,
    email: state.user.email,
    newsletter: state.user.newsletter,
    subscription: state.user.subscription,
    subscription_plan_id: state.user.subscription_plan_id,
    is_search_menu: state.app.is_search_menu,
    is_submenu_about: state.app.is_submenu_about,
    is_submenu_category: state.app.is_submenu_category,
    is_category: state.app.is_category,
    is_subscription: state.app.is_subscription,
    is_loading_indicator: state.app.is_loading_indicator,
});
export const mapDispatchToProps = dispatch => ({
    
    toggleSearchMenu: value => dispatch(toggleSearchMenu(value)),
    toggleSubMenuAbout: value => dispatch(toggleSubMenuAbout(value)),
    toggleSubMenuCategory: value => dispatch(toggleSubMenuCategory(value)),
    toggleCategory: value => dispatch(toggleCategory(value)),
    toggleSubscription: value => dispatch(toggleSubscription(value)),
    toggleLoadingIndicator: value => dispatch(toggleLoadingIndicator(value)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBottom));
// export default UserStatus;