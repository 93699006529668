//copyright: Big Screen Entertainment Group Inc.
import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleSearchMenu } from '../../redux/actions/appActions';
import { toggleSubMenuAbout } from '../../redux/actions/appActions';
import { toggleSubMenuCategory } from '../../redux/actions/appActions';
import { toggleCategory } from '../../redux/actions/appActions';

import './index.css';
import search_ico from '../../images/search.svg';
import searchmenu_user from '../../images/ico_usermenu.png';
// import hamburger_ico from '../../images/hamburger.svg';
import submenu_close from '../../images/chevron-double-left-duotone-white.png';
import submenu_open from '../../images/chevron-double-down-duotone.png';
import Routes from '../AppRouter/routes';
import RoutesHIFM from '../HIFMRouter/routeshifm';
import { withRouter } from 'react-router';
import SubMenuAbout from '../SubMenuAbout';
import SubMenuCategory from '../SubMenuCategory';
import hamburger_ico from '../../images/hamburger.png';
import closed from '../../images/closed.png';

import FadeIn from 'react-fade-in';

import store from '../../redux/store';
import { toggleOverflow } from '../../redux/actions/appActions';
import { toggleOverflowScrollBottom } from '../../redux/actions/appActions';
// import Breadcrumbs from '../Breadcrumbs'



//CATEGORIES IMAGES MAIN -- START
import searchmenu_home from '../../images/home-lg-alt-duotone.png';
import searchmenu_privacy from '../../images/privacy.png';
import searchmenu_tos from '../../images/tos.png';
import searchmenu_support from '../../images/question-circle-solid.png';
import searchmenu_about from '../../images/about.png';
import searchmenu_hifm from '../../images/hifm.png';
import img_category from '../../images/category.png';
import img_logo_menu_bseg from '../../images/both_logo_menu_bseg.png';
// import menu_bakc from '../../images/menu-background.png';


// const SubMenuAbout = ({}) => {
//     return (
//     <React.Fragment>
//       <div className="sub-menu-container">
//       <div className="sub-menu-item" onClick={()=> {
//             toggleSearchMenu(!is_search_menu);
//             history.push(Routes.about)
//             // toggleSubMenuAbout(!is_submenu_about);
//         }}>BSE</div>
//       </div>
//     </React.Fragment>
//     );
// };



export class SearchMenu extends Component {
    constructor(props) {
        super(props);
        this.scrollBot = React.createRef();
        this.scrollTop = React.createRef();
        this.state = {
            scrollTop: false,
            scrollBottom: false,
        };
        // console.log(props)
    }

    componentDidMount() {
        // (window.adsbygoogle = window.adsbygoogle || []).push({});
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'relative';
        document.body.style.overflowY = 'scroll';
        this.handleOverflow()

    }

    componentWillUnmount() {
        const {
            toggleOverflow,
            is_overflow,
        } = this.props;
        document.body.style.overflow = 'unset';
        document.body.style.position = 'relative';
        document.body.style.overflowY = 'scroll';
        
        toggleOverflow(false)
        toggleOverflowScrollBottom(false)
        
    }
    componentDidUpdate() {
        this.handleOverflow();
    }

    

    handleOverflow() {
        const {
            toggleOverflow,
            toggleOverflowScrollBottom,
            is_overflow,
            is_overflow_scrollbottom,
        } = this.props;
        
        const getWindowDimensions = () => {
            const { innerWidth: width, innerHeight: height } = window;
            return {
                width,
                height
            };
        };
        const useWindowDimensions = () => {
            const [windowDimensions, setWindowDimensions] = useState(
                getWindowDimensions()
            );
            useEffect(() => {
                function handleResize() {
                    setWindowDimensions(getWindowDimensions());
                }
                window.addEventListener("resize", handleResize);
                return () => window.removeEventListener("resize", handleResize);
            }, []);
            return windowDimensions;
        };
        const element = this.element;
        const { width, height } = getWindowDimensions();
        const windowSize = [window.innerWidth, window.innerHeight];
        // console.log(element.scrollHeight + 60);
        // console.log(element);
        // console.log(height);
        // console.log(element.offsetHeight)
        
        let hasOverflowingChildren = false;
        if (windowSize[0] <= 768) {
            hasOverflowingChildren = element.offsetHeight + 60 > height;
        } else {
            hasOverflowingChildren = element.offsetHeight > height;
        }
        // console.log(hasOverflowingChildren);
        toggleOverflow(hasOverflowingChildren)
        if (hasOverflowingChildren) {
            this.scrollBot.current.style.display = 'block'
        } else {
            this.scrollBot.current.style.display = 'none';
            this.scrollTop.current.style.display = 'none';
        }
    }

    static propTypes = {
        // match: PropTypes.object.isRequired,
        // location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }

    

    render() {
        const {
            is_search_menu,
            is_submenu_about,
            is_submenu_category,
            is_category,
            is_overflow,
            is_overflow_scrollbottom,
            toggleSearchMenu,
            toggleSubMenuAbout,
            toggleSubMenuCategory,
            toggleCategory,
            toggleOverflow,
            toggleOverflowScrollBottom,
            // match,
            // location,
            history
        } = this.props;

        const handleScroll = (e) => {
            const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
            const top = e.target.scrollTop === 0;
            const target = e.target;
            
            const {                
                is_overflow,
                is_overflow_scrollbottom,
                toggleOverflow,
                toggleOverflowScrollBottom,
            } = this.props;

            // console.log(target.scrollHeight)
            // console.log(target.scrollTop)
            // console.log(target.clientHeight)a
            // console.log(bottom)
            // console.log(top)
            // console.log(is_overflow)
            
            if (is_overflow && bottom && !top) {
                // console.log('DISPLAY BOTTOM NONE - DISPLAY TOP BLOCK - AT BOTTOM')
                this.scrollBot.current.style.display = 'none'
                this.scrollTop.current.style.display = 'block'

            } else if (is_overflow && !bottom && !top) {
                // console.log('DISPLAY BOTTOM BLOCK - DISPLAY TOP BLOCK - IN BETWEEN');
                this.scrollBot.current.style.display = 'block'
                this.scrollTop.current.style.display = 'block'

            } else if (is_overflow && top && !bottom) {
                // console.log('DISPLAY BOTTOM BLOCK - DISPLAY TOP NONE - AT TOP')
                this.scrollBot.current.style.display = 'block'
                this.scrollTop.current.style.display = 'none'
            }
        }

        // console.log(this.props);        
        return (
            <div className="menu-container" onScroll={handleScroll}>
            {/*<FadeIn>*/}
                {/*{console.log(this.state.hasOverflowingChildren)}*/}
                <div className="search-menu-container">
                    
                    
                    
                    <div className="menu-overflow-scrollbottom" ref={this.scrollTop}></div>
                    
                    <div className='submenu-fixed' onClick={()=>{ toggleSearchMenu(!is_search_menu); }}>
                        {!is_search_menu ? <img alt="hamburger_ico" src={hamburger_ico} /> : <img alt="closed" src={closed} />}
                    </div>

                    {/*<div className="menu-img">
                        <a href="https://www.otcmarkets.com/stock/BSEG/overview" target="_blank" rel="noreferrer"><img alt="BSEG Logo" src={img_logo_menu_bseg} /></a>
                    </div>
                    <div className="stock-symbol">
                        <a href="https://www.otcmarkets.com/stock/BSEG/overview" target="_blank" rel="noreferrer"><h4 className="center"><small>stock: <strong>BSEG</strong></small></h4></a>
                    </div>*/}
                    <div ref={(el) => {this.element = el}}  className="search-menu-list" style={{ backgroundImage: "url(/menu-background.png)" }}>
                        <div className="submenu-search">
                          <img alt="BSE search icon" src={search_ico} />
                          <input type="text" placeholder="Search for title"/>
                        </div>
                        <div className={is_category === 'home' ? "search-menu-item search-menu-active" : "search-menu-item"} onClick={()=> {
                            toggleSubMenuAbout(false);
                            toggleSubMenuCategory(false);
                            toggleSearchMenu(!is_search_menu);
                            toggleCategory('home');
                            history.push(Routes.home);
                        }}><img alt="BSE search menu home" className="search-menu-img" src={searchmenu_home} />Home</div>
                        <div className={is_category === 'userprofile' ? "search-menu-item search-menu-active" : "search-menu-item"} onClick={()=> {
                            toggleSubMenuAbout(false);
                            toggleSubMenuCategory(false);
                            toggleSearchMenu(!is_search_menu);
                            toggleCategory('userprofile');
                            history.push(Routes.userprofile);
                        }}><img alt="BSE search menu user" className="search-menu-img" src={searchmenu_user} />User</div>
                        <div className={is_submenu_category ? "sub-menu sub-menu-active" : "sub-menu"} onClick={()=> {
                            
                            // toggleSearchMenu(!is_search_menu);
                            // history.push(Routes.about)
                            // this.handleOverflow();
                            toggleSubMenuCategory(!is_submenu_category);
                            // is_submenu_about ? toggleSubMenuAbout(!is_submenu_about) : toggleSubMenuAbout(is_submenu_about);
                        }}><img alt="BSE search menu categories" className="search-menu-img" src={img_category} />Categories {!is_submenu_category ? <img alt="BSE sub menu close" src={submenu_close} /> : <img alt="BSE search menu open" src={submenu_open} />}</div>
                        {is_submenu_category && <FadeIn><SubMenuCategory /></FadeIn>}
                        <div className={is_category === 'hifm' ? "search-menu-item search-menu-active" : "search-menu-item"} onClick={() => {
                            toggleSubMenuAbout(false);
                            toggleSubMenuCategory(false);
                            toggleSearchMenu(!is_search_menu);
                            toggleCategory('hifm');
                            history.push(RoutesHIFM.hifm);
                        }}><img alt="BSE search menu Hollywood International Filmmakers Magazine" className="search-menu-img" src={searchmenu_hifm} />HIFM</div>
                        <div className={is_submenu_about ? "sub-menu sub-menu-active" : "sub-menu"} onClick={()=> {
                            // toggleSearchMenu(!is_search_menu);
                            // history.push(Routes.about)
                            // this.handleOverflow();
                            toggleSubMenuAbout(!is_submenu_about);
                            // is_submenu_category ? toggleSubMenuCategory(!is_submenu_category) : toggleSubMenuCategory(is_submenu_category);
                        }}><img alt="BSE search menu about" className="search-menu-img" src={searchmenu_about} />About {!is_submenu_about ? <img alt="BSE sub menu close" src={submenu_close} /> : <img alt="BSE sub menu open" src={submenu_open} />}</div>
                        {is_submenu_about && <FadeIn><SubMenuAbout /></FadeIn>}
                        {// <div className={is_category === 'contacts' ? "search-menu-item search-menu-active" : "search-menu-item"} onClick={()=> {
                        //     toggleSubMenuAbout(false);
                        //     toggleSubMenuCategory(false);
                        //     toggleSearchMenu(!is_search_menu);
                        //     toggleCategory('contacts');
                        //     history.push(Routes.contacts)
                        // }}><img className="search-menu-img" src={searchmenu_tos} />Contacts</div>
                        }
                        
                        
                        <div className={is_category === 'privacy' ? "search-menu-item search-menu-active" : "search-menu-item"} onClick={()=> {
                            toggleSubMenuAbout(false);
                            toggleSubMenuCategory(false);
                            toggleSearchMenu(!is_search_menu);
                            toggleCategory('privacy');
                            history.push(Routes.privacy);
                        }}><img alt="BSE search menu privacy" className="search-menu-img" src={searchmenu_privacy} />Privacy</div>  
                        <div className={is_category === 'tos' ? "search-menu-item search-menu-active" : "search-menu-item"} onClick={()=> {
                            toggleSubMenuAbout(false);
                            toggleSubMenuCategory(false);
                            toggleSearchMenu(!is_search_menu);
                            toggleCategory('tos');
                            history.push(Routes.tos);
                        }}><img alt="BSE search menu terms of service" className="search-menu-img" src={searchmenu_tos} />TOS</div>
                        <a className="clear_all" href="https://support.bigstreamentertainment.com/" target="_blank" rel="noopener noreferrer"><div className={is_category === 'tos' ? "search-menu-item search-menu-active" : "search-menu-item"}>
                        <img alt="BSE search menu support" className="search-menu-img" src={searchmenu_support} />Support</div></a>
                    </div>
                    
                    <div className="menu-overflow" ref={this.scrollBot}></div>
                    
                </div>
            {/*</FadeIn>*/}
                
            </div>
        );
    }
}

SubMenuAbout.propTypes = {
    toggleSubMenuAbout: PropTypes.func,
    is_submenu_about: PropTypes.bool,
}

SubMenuAbout.defaultProps = {
    toggleSubMenuAbout: () => {},
    is_submenu_about: false,
}

SubMenuCategory.propTypes = {
    toggleSubMenuCategory: PropTypes.func,
    is_submenu_category: PropTypes.bool,
}

SubMenuCategory.defaultProps = {
    toggleSubMenuCategory: () => {},
    is_submenu_category: false,
}

SearchMenu.propTypes = {
    toggleSearchMenu: PropTypes.func,
    is_search_menu: PropTypes.bool,
    toggleOverflow: PropTypes.func,
    is_overflow: PropTypes.bool,
    toggleOverflowScrollBottom: PropTypes.func,
    is_overflow_scrollbottom: PropTypes.bool,
}

SearchMenu.defaultProps = {
    toggleSearchMenu: () => {},
    is_search_menu: true,
    toggleOverflow: () => {},
    is_overflow: false,
    toggleOverflowScrollBottom: () => {},
    is_overflow_scrollbottom: false,
}


export const mapStateToProps = state => ({
    is_search_menu: state.app.is_search_menu,
    is_submenu_about: state.app.is_submenu_about,
    is_submenu_category: state.app.is_submenu_category,
    is_category: state.app.is_category,
    is_overflow: state.app.is_overflow,
    is_overflow_scrollbottom: state.app.is_overflow_scrollbottom,
});

export const mapDispatchToProps = dispatch => ({
    toggleSearchMenu: value => dispatch(toggleSearchMenu(value)),
    toggleSubMenuAbout: value => dispatch(toggleSubMenuAbout(value)),
    toggleSubMenuCategory: value => dispatch(toggleSubMenuCategory(value)),
    toggleCategory: value => dispatch(toggleCategory(value)),
    toggleOverflow: value => dispatch(toggleOverflow(value)),
    toggleOverflowScrollBottom: value => dispatch(toggleOverflowScrollBottom(value)),
});

{ /*export default connect(mapStateToProps, mapDispatchToProps)(SearchMenu);*/ } { /*export default withRouter(SearchMenu);*/ }
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchMenu));