import * as ActionTypes from '../actions/appActions';

export const initialState = {
    is_search_menu: false,
    is_submenu_about: false,
    is_submenu_category: false,
    is_category: 'unknown',
    is_subscription: false,
    is_loading_indicator: false,
    is_loading_module: false,
    is_overflow: false,
    is_overflow_scrollbottom: false,
}

export default (state = initialState, action) => {
    switch (action.type) {

        case ActionTypes.TOGGLE_SEARCH_MENU:
            return Object.assign({}, state, {
                is_search_menu: action.value,
            });
        case ActionTypes.TOGGLE_SUBMENU_ABOUT:
            return Object.assign({}, state, {
                is_submenu_about: action.value,
            });
        case ActionTypes.TOGGLE_SUBMENU_CATEGORY:
            return Object.assign({}, state, {
                is_submenu_category: action.value,
            });
        case ActionTypes.TOGGLE_CATEGORY:
            return Object.assign({}, state, {
                is_category: action.value,
            });
        case ActionTypes.TOGGLE_SUBSCRIPTION:
            return Object.assign({}, state, {
                is_subscription: action.value,
            });
        case ActionTypes.TOGGLE_LOADING_INDICATOR:
            return Object.assign({}, state, {
                is_loading_indicator: action.value,
            });
        case ActionTypes.TOGGLE_LOADING_MODULE:
            return Object.assign({}, state, {
                is_loading_module: action.value,
            });
        case ActionTypes.TOGGLE_OVERFLOW:
            return Object.assign({}, state, {
                is_overflow: action.value,
            });
        case ActionTypes.TOGGLE_OVERFLOW_SCROLLBOTTOM:
            return Object.assign({}, state, {
                is_overflow_scrollbottom: action.value,
            });
        default:
            return state;
    }
};