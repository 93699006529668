//copyright: Big Screen Entertainment Group Inc.
import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import store from '../../redux/store';
import { connect } from 'react-redux';
import { toggleSearchMenu } from '../../redux/actions/appActions';
import { toggleSubMenuCategory } from '../../redux/actions/appActions';
import { toggleSubMenuAbout } from '../../redux/actions/appActions';
import { toggleCategory } from '../../redux/actions/appActions';
import { toggleOverflow } from '../../redux/actions/appActions';
// import './index.css';
import Routes from '../AppRouter/routes';
import { withRouter } from 'react-router';
// import SearchMenu from '../SearchMenu'; 
// import Breadcrumbs from '../Breadcrumbs' 
//CATEGORIES UPDATE REPLACE -- START
import img_category from '../../images/category.png';
// import category_featured from '../../images/category_featured.png';
// import category_up_and_coming_female_directors from '../../images/category_up_and_coming_female_directors.png';
// import category_biography from '../../images/category_biography.png';
// import category_documentary from '../../images/category_documentary.png';
// import category_drama from '../../images/category_drama.png';
// import category_news from '../../images/category_news.png';
// import category_comedy from '../../images/category_comedy.png';
// import category_action from '../../images/category_action.png';
// import category_horror from '../../images/category_horror.png';
// import category_adventure from '../../images/category_adventure.png';
// import category_science_fiction from '../../images/category_science_fiction.png';
// import category_trailer from '../../images/category_trailer.png';
// import category_hidden_chambers from '../../images/category_hidden_chambers.png';
// import category_ from '../../images/category_.png';
//CATEGORIES UPDATE REPLACE -- END
//CATEGORIES IMAGES CURRENT UPDATE -- END
import Category from '../Category';
export class SubMenuCategory extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {
            toggleOverflow,
            is_overflow
        } = this.props;
        // toggleOverflow(!is_overflow)
    }

    componentWillUnmount() {
        // const {
        //     toggleOverflow,
        //     is_overflow,
        //     is_submenu_about,
        //     is_submenu_category,
        // } = this.props;
        // console.log(is_submenu_about)
        // console.log(is_submenu_category)
        // if (is_submenu_about && is_submenu_category) {
        //     // do nothing as About menu is overflowing
        // } else if (!is_submenu_about && is_submenu_category) {
        //     toggleOverflow(false); //overflow is about to end once unmounted because about menu is also not on/false 
        // }
        
    }

    render() {
        const {
            is_search_menu,
            is_submenu_category,
            is_submenu_about,
            is_category,
            is_overflow,
            toggleSearchMenu,
            toggleSubMenuCategory,
            toggleSubMenuAbout,
            toggleCategory,
            toggleOverflow,
            match,
            location,
            history
        } = this.props;



        const state = store.getState();
        const playlist = state.custom1.playlist;
        // console.log(playlist);
        // let user_media_detail = null;
        // if (playlist) {
        //     user_media_detail = getResources(playlist, bse_media, 1);
        //     if (user_media_detail.length == 0) {
        //         user_media_detail = null;
        //     }
        // }
        return (
            <div className="sub-menu-container">
                <div className="sub-menu-list">
                    {
                        (playlist && state.user.subscription >= 30) ? // subscription numbers 30 and above are paying
                        ((Object.keys(playlist)).length > 0) &&
                        <div className={is_category === 'user playlist' ? "sub-menu-item sub-menu-item-active" : "sub-menu-item"} onClick={()=> {
                        toggleSearchMenu(!is_search_menu);
                        toggleSubMenuAbout(false);
                        toggleCategory('user playlist');
                        history.push(Routes.userplaylist);
                        // console.log('CATEGORY CHANGE: ' + Routes.featured);
                        }}> User Playlist</div>
                        :
                        <div></div>
                    }       
                    <div className={is_category === 'featured' ? "sub-menu-item sub-menu-item-active" : "sub-menu-item"} onClick={()=> {
                        toggleSearchMenu(!is_search_menu);
                        toggleSubMenuAbout(false);
                        toggleCategory('featured');
                        history.push(Routes.featured);
                        // console.log('CATEGORY CHANGE: ' + Routes.featured);
                    }}> Featured</div>
                    <div className={is_category === 'recently added' ? "sub-menu-item sub-menu-item-active" : "sub-menu-item"} onClick={()=> {
                        toggleSearchMenu(!is_search_menu);
                        toggleSubMenuAbout(false);
                        toggleCategory('recently added');
                        history.push(Routes.recently_added);
                        // console.log('CATEGORY CHANGE: ' + Routes.recently_added);
                    }}> Recently Added</div>
                    <div className={is_category === 'documentary' ? "sub-menu-item sub-menu-item-active" : "sub-menu-item"} onClick={()=> {
                        toggleSearchMenu(!is_search_menu);
                        toggleSubMenuAbout(false);
                        toggleCategory('documentary');
                        history.push(Routes.documentary);
                        // console.log('CATEGORY CHANGE: ' + Routes.documentary);
                        // toggleSubMenuAbout(!is_submenu_about);
                    }}> Documentary</div>
                    <div className={is_category === 'comedy' ? "sub-menu-item sub-menu-item-active" : "sub-menu-item"} onClick={()=> {
                        toggleSearchMenu(!is_search_menu);
                        toggleSubMenuAbout(false);
                        toggleCategory('comedy');
                        history.push(Routes.comedy);
                        // console.log('CATEGORY CHANGE: ' + Routes.comedy);
                        // toggleSubMenuAbout(!is_submenu_about);
                    }}> Comedy</div>
                    <div className={is_category === 'drama' ? "sub-menu-item sub-menu-item-active" : "sub-menu-item"} onClick={()=> {
                        toggleSearchMenu(!is_search_menu);
                        toggleSubMenuAbout(false);
                        toggleCategory('drama');
                        history.push(Routes.drama);
                        // console.log('CATEGORY CHANGE: ' + Routes.drama);
                        // toggleSubMenuAbout(!is_submenu_about);
                    }}> Drama</div>
                    <div className={is_category === 'adventure' ? "sub-menu-item sub-menu-item-active" : "sub-menu-item"} onClick={()=> {
                        toggleSearchMenu(!is_search_menu);
                        toggleSubMenuAbout(false);
                        toggleCategory('adventure');
                        history.push(Routes.adventure);
                        // console.log('CATEGORY CHANGE: ' + Routes.adventure);
                        // toggleSubMenuAbout(!is_submenu_about);
                    }}> Adventure</div>
                </div>
            </div>
        );
    };
};
SubMenuCategory.propTypes = {
    toggleSubMenuCategory: PropTypes.func,
    is_submenu_category: PropTypes.bool,
    toggleOverflow: PropTypes.func,
    is_overflow: PropTypes.bool,
}
SubMenuCategory.defaultProps = {
    toggleSubMenuCategory: () => {},
    is_submenu_category: true,
    toggleOverflow: () => {},
    is_overflow: false,
}
// Category.propTypes = {
//     toggleCategory: PropTypes.func,
//     is_category: PropTypes.string,
// }
// Category.defaultProps = {
//     toggleCategory: () => {},
//     // is_category: true,
// }
export const mapStateToProps = state => ({
    is_search_menu: state.app.is_search_menu,
    is_submenu_category: state.app.is_submenu_category,
    is_submenu_about: state.app.is_submenu_about,
    is_category: state.app.is_category,
    is_overflow: state.app.is_overflow,
});
export const mapDispatchToProps = dispatch => ({
    toggleSearchMenu: value => dispatch(toggleSearchMenu(value)),
    toggleSubMenuCategory: value => dispatch(toggleSubMenuCategory(value)),
    toggleSubMenuAbout: value => dispatch(toggleSubMenuAbout(value)),
    toggleCategory: value => dispatch(toggleCategory(value)),
    toggleOverflow: value => dispatch(toggleOverflow(value)),
}); { /*export default connect(mapStateToProps, mapDispatchToProps)(SearchMenu);*/ } { /*export default withRouter(SearchMenu);*/ }
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubMenuCategory));