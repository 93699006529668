//copyright: Big Screen Entertainment Group Inc.
import React from 'react';
import './index.css';
// import svg_loader from './Blocks-0.8s-80px.svg';

const LoadingIndicator = () => (
    <div className="loader">
         {/*<img className="loader" alt="Loading" src={svg_loader} />*/}
    </div>
);

export default LoadingIndicator;